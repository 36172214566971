<template>
  <div>
    <v-data-table
      :headers="headers"
      :search="search"
      :items="getCommission_types.data"
      no-data-text="Não há comissões  registradas"
      no-results-text="Não encontrado!"
      hide-default-footer
      @click:row="handleClick"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          color="yellow darken-4"
          v-if="component.permission.Editar"
          class="mr-2"
          @click="
            () => {
              edit(item);
            }
          "
          >mdi-pencil</v-icon
        >
        <v-icon
          v-if="component.permission.Excluir"
          color="red darken-4"
          class="mr-2"
          @click="
            () => {
              delete_item(item);
            }
          "
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <Edit :component="this" />
    <Delete :component="this" />
    <div>
      <v-pagination
        class="mt-6"
        v-model="current"
        :length="total"
        :total-visible="10"
        color="success"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { GET_ALL_COMISSION_TYPE } from "../../../store/actions/commission_type";
//import { dispatchGetAll } from "../../../components/Methods/dispatchGetAll";
import { headers } from "./script";
import Edit from "./Edit_Item/";
import Delete from "./Delete_Item/";
export default {
  props: {
    search: String(),
    component: Object(),
  },
  components: {
    Edit,
    Delete,
  },
  data: () => ({
    page: 1,
    total: Number(),
    current: Number(),
    headers,
    edit_item: {},
    dialog_edit: false,
    dialog_delete: false,
    item_delete: Object(),
  }),
  computed: { ...mapGetters(["getCommission_types"]) },
  methods: {
    edit(item) {
      this.dialog_edit = !this.dialog_edit;
      let aux = { ...item };
      if (aux.commissionType == "Items") {
        aux.commissionT = 1;
      } else if (aux.commissionType == "Vendas") {
        aux.commissionT = 2;
      } else if (aux.commissionType == "Produto") {
        aux.commissionT = 3;
      }
      this.edit_item = { ...aux };
    },
    delete_item(item) {
      this.item_delete = item;
      this.dialog_delete = !this.dialog_delete;
    },

    async GetAll(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: true,
      };
      await this.$store.dispatch(GET_ALL_COMISSION_TYPE, value);
    },
  },
  created() {
    this.GetAll();
  },
  watch: {
    getCommission_types: function (val) {
      this.results = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
    },
    current: function (val) {
      if (this.component.filter) {
        this.component.comissionType_filter &&
          this.component.comissionType_filter(val);
      } else {
        this.GetAll && this.GetAll(val);
      }
    },
  },
};
</script>
