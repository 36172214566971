<template>
  <div>
    <v-data-table
      :headers="headers"
      :search="search"
      :items="results"
      hide-default-footer
      no-data-text="Sem Profissões registradas registradas"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          color="yellow darken-4"
          v-if="component.permission.Editar"
          class="mr-2"
          @click="
            () => {
              change_addDialog(item);
            }
          "
          >mdi-pencil</v-icon
        >
        <v-icon
          color="red darken-4"
          v-if="component.permission.Excluir"
          class="mr-2"
          @click="
            () => {
              delete_item(item);
            }
          "
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-pagination
    class="mb-3"
      v-model="current"
      :length="total"
      :total-visible="10"
      color="success"
    >
    </v-pagination>
    <DestroyDialog :component="this" :destroy_object="destroy_object" />
  </div>
</template>

<script>
import { headers } from "./script";
import DestroyDialog from "../destroyDialog/destroyDialog";

export default {
  components: { DestroyDialog },

  props: {
    component: Object,
    getters: Object,
    search: String,
    change_addDialog: Function,
    destroy_object: Function,
  },

  data: () => ({
    page: Number(),
    last_page: Number(),
    headers,
    results: Array(),
    total: Number(),
    current: Number(),
    currentItem: {},
    destroyDialog: false,
  }),

  methods: {
    delete_item(item) {
      this.destroyDialog = true;
      this.currentItem = item;
    },
  },

  created() {
    this.component.GetAll();
  },

  watch: {
    getters: function (val) {
      this.results = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
    },
    current: function (val) {
      if(this.component.filter){
        this.component.profession_filter && this.component.profession_filter(val);
      }else{
        this.component.GetAll && this.component.GetAll(val);
      }
    },
  },
};
</script>
