<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="results"
      :search="search"
      no-data-text="Sem registro!"
      no-results-text="Nenhum resultado encontrado"
      hide-default-footer
      :loading="component.loading"
      loading-text="Carregando os dados!"
      @click:row="handleClick"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="success"
              class="mr-1"
              v-bind="attrs"
              v-on="on"
              @click="viewInfo(item)"
              >mdi-information</v-icon
            >
          </template>
          <span>Informações</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="yellow darken-4"
              v-bind="attrs"
              @click="changeReverse(item)"
              v-on="on"
              >mdi-chevron-up-box</v-icon
            >
          </template>
          <span>Estornar</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-pagination
      v-model="current"
      :length="total"
      color="success"
      :total-visible="10"
    ></v-pagination>
    <ViewDialog
      v-if="viewDialog"
      :dialog="viewDialog"
      :object="ObjectToView"
      :changeDialog="alterDialogView"
      title="Informações do"
      :component="this"
      :disabled="true"
    />
    <ReverseNote
      v-if="dialogReverse"
      :dialog="dialogReverse"
      title="Deseja estornar esta Nota?"
      subtitle="A nota será estornada."
      :alterDialog="changeReverse"
      :confirm="confirmReverse"
    />
  </div>
</template>

<script>
import ViewDialog from "../AddDialog/";
import ReverseNote from "../AddDialog/ConfirmAction/";
import { REFRESH_REVENUES_ITENS } from "../../../store/actions/revenuesitens";
import { REVERSE_REVENUE } from "../../../store/actions/revenues";

export default {
  props: {
    component: Object,
    getters: Object,
    destroy_object: Function,
    search: String,
    headers: Array,
    change_addDialog: Function,
    isLoad: Boolean,
  },
  components: {
    ViewDialog,
    ReverseNote,
  },

  data: () => ({
    destroyDialog: false,
    viewDialog: Boolean(),
    dialogReverse: Boolean(),
    ObjectToView: Object(),
    enterpriseId: Number(),
    results: Array(),
    total: Number(),
    current: Number(),
    dialog: Boolean(),
    confirmDialog: Boolean(),
    id: Number(),
    itemSelectedOnClick: Object(),
    idReverse: Number(),
  }),
  methods: {
    alterDialogView() {
      this.viewDialog = !this.viewDialog;
    },

    dialogDelete(item) {
      this.dialog = !this.dialog;
      this.id = item.id;
    },

    handleClick(i) {
      this.itemSelectedOnClick = i;
    },
    changeReverse(item) {
      this.idReverse = { ...item };
      this.dialogReverse = !this.dialogReverse;
    },
    async confirmReverse() {
      try {
        await this.$store.dispatch(REVERSE_REVENUE, this.idReverse);
      } finally {
        this.dialogReverse = !this.dialogReverse;
      }
    },
    refreshRevenusItem(item) {
      let listItens = [...item.revenus_item];

      listItens.forEach((e) => {
        e.description_item = e.produto.description_item;
        e.value_products = e.value_subtotal_product;
        e.venda_sale = e.value_sale;
        e.description_unit = e.produto.unit.sigla;
        e.base_icms_product = e.value_base_icms_product;

        if (e.cst_id === null) {
          e.cst_code = e.produto.csons.code;
        } else {
          e.cst_code = e.produto.cst.code;
        }
      });
      this.$store.dispatch(REFRESH_REVENUES_ITENS, listItens);
    },
    viewInfo(item) {
      this.ObjectToView.id = item.id;
      this.ObjectToView.status = item.status;
      this.ObjectToView.type_grade_id = item.type_grade_id;
      this.ObjectToView.finality_nf = item.finality_nf;
      this.ObjectToView.date_of_issue = item.date_of_issue;
      this.ObjectToView.access_key = item.access_key;
      this.ObjectToView.authorization_protocol = item.authorization_protocol;
      this.ObjectToView.series = item.series;
      this.ObjectToView.value_total_products = item.value_total_products;
      this.ObjectToView.value_book = item.value_book;
      this.ObjectToView.value_total_note = item.value_total_note;
      this.ObjectToView.value_discount = item.value_discount;
      this.ObjectToView.specie_note = item.specie_note;
      this.ObjectToView.value_icms_substituicao_total =
        item.value_icms_substituicao_total;
      this.ObjectToView.value_freigth = item.value_freigth;
      this.ObjectToView.base_calc_icms_total = item.base_calc_icms_total;
      this.ObjectToView.base_calc_susbt_total = item.base_calc_susbt_total;
      this.ObjectToView.value_icms_free_total = item.value_icms_free_total;
      this.ObjectToView.value_account_total = item.value_account_total;
      this.ObjectToView.value_outher_tax_total = item.value_outher_tax_total;
      this.ObjectToView.value_icms_total = item.value_icms_total;
      this.ObjectToView.note = item.note;
      this.ObjectToView.value_security = item.value_security;
      this.ObjectToView.value_ipi_total = item.value_ipi_total;
      this.ObjectToView.value_ipi_outher_total = item.value_ipi_outher_total;
      this.ObjectToView.value_ipi_free_total = item.value_ipi_free_total;
      this.ObjectToView.base_calc_ipi_total = item.base_calc_ipi_total;
      this.ObjectToView.vehicle_plate = item.vehicle_plate;
      this.ObjectToView.volume_transported = item.volume_transported;
      this.ObjectToView.gross_weight = item.gross_weight;
      this.ObjectToView.net_weight = item.net_weight;
      this.ObjectToView.volume_type = item.volume_type;
      this.ObjectToView.transporter_id = item.transporter_id;
      this.ObjectToView.client_id = item.client_id;
      this.ObjectToView.payment_terms_id = item.payment_terms_id;
      this.ObjectToView.tax_operation_types_id = item.tax_operation_types_id;
      this.ObjectToView.account_plans_id = item.account_plans_id;
      this.ObjectToView.enterprise_id = item.enterprise_id;
      this.ObjectToView.cliente = item.cliente;
      this.ObjectToView.value_other_incidental_expenses =
        item.value_other_incidental_expenses;
      this.ObjectToView.value_icms_outher_product =
        item.value_icms_outher_product;
      this.refreshRevenusItem(item);
      this.viewDialog = !this.viewDialog;
    },
  },
  created() {
    this.component.GetAll();
  },
  watch: {
    getters: function (val) {
      this.results = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
    },
    current: function (val) {
      this.component.GetAll && this.component.GetAll(val);
    },
  },
};
</script>
