import { GET_FILTER_CSOSN } from "../../../store/actions/csosn";
import { CHANGE_LOAD } from "../../../store/actions/load";
import { GET_ALL_CSOSN, DESTROY_CSOSN } from "../../../store/actions/csosn";




function close() {
    this.addDialogCsosn = !this.addDialogCsosn;
}
async function destroy_csosn(item) {
    const csosn = {
        id: item.id,
    };
    try {
        await this.$store.dispatch(DESTROY_CSOSN, csosn);
    } catch (error) {
        alert(error);
    }
}
function open_Dialog(item) {
    this.addDialogCsosn = !this.addDialogCsosn;
    this.objectToUpdate = item;
}
async function GetAll(page = 1) {
    this.$store.commit(CHANGE_LOAD, true);
    let value = {
        page: page,
        limit: 10,
        paginate: true,
    };
    await this.$store.dispatch(GET_ALL_CSOSN, value);
}
async function csosn_filter(page = 1) {
    page;
    if (!this.filter === true) {
        this.GetAll();
    } else {
        let value = {
            search: this.filter,
            page: 1,
            limit: 10,
            paginate: true,
        };
        await this.$store.dispatch(GET_FILTER_CSOSN, value);
    }
}




export default {
    close,
    destroy_csosn,
    open_Dialog,
    GetAll,
    csosn_filter,
}