<template>
  <v-row justify="center">
    <v-dialog
      v-model="getShowDialogRegisterProvider"
      fullscreen
      transition="dialog-bottom-transition"
      hide-overlay
    >
      <v-card>
        <v-toolbar color="success" class="white--text" flat>
          <v-btn
            icon
            dark
            @click="
              () => {
                close_dialog();
              }
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ tabTitle }} Fornecedor</v-toolbar-title>
        </v-toolbar>

        <v-container>
          <v-divider></v-divider>
          <v-tabs>
            <v-tab href="#tab1">
              <v-icon class="mr-1">mdi-account-details</v-icon>Gerais
            </v-tab>
            <v-tab href="#tab2" v-if="!register">
              <v-icon class="mr-1">mdi-family-tree</v-icon>Pessoal
            </v-tab>
            <v-tab href="#tab3">
              <v-icon class="mr-1">mdi-card-account-mail</v-icon>Contato
            </v-tab>
            <v-tab href="#tab4">
              <v-icon class="mr-1">mdi-home-account</v-icon>Endereço
            </v-tab>
            <v-tab href="#tab5">
              <v-icon class="mr-1">mdi-bank</v-icon>Contas Bancárias
            </v-tab>

            <v-tab-item value="tab1">
              <v-switch
                class="ml-5 mt-6"
                v-model="register"
                label="Pessoa Jurídica"
              ></v-switch>
              <div class="mt-5 d-inline-flex">
                <v-select
                  :items="enterprises"
                  item-text="fantasyName"
                  item-value="id"
                  v-model="enterprise_id"
                  v-if="out_of_register"
                  outlined
                  label="Empresa"
                />
              </div>

              <v-card flat v-if="!register">
                <TabPhisical :component="this" ref="phys" />
              </v-card>
              <v-card flat v-else>
                <TabLegal :component="this" ref="legal" />
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab2">
              <v-card flat>
                <TabPessoal :component="this" />
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab3">
              <v-card flat>
                <TabContact :component="this" />
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab4">
              <v-card flat>
                <TabAddress :component="this" />
              </v-card>
            </v-tab-item>
            <v-tab-item value="tab5">
              <v-card flat>
                <TabBank :component="this" />
              </v-card>
            </v-tab-item>
          </v-tabs>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="!justVisible" color="error" @click="clear()">
              <v-icon>mdi-cancel</v-icon>Limpar Formulário
            </v-btn>
            <v-btn
              v-if="!justVisible"
              color="success"
              :disabled="!valid"
              @click="submit()"
            >
              <v-icon>mdi-content-save</v-icon>Salvar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import TabPhisical from "../Tab/TabPhisical";
import TabLegal from "../Tab/TabLegal";
import TabContact from "../Tab/TabContact/index.vue";
import TabAddress from "../Tab/TabAddress/index.vue";
import TabBank from "../Tab/TabBank/index.vue";
import TabPessoal from "../Tab/TabPersonal";
import Methods from "./helpers/Mehods";
import { mapGetters } from "vuex";
import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";
import {
  ADD_PF_PROVIDER,
  ADD_PJ_PROVIDER,
  UPDATE_PF_PROVIDER,
  UPDATE_PJ_PROVIDER,
  ITEM_TO_UPDATE,
} from "../../../store/actions/provider";
import {
  cleanCNPJ,
  cleanString,
} from "../../Utils/global_functions/clean_cnpj_cpf";
export default {
  name: "AddDialog",
  components: {
    TabPhisical,
    TabLegal,
    TabContact,
    TabAddress,
    TabPessoal,
    TabBank,
  },

  props: {
    component: Object(),
    id: Number(),
    showDialog: Boolean(),
    out_of_register: Boolean(),
  },

  computed: {
    ...mapGetters([
      "getShowDialogRegisterProvider",
      "getEnterprises",
      "getItemToUpdateProvider",
      "getAutoReload",
    ]),
  },

  data: () => ({
    tabTitle: String(),
    justVisible: Boolean(),
    register: Boolean(),
    valid: Boolean(),
    validPhysical: Boolean(),
    validLegal: Boolean(),
    validTable: Boolean(false),
    enterprises: Array(),
    enterprise_id: String(),
    provider: {
      type_people: String(),
      people_id: Number(),
      type: String(),
      relatedRepresentative: String(),
    },
    PhysicalPeople: {
      name: "",
      lastName: "",
      cpf: "",
      gender: "",
      emitter: "",
      emitterState: "",
      idCard: "",
      dateOfBirth: "",
      fatherName: "",
      motherName: "",
      nacionality: "",
      naturality: "",
      monthlyIncome: "",
      civilStatus: "",
      spouse: "",
      icomeSpouse: Number(),
      people_id: Number(),
      id: undefined,
    },

    LegalPeople: {
      cnpj: String(),
      fantasyName: String(),
      socialName: String(),
      stateRegistration: String(),
      municipalRegistration: String(),
      homePage: String(),
      id: undefined,
      people_id: Number(),
    },
    address: {
      street: String(),
      number: String(),
      district: String(),
      complement: String(),
      city: String(),
      state: String(),
      cep: String(),
      city_id: Number(),
      people_id: Number(),
    },
    banks: {
      bank: String(),
      agency: String(),
      count: Number(),
      manager: String(),
      people_id: Number(),
    },
    contact: {
      primaryEmail: "",
      secundaryEmail: "",
      phone: String(),
      cellPhone: String(),
      phoneReference: String(),
      people_id: Number(),
    },
    new_city: Object(),

    // ListAddress: Array(),
    // ListContact: Array(),
    // ListBank: Array(),
  }),

  methods: {
    ...Methods,
    autoReload() {
      this.register = this.getAutoReload.pj;
      this.LegalPeople.fantasyName = this.getAutoReload.fantasyName;
      this.LegalPeople.socialName = this.getAutoReload.providerName;
      this.LegalPeople.cnpj = this.getAutoReload.providerCNPJ;
      this.contact.phone = this.getAutoReload.contact.fone;
      //endereço

      this.address.street = this.getAutoReload.address.street;
      this.address.district = this.getAutoReload.address.neighborhood;
      this.address.complement = this.getAutoReload.address.complement;
      this.address.city = this.getAutoReload.address.city;
      this.address.state = this.getAutoReload.address.state;
      this.address.cep = this.getAutoReload.address.cep;
    },
    closeOnEsc(event) {
      if (event.key === "Escape") {
        this.close_dialog();
      }
    },
    loadEnterprises() {
      if (this.out_of_register) {
        this.$store.dispatch(GET_ALL_ENTERPRISE, {
          page: null,
          limit: null,
          paginate: false,
        });
      }
    },
    close_dialog() {
      if (this.register) {
        this.clear_legal();
      } else {
        this.clear_Physical();
      }
      this.justVisible = false;
      this.tabTitle = "";
      this.address = {};
      this.contacts = {};
      this.banks = {};
      this.$store.dispatch(ITEM_TO_UPDATE, null);
      this.wipeData();
      this.$store.dispatch("setShowDialogRegisterProvider", false);
    },

    validTotal() {
      if (this.ListAddress.length > 0) {
        this.validTable = true;
      }
    },
    submit() {
      this.PhysicalPeople.cpf = this.PhysicalPeople.cpf.replace(/\D/g, "");
      if (this.register) {
        if (this.LegalPeople.id != undefined) {
          this.update();
        } else {
          this.create();
        }
      } else {
        if (this.PhysicalPeople.id != undefined) {
          this.update();
        } else {
          this.create();
        }
      }
    },
    async update() {
      if (this.register === true) {
        let newPeople = Object();
        newPeople.legal_people = { ...this.LegalPeople };
        newPeople.id = this.provider.id;
        newPeople.people_id = this.LegalPeople.people_id;
        newPeople.relatedRepresentative = this.provider.relatedRepresentative;
        newPeople.type = this.provider.type;
        newPeople.type_people = "LegalPeople";
        newPeople.address = { ...this.address };
        newPeople.contacts = { ...this.contact };
        newPeople.banks = { ...this.banks };
        newPeople.address.city_id = this.new_city.id;
        newPeople.address.city = this.new_city.nome;
        newPeople.enterprise_id = this.enterprise_id;
        await this.$store.dispatch(UPDATE_PJ_PROVIDER, newPeople).then(() => {
          this.close_dialog();
        });
      } else {
        let newPeople = Object();
        newPeople.physical_people = { ...this.PhysicalPeople };
        newPeople.id = this.provider.id;
        newPeople.people_id = newPeople.physical_people.people_id;
        newPeople.relatedRepresentative = this.provider.relatedRepresentative;
        newPeople.type = this.provider.type;
        newPeople.type_people = "PhysicalPeople";
        newPeople.address = { ...this.address };
        newPeople.address.city_id = this.new_city.id;
        newPeople.address.city = this.new_city.nome;
        newPeople.contacts = { ...this.contact };
        newPeople.banks = { ...this.banks };
        newPeople.enterprise_id = this.enterprise_id;
        await this.$store.dispatch(UPDATE_PF_PROVIDER, newPeople).then(() => {
          this.close_dialog();
        });
      }
      // this.validTotal();
      // if (this.validTable) {
      //   this.empty_array(this.ListAddress, newPeople.address);
      //   this.empty_array(this.ListContact, newPeople.contacts);
      //   this.empty_array(this.ListBank, newPeople.banks);

      // } else {
      //   alert("É necessário no mínimo 1 endereço cadastrado!");
      // }
    },
    async create() {
      if (this.register === true) {
        let newPeople = Object();
        newPeople.legal_people = { ...this.LegalPeople };
        newPeople.legal_people.cnpj = cleanCNPJ(newPeople.legal_people.cnpj);
        newPeople.people_id = "";
        newPeople.relatedRepresentative = this.provider.relatedRepresentative;
        newPeople.type = this.provider.type;
        newPeople.type_people = "LegalPeople";
        newPeople.address = { ...this.address };
        newPeople.contacts = { ...this.contact };
        newPeople.banks = { ...this.banks };
        newPeople.address.city_id = this.new_city.id;
        newPeople.address.city = this.new_city.nome;

        newPeople.address.cep = cleanString(this.address.cep);
        newPeople.contacts.phone = cleanString(this.contact.phone);
        newPeople.contacts.cellPhone = cleanString(this.contact.cellPhone);
        newPeople.contacts.phoneReference = cleanString(
          this.contact.phoneReference
        );

        if (this.out_of_register) {
          newPeople.enterprise_id = this.enterprise_id;
        } else {
          newPeople.enterprise_id = this.component.enterpriseId;
        }
        await this.$store.dispatch(ADD_PJ_PROVIDER, newPeople).then(() => {
          this.close_dialog();
        });
      } else {
        let newPeople = Object();
        newPeople.physical_people = { ...this.PhysicalPeople };
        newPeople.people_id = "";
        newPeople.relatedRepresentative = this.provider.relatedRepresentative;
        newPeople.type = this.provider.type;
        newPeople.type_people = "PhysicalPeople";
        newPeople.address = { ...this.address };
        newPeople.address.city_id = this.new_city.id;
        newPeople.address.city = this.new_city.nome;
        newPeople.contacts = { ...this.contact };
        newPeople.banks = { ...this.banks };

        newPeople.address.cep = cleanString(this.address.cep);
        newPeople.contacts.phone = cleanString(this.contact.phone);
        newPeople.contacts.cellPhone = cleanString(this.contact.cellPhone);
        newPeople.contacts.phoneReference = cleanString(
          this.contact.phoneReference
        );
        if (this.out_of_register) {
          newPeople.enterprise_id = this.enterprise_id;
        } else {
          newPeople.enterprise_id = this.component.enterpriseId;
        }
        await this.$store.dispatch(ADD_PF_PROVIDER, newPeople).then(() => {
          this.close_dialog();
        });
      }
    },

    clear() {
      if (this.register) {
        this.clear_legal();
      } else {
        this.clear_Physical();
      }

      this.address = {};
      this.contacts = {};
      this.banks = {};
    },
    empty_array(array, main_array) {
      if (array.length === 0) {
        main_array.push({});
      }
    },

    loadContent(item) {
      if (item) {
        if (item.justVisible) {
          this.tabTitle = "Visualizar";
          this.justVisible = item.justVisible;
          this.itemToUpdate(item.item);
        } else {
          this.tabTitle = "Cadastrar";
          this.itemToUpdate(item);
        }
      }
    },

    clear_Physical() {
      this.PhysicalPeople.name = "";
      this.PhysicalPeople.lastName = "";
      this.PhysicalPeople.cpf = "";
      this.PhysicalPeople.gender = "";
      this.PhysicalPeople.emitter = "";
      this.PhysicalPeople.emitterState = "";
      this.PhysicalPeople.idCard = "";
      this.PhysicalPeople.dateOfBirth = "";
      this.PhysicalPeople.fatherName = "";
      this.PhysicalPeople.motherName = "";
      this.PhysicalPeople.nacionality = "";
      this.PhysicalPeople.naturality = "";
      this.PhysicalPeople.monthlyIncome = "";
      this.PhysicalPeople.civilStatus = "";
      this.PhysicalPeople.spouse = "";
      this.PhysicalPeople.icomeSpouse = "";
      this.PhysicalPeople.type = "";
      this.PhysicalPeople.address = [];
      this.PhysicalPeople.contacts = [];
      this.PhysicalPeople.banks = [];
      this.$refs.phys.reset();
    },
    clear_legal() {
      this.LegalPeople.cnpj = "";
      this.LegalPeople.fantasyName = "";
      this.LegalPeople.socialName = "";
      this.LegalPeople.stateRegistration = "";
      this.LegalPeople.municipalRegistration = "";
      this.LegalPeople.homePage = "";
      this.LegalPeople.address = [];
      this.LegalPeople.banks = [];
      this.LegalPeople.contacts = [];
      this.LegalPeople.related_representative = "";
      this.LegalPeople.type = "";
      this.$refs.legal.reset();
    },
  },
  watch: {
    //não é mais necessario
    getItemToUpdateProvider: function (item) {
      if (item) {
        if (item.justVisible) {
          this.tabTitle = "Visualizar";
          this.justVisible = item.justVisible;
          this.itemToUpdate(item.item);
        } else {
          this.tabTitle = "Cadastrar";
          this.itemToUpdate(item);
        }
      }
    },
    getEnterprises: function (val) {
      this.enterprises = Object.values(val);
    },
    validPhysical(val) {
      if (val === true) {
        this.valid = true;
      } else {
        this.valid = false;
      }
    },
    validLegal(val) {
      if (val === true) {
        this.valid = true;
      } else {
        this.valid = false;
      }
    },
  },
  created() {
    window.addEventListener("keydown", this.closeOnEsc);
    this.loadContent(this.getItemToUpdateProvider);
    this.loadEnterprises();
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.closeOnEsc);
    this.$store.dispatch(ITEM_TO_UPDATE, {});
  },
};
</script>
