import rules from "./rules";

var data = {
  comission_type:Array(),
  iterator: 0,
  form: Boolean(),
  rules: rules,
  listEnterprises: Array(),
  formData: Object(),
  enterprise_id:Number()
};

export default data;
