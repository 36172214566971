var headerPDF = [
  "Codigo do cliente",
  "Status ",
  "Data da venda",
  "numero da venda",
  "serie",
  "Valor do produto",
  "Valor Desconto",
  "Base de calculo icms",
  "Valor icms",
  "Valor da venda",
];

module.exports = {
  headerPDF,
};
