<template>
  <v-container>
    <v-form :disabled="isDisable" v-model="valid">
      <v-row>
        <v-col cols="12" lg="2" xl="6" md="5" sm="6">
          <v-text-field v-on:change="choose_product()" outlined label="Produto" v-model="description.description_item"
            append-icon="mdi-magnify" return-object append-outer-icon="mdi-plus-circle"
            @click:append-outer="showDialog('productdialog')" @click:append="changeDialog()">
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="1" xl="6" md="5" sm="6">
          <v-text-field outlined type="number" v-model="itemNote.productAmount" label="Qtd"
            :rules="[(v) => !!v || 'Campo Obrigatorio']">
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="2" xl="6" md="5" sm="6">
          <v-text-field prefix="R$" v-model="itemNote.cost" type="number" outlined label="Custo"
            :rules="[(v) => !!v || 'Campo Obrigatorio']">
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="2" xl="6" md="5" sm="6">
          <v-autocomplete outlined label="Alíquota" v-model="itemNote.aliquot" :items="aliquot_itens"
            item-text="aliquot" item-value="id" return-object append-outer-icon="mdi-plus-circle"
            @click:append-outer="showDialog('aliquotdialog')" :rules="[() => !!aliquot || 'Campo Obrigatorio']">
          </v-autocomplete>
        </v-col>
        <v-col cols="12" lg="2" xl="6" md="5" sm="6">
          <v-autocomplete v-model="itemNote.CFOP" label="CFOP" :items="cfop_itens" return-object item-text="code"
            outlined append-outer-icon="mdi-plus-circle" @click:append-outer="showDialog('cfopdialog')"
            :rules="[() => !!cfop || 'Campo Obrigatorio']">
          </v-autocomplete>
        </v-col>
        <v-col cols="12" lg="2" xl="6" md="5" sm="6">
          <v-autocomplete item-text="description" v-model="itemNote.cstCSOSN" :items="cstCsosn" return-object
            label="CST/CSOSN" outlined append-outer-icon="mdi-plus-circle"
            @click:append-outer="showDialog('csosndialog')" :rules="[(v) => !!v || 'Campo Obrigatorio']">
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="3" xl="5" md="5" sm="6">
          <v-text-field outlined label="IPI" v-model="itemNote.IPI" type="number" suffix="%"
            :rules="[(v) => !!v || v === 0 || 'Campo Obrigatorio']"></v-text-field>
        </v-col>
        <v-col cols="12" lg="4" xl="6" md="5" sm="6">
          <v-autocomplete outlined label="CST/PIS" :items="pisCofins_itens" item-text="description" return-object
            append-outer-icon="mdi-plus-circle" @click:append-outer="showDialog('pisdialog')" v-model="itemNote.cstPIS"
            :rules="[() => !!cst_pis || 'Campo Obrigatorio']">
          </v-autocomplete>
        </v-col>
        <v-col cols="12" lg="3" xl="6" md="5" sm="6">
          <v-autocomplete outlined label="CST/COFINS" :items="pisCofins_itens" item-text="description" return-object
            append-outer-icon="mdi-plus-circle" @click:append-outer="showDialog('confinsdialog')"
            v-model="itemNote.cstCOFINS" :rules="[() => !!cst_cofins || 'Campo Obrigatorio']">
          </v-autocomplete>
        </v-col>
        <v-col cols="12" lg="1" xl="1" md="2" sm="2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn rounded elevation="5" v-bind="attrs" @click="add_product()" v-on="on" class="success mt-2"
                :disabled="!valid">
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
            </template>
            <span>Adicionar</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <TableNoteEntryItens :component="component" :actionDelete="deleteItens" :getters="results"
        :actionEdit="changeDialogEdit" />

      <Product v-if="dialog" :component="this" :dialog="dialog" :getters="getProduct" :changeDialog="changeDialog" />
      <editItem v-if="dialogEdit" :dialog="dialogEdit" :changeDialog="changeDialogEdit" :ObjectToUpdate="ObjectToUpdate"
        :submit="updateItens" :component="this" />

      <PorductDialog v-if="getShowDialogRegisterProduct" :out_of_register="true" />
      <AliqutotDialog v-if="getShowDialogRegisterAliquot" />
      <CFOPDialog v-if="getShowDialogRegisterCFOP" />
      <CSOSNDialog v-if="getShowDialogRegisterCSOSN" />
      <PISDialog v-if="getShowDialogRegisterPISCOFINS" />
      <COFINSDialog v-if="getShowDialogRegisterPISCOFINS" />
    </v-form>
  </v-container>
</template>

<script>
import Product from "./DialogProduct/";
import TableNoteEntryItens from "../../Table/TableNoteEntryItens/index";
import editItem from "../../AddDialog/EditItensNote/";

import PorductDialog from "../../../products/AddDialog";
import AliqutotDialog from "../../../aliquot/AddDialog";
import CFOPDialog from "../../../cfop/AddDialog";
import CSOSNDialog from "../../../csosn/AddDialog";
import PISDialog from "../../../pis_cofins/AddDialog";
import COFINSDialog from "../../../pis_cofins/AddDialog";

import { header } from "./script";
import { GET_PRODUCT_BY_ID } from "../../../../store/actions/product";
import { GET_ALL_CFOP } from "../../../../store/actions/cfop";
import { GET_ALL_ALIQUOT } from "../../../../store/actions/aliquot";
import { GET_ALL_PIS_COFINS } from "../../../../store/actions/pis_cofins";
import { GET_ALL_CST } from "../../../../store/actions/cst";
import { GET_ALL_CSOSN } from "../../../../store/actions/csosn";

import { UPDATE_ITENS_NOTE } from "../../../../store/actions/note_entry_itens";
import { GET_ALL_NOTE_ENTRY_ITENS } from "../../../../store/actions/note_entry_itens";
import {
  ADD_NOTE_ENTRY_ITENS,
  DESTROY_NOTE_ENTRY_ITENS,
} from "../../../../store/actions/note_entry_itens";

import { mapGetters } from "vuex";
import Methods from "./helpers/Methods"
export default {
  props: {
    component: Object,
    isDisable: Boolean,
  },
  components: {
    Product,
    TableNoteEntryItens,
    editItem,
    PorductDialog,
    AliqutotDialog,
    CFOPDialog,
    CSOSNDialog,
    PISDialog,
    COFINSDialog,
  },
  data: () => ({
    itemNote: {
      product: Object(),
      productAmount: Number(),
      aliquot: Object(),
      cost: Number(),
      CFOP: Object(),
      cstCSOSN: Object(),
      cstPIS: Object(),
      cstCOFINS: Object(),
      IPI: Number(),
    },

    header,
    description: Object(),
    cst_pis: Object(),
    cst_cofins: Object(),
    aliquot_itens: Array(),
    cfop_itens: Array(),
    pisCofins_itens: Array(),
    dialogEdit: Boolean(),

    cstList: Array(),
    csosnList: Array(),

    cst: Object(),
    csosn: Object(),
    product: Object(),
    valuesParameter: {
      page: null,
      limit: null,
      paginate: false,
    },
    dialog: Boolean(),
    ObjectToUpdate: Object(),
    dialog_cfop: Boolean(),
    dialog_aliquot: Boolean(),
    aliquot: Object(),
    cfop: Object(),
    cstCsosn: Array(),
    cst_csosn: "",
    results: [],
    valid: false,
  }),
  methods: {
    ...Methods,
    showDialog(dialog) {
      if (dialog == "productdialog") {
        this.$store.dispatch("setShowDialogRegisterProduct", true);
      } else if (dialog == "aliquotdialog") {
        this.$store.dispatch("setShowDialogRegisterAliquot", true);
      } else if (dialog == "cfopdialog") {
        this.$store.dispatch("setShowDialogRegisterCFOP", true);
      } else if (dialog == "csosndialog") {
        this.$store.dispatch("setShowDialogRegisterCSOSN", true);
      } else if (dialog == "pisdialog") {
        this.$store.dispatch("setShowDialogRegisterPISCOFINS", true);
      } else if (dialog == "confinsdialog") {
        this.$store.dispatch("setShowDialogRegisterPISCOFINS", true);
      }
    },
    changeDialog() {
        
      this.dialog = !this.dialog;
    },
    changeDialogCfop() {
      this.dialog_cfop = !this.dialog_cfop;
    },
    changeDialogAliquot() {
      this.dialog_aliquot = !this.dialog_aliquot;
    },

    async choose_product() {
      let id = this.description.description_item;
      await this.$store.dispatch(GET_PRODUCT_BY_ID, id);
    },

    refresh_dates() {
      this.cstList = Object.keys(this.getCst).map((key) => this.getCst[key]);
      this.csosnList = Object.keys(this.getCsosn).map(
        (key) => {
          if(typeof(this.getCsosn[key].code) == "string"){
            this.getCsosn[key].code = parseFloat(this.getCsosn[key].code)
            return this.getCsosn[key]
          }else {
            return this.getCsosn[key]
          }
        }
      );
      this.cstCsosn = this.cstList.concat(this.csosnList);
    },
    changeDialogEdit(item) {
      if (!this.isDisable) {
        if (!this.dialogEdit) {
          this.ObjectToUpdate = { ...item };
          this.ObjectToUpdate.provider_id = this.component.invoice.provider_id;
          this.ObjectToUpdate.tax_operation_types_id =
            this.component.invoice.tax_operation_types_id;
          this.ObjectToUpdate.enterprise_id =
            this.component.invoice.enterprise_id;
        }
        this.dialogEdit = !this.dialogEdit;
      } else {
        alert("Não é permitido na ação de Informações.");
      }
    },
    async updateItens(val) {
      // let product = {
      //   id: val.id,
      //   amount_add: parseFloat(val.amount),
      //   value_cust: val.cust,
      //   description_cst_code: val.cst_csosn.code,
      //   cfop_id: val.cfop.id,
      //   description_cfop_code: val.cfop.code,
      //   aliquot_id: val.aliquot.id,
      //   aliquot: val.aliquot.aliquot,
      //   product_id_add: val.product_id,
      //   provider_id: val.provider_id,
      //   tax_operation_types_id: val.tax_operation_types_id,
      //   enterprise_id: val.enterprise_id,
      //   value_aliquot_ipi: val.ipi,
      //   cst_pis_id: val.cst_pis.id,
      //   cst_pis_code: val.cst_pis.code,
      //   cst_cofins_id: val.cst_cofins.id,
      //   cst_confis_code: val.cst_cofins.code,

      //   cst_id: Number(),
      //   csosn_id: Number(),
      // };
      // if (product.description_cst_code < 100) {
      //   product.cst_id = val.cst_csosn.id;
      //   product.csosn_id = null;
      // } else {
      //   product.csosn_id = val.cst_csosn.id;
      //   product.cst_id = null;
      // }

      await this.$store.dispatch(UPDATE_ITENS_NOTE, val).then(() => {
        this.changeDialogEdit();
      });
    },

    async GetCst() {
      await this.$store.dispatch(GET_ALL_CST, this.valuesParameter);
    },

    async GetCsosn() {
      await this.$store.dispatch(GET_ALL_CSOSN, this.valuesParameter);
    },

    async GetCfop() {
      this.$store.dispatch(GET_ALL_CFOP, this.valuesParameter);
    },

    async GetAliquot() {
      this.$store.dispatch(GET_ALL_ALIQUOT, this.valuesParameter);
    },

    async GetPisCofins() {
      this.$store.dispatch(GET_ALL_PIS_COFINS, this.valuesParameter);
    },

    async add_product() {
      let products = {
        amount: this.itemNote.productAmount,
        cust: parseFloat(this.itemNote.cost),
        aliquot_ipi_product: parseFloat(this.itemNote.IPI),
        description_product: this.itemNote.product.description_item,
        description_unit: this.itemNote.product.unit.sigla,
        cst_code_ipi: String(this.itemNote.product.cst_ipi_id),
        aliquot_icms_product: parseFloat(this.itemNote.aliquot.aliquot),
        cst_pis_id: this.itemNote.product.cst_pis_id,
        cst_confis_id: this.itemNote.product.cst_cofins_id,
        product_id: this.itemNote.product.id,
        cfop_id: this.itemNote.CFOP.id,
        csosn_id: this.itemNote.product.csosn_id,
        cst_id: this.itemNote.product.cst_id,
        invoiceentry_id: null,
        cst_code_pis: this.itemNote.cstPIS.code,
        cst_code_confis: this.itemNote.cstCOFINS.code,
        code_cfop: String(this.itemNote.CFOP.code),
        code_csosn: this.itemNote.product.csons.code,
        code_cst: this.itemNote.product.cst.code,
        unit_id: this.itemNote.product.unit_id,

        value_sub_total_product: 0,
        value_icms_st_product: 0,
        value_accounting_product: 0,
        value_base_ipi_product: 0,
        value_base_icms_st_product: 0,
        value_ipi_product: 0,
        value_icms_product: 0,
        value_free_icms_product: 0,
        value_icms_outher_product: 0,
        value_ipi_free_product: 0,
        value_ipi_outher_product: 0,
        value_descont: 0,
        value_base_icms_product: 0,
        aliquot_pis: 0,
        aliquot_confis: 0,
        value_base_pis_product: 0,
        value_pis_product: 0,
        value_base_confis_product: 0,
        value_confis_product: 0,
        value_base_pis_st_product: 0,
        value_pis_st_product: 0,
        value_base_cofins_st_product: 0,
        value_cofins_st_product: 0,
        value_other_pis_product: 0,
        value_other_confis_product: 0,
        value_free_confis_product: 0,
        value_free_pis_product: 0,
      };

      // if (this.itemNote.cstCSOSN.code < 100) {
      //   products.cst_id = this.itemNote.cstCSOSN.id;
      //   products.csosn_id = null;
      // } else {
      //   products.csosn_id = this.itemNote.cstCSOSN.id;
      //   products.cst_id = null;
      // }
    
      this.$store.dispatch(ADD_NOTE_ENTRY_ITENS, products);
      this.wipeData()
    },

    deleteItens(item) {
      if (!this.isDisable) {
        try {
          this.$store.dispatch(DESTROY_NOTE_ENTRY_ITENS, item.tableId);
        } catch (error) {
          alert(error);
        }
      } else {
        alert("Não é permitido na ação de Informações.");
      }
    },
    GetAll() {
      this.$store.dispatch(
        GET_ALL_NOTE_ENTRY_ITENS,
        this.component.objectToView.grade_itens
      );
    },

    lookGetNoteEntryItens(itens) {
      let aux = JSON.parse(JSON.stringify(itens));
      if (aux.length > 0) {
        aux.forEach((e) => {
          e.amount = parseFloat(e.amount).toFixed(3).replace(".", ",");
          e.value_icms_product = parseFloat(e.value_icms_product).toFixed(2);
        });
        this.results = aux;
      } else {
        this.results = Array();
      }
    },
  },
  computed: {
    ...mapGetters([
      "getProduct",
      "getCfop",
      "getCst",
      "getCsosn",
      "getAliquot",
      "getNoteEntryItens",
      "getPisCofins",
      "getShowDialogRegisterProduct",
      "getShowDialogRegisterAliquot",
      "getShowDialogRegisterCFOP",
      "getShowDialogRegisterCSOSN",
      "getShowDialogRegisterPISCOFINS",
      "getProductById"
    ]),
  },
  async created() {
    if (this.component.objectToView !== undefined) {
      await this.GetAll();
      this.$nextTick(this.lookGetNoteEntryItens(this.getNoteEntryItens));
    }

    await this.GetCst();
    await this.GetCsosn();
    await this.GetCfop();
    await this.GetAliquot();
    await this.GetPisCofins();
    await this.refresh_dates();
  },

  watch: {
    description: function (val) {
        

      this.itemNote.product = { ...val }
      if (val.id !== undefined) {
        this.itemNote.cost = val.cost_price;
        this.itemNote.aliquot = val.aliquot;
        this.itemNote.CFOP = val.cfop;
        this.itemNote.cstCSOSN = val.csons;
        this.itemNote.cstPIS = val.cst_pis;
        this.itemNote.cstCOFINS = val.cst_confis;
      }
    },

    getNoteEntryItens: function (val) {
      this.lookGetNoteEntryItens(val);
    },

    getProductById: function (val) {

      this.description = { ...val.data[0] };
      this.itemNote.product = { ...val.data[0] };
    },
   

    getAliquot: function (val) {
      this.aliquot_itens = Object.keys(val).map((key) => val[key]);
    },

    getCfop: function (val) {
      let aux = Object.keys(val).map((key) => val[key]);
      this.cfop_itens = aux.filter((e) => e.code < 4999);
    },

    getPisCofins: function (val) {
      this.pisCofins_itens = Object.keys(val).map((key) => val[key]);
    },

    aliquot: function (val) {
      this.product.aliquot = val.aliquot;
      this.product.aliquot_id = val.id;
    },

    cfop: function (val) {
      this.product.description_cfop_code = val.code;
      this.product.cfop_id = val.id;
    },
  },
};
</script>

<style>
</style>