

const GET_ALL_TAX_CUPOM = "GET_ALL_TAX_CUPOM"
const ITEM_TO_CANCEL = "ITEM_TO_CANCEL"
const CANCEL_TAX_CUPOM = "CANCEL_TAX_CUPOM"
const SET_CNPJ = "SET_CNPJ"
const REPRINT_NFCE_WITH_KEY = "REPRINT_NFCE_WITH_KEY"

module.exports = {
    GET_ALL_TAX_CUPOM,
    ITEM_TO_CANCEL,
    CANCEL_TAX_CUPOM,
    SET_CNPJ,
    REPRINT_NFCE_WITH_KEY,
}