<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
      </v-card-text>
      <v-card-text>
        <v-text-field
          class="mx-4"
          label="Pesquisar"
          outlined
          v-model="filter"
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-card-text>
      <Table
        v-if="permission.Visualizar"
        :component="this"
        :getters="getNcm"
        :destroy_ncm="destroy_ncm"
        :search="search"
        :headers="headers"
        :open_Dialog="open_Dialog"
      />
      <ModalNcm
        v-if="addDialogNcm"
        :addDialogNcm="addDialogNcm"
        :component="this"
        @close="addDialog = $event"
      />
    </v-card>
    <AlertApi />
    <loadScreen />
  </Container>
</template>

<script>
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/ncm/CardTitle/index";
import Table from "../components/ncm/Table/index";
import ModalNcm from "../components/ncm/AddDialog/index";
import AlertApi from "../components/alert_api/alert_api";
import loadScreen from "../components/load/";

import { mapGetters } from "vuex";
import { CHANGE_LOAD } from "../store/actions/load";
import { GET_ALL_NCM, DESTROY_NCM, AUX_TO_UPDATE_NCM } from "../store/actions/ncm";
import { GET_FILTER_NCM } from "../store/actions/ncm";

export default {
  data: () => ({
    search: "",
    code: "",
    getter: "getNcm",
    addDialogNcm: false,
    destroyDialog: false,
    idToDestroy: 0,
    filter: "",
    objectToUpdate: {},
    title: "NCM",
    headers: [
      { text: "Codigo", value: "code" },
      { text: "Descrição", value: "description" },
      { text: "Ações", value: "actions" },
    ],
    permission: Object(),
  }),

  methods: {
    async destroy_ncm(item) {
      const ncm = {
        id: item,
      };
      try {
        await this.$store.dispatch(DESTROY_NCM, ncm);
      } catch (error) {
        alert(error);
      }
    },
    open_Dialog(item) {
      this.addDialogNcm = !this.addDialogNcm;
      // this.objectToUpdate = item;
      this.$store.dispatch(AUX_TO_UPDATE_NCM, item)
    },
    async GetAll(page = 1) {
      this.$store.commit(CHANGE_LOAD, true);
      let value = {
        page: page,
        limit: 10,
        paginate: true,
      };
      await this.$store.dispatch(GET_ALL_NCM, value);
    },

    async ncm_filter(page = 1) {
      if (!this.filter === true) {
        this.GetAll();
      } else {
        let value = {
          search: this.filter,
          page: page,
          limit: 10,
          paginate: true,
        };
        await this.$store.dispatch(GET_FILTER_NCM, value);
      }
    },
  },

  async created() {
    this.GetAll();
  },

  mounted() {
    // usando js para pegar o nome da rota, vai funcionar para todas rotas menos a rota /
    let name = document.URL.split("/").pop();
    this.permission = this.getCadastrosLinks.getPermissions(name);
    // usar getter especifico para comparar as rotas, getPermission deve receber ultimo parametro da rota
    // exemplo se a rota é http://localhost:8080/Aliquot voce deve passar para ele somente o Aliquot
  },

  watch: {
    filter: function () {
      this.ncm_filter();
    },
  },

  components: {
    Container,
    CardTitle,
    Table,
    ModalNcm,
    loadScreen,
    AlertApi,
  },

  computed: {
    ...mapGetters(["getNcm", "getCadastrosLinks"]),
  },
};
</script>

<style scoped></style>
