<template>
  <Container>
    <v-card>
      <v-card-text
        ><CardTitle :downTitle="changeDialog" :title="Title" :component="this"
      /></v-card-text>
      <v-layout>
        <Info :component="this" />
      </v-layout>
      <AddDialog
        :dialog="dialog"
        :changeDialog="changeDialog"
        :submit="submit"
      />
      <v-card-text>
        <v-text-field
          outlined
          label="Pesquisar"
          append-icon="mdi-magnify"
          v-model="search"
        ></v-text-field>
      </v-card-text>
      <Table :component="this" :header="header" :items="itemsDownReceiver" />
    </v-card>
    <AlertAPI />
    <LoadScreen/>
  </Container>
</template> 

<script>
import AlertAPI from "../components/alert_api/alert_api";
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/TitleDown/CardTitle/";
import Info from "../components/TitleDown/Info/";
import Table from "../components/TitleDown/Table/";
import AddDialog from "../components/TitleDown/AddDialog/";
import LoadScreen from "../components/load/index.vue"
import {
  GET_ALL_BILLSRECEIVE,
  DOWNLOAD_BILLSRECEIVE,
  FIND_BILLSRECEIVE,
  AUTO_COMPLETE
} from "../store/actions/bills_receive";
import { GET_ALL_CASHIER } from "../store/actions/cashier";
import { mapGetters } from "vuex";

export default {
  components: { Container, CardTitle, Info, Table, AlertAPI, AddDialog, LoadScreen },
  data: () => ({
    watchDownReceiver: true,
    indexGetDownReceive: 0,
    Title: "Baixa de Títulos a Receber",
    current: Number(),
    total_table: Number(),
    total: Number(),
    initial_date: Date,
    final_date: Date,
    dialog: Boolean(),
    array_security: Array(),
    enterpriseId: Number(),
    itemsDownReceiver: Array(),
    cashier: [],

    date: {
      date_initial: Date,
      date_final: Date,
    },



    downtitle: {
      id: Number(),
      id_cashier:String(),
      issuance_paymente: String(),
      account_plans_id: Number(),
      date_low: String(),
      title_balance: Number(),
      enterprise_id: Number(),
    },

    search: String(),
    header: [
      { text: "Status", value: "status" },
      { text: "Tipo do Título", value: "type_title" },
      { text: "Data de Emissão", value: "issuance_date" },
      { text: "Data de Vencimento", value: "issuance_due" },
      { text: "Número do Título", value: "number_title" },
      { text: "Valor do Título", value: "value_title" },
      { text: "Valor Líquido", value: "title_balance" },
      { text: "Baixar?", value: "down", align: "center" },
    ],
  }),

  computed: {
    ...mapGetters(["getBillReceive", "getCashier","getAutoCompleteBillsToReceive"]),
  },

  methods: {
    async autoCompleteBillsReceive(page = 1) {
      if (this.search.length >= 2) {
        let value = {
          search: this.search,
          page: page,
          limit: 10,
          paginate: true,
        };
        await this.$store.dispatch(AUTO_COMPLETE, value);
      } else{
        this.GetBills_Receive();
      }
    },
    current_Date() {
      var dNow = new Date();
      var month = dNow.getMonth() + 1;
      var dec, dFull;

      if (month < 10) {
        month = "0" + month;
      }

      if (dNow.getDate() <= 9) {
        dec = "0";

        dFull = dNow.getFullYear() + "-" + month + "-" + dec + dNow.getDate();
        this.date.date_initial = dFull;
        this.date.date_final = dFull;
      } else {
        dFull = dNow.getFullYear() + "-" + month + "-" + dNow.getDate();
        this.date.date_initial = dFull;
        this.date.date_final = dFull;
      }
    },
    async GetBills_Receive(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: true,
      };
      await this.$store.dispatch(GET_ALL_BILLSRECEIVE, value);
    },

    async GetAllNoPage() {
      let value = {
        page: null,
        limit: null,
        paginate: false,
      };
      await this.$store.dispatch(GET_ALL_CASHIER, value);
    },

    changeDialog() {
      this.dialog = !this.dialog;
    },

    async submit() {
      let titles = Array();//itemsDownReceiver
      // this.getBillReceive.data.forEach((e) => e.down && titles.push(e));
      this.itemsDownReceiver.forEach((e) => e.down && titles.push(e));
       
      try {
        titles.forEach((e) => {
          // this.downtitle.id = e.id;
          this.downtitle.billstoReceive_id = e.id;
          this.downtitle.account_plans_id = e.account_plans_id;
          this.downtitle.title_balance = parseFloat(e.title_balance).toFixed(1);
          this.downtitle.enterprise_id = e.enterprise_id;
          this.downtitle.issuance_paymente = e.issuance_payment;
          this.downtitle.annotation = e.annotation;
          this.downtitle.balance_movement =  parseFloat(this.total).toFixed(1);
          this.downtitle.date_movement = e.issuance_due;
          this.downtitle.description_movement =
            "O título é de N°" + e.number_title;
          this.downtitle.number_movement = e.number_title;
          this.downtitle.type_movement = "Entrada";
          this.downtitle.value_movement = this.total;
          let downtitle = { ...this.downtitle };

          this.$store.dispatch(DOWNLOAD_BILLSRECEIVE, downtitle).then(()=>{
               
              this.watchDownReceiver = !this.watchDownReceiver
          });
        });
      } catch (error) {
        alert(error);
      } finally {
        this.changeDialog();
      }
    },
    find(page = 1) {
      this.date.enterprise_id = this.enterpriseId;
      this.date.date_initial = this.convertDate(this.date.date_initial);
      this.date.date_final = this.convertDate(this.date.date_final);

      const payload = {
        ...this.date,
        page,
        paginate: true,
        limit: 10
      }

      this.$store.dispatch(FIND_BILLSRECEIVE, payload);
    },
    //Formato aceitável no método de pesquisa
    convertDate(item) {
      let year = item[0] + item[1] + item[2] + item[3];
      let mouth = item[5] + item[6];
      let day = item[8] + item[9];
      return year + mouth + day;
    },
  },
  watch: {
    "getAutoCompleteBillsToReceive": function(val){
      this.itemsDownReceiver = val.data
    },
    watchDownReceiver: function(){
       
        this.GetBills_Receive()
    },
    "itemsDownReceiver": {
      handler: function (val) {
        // itemsDownReceiver
        this.total = 0;
        val.forEach((e) => {
          if (e.down === true) {
            this.total += e.title_balance;
          }
        });
      },
      deep: true,
    },
    getBillReceive: function (val) {
      this.total_table = val.last_page;
      this.current = val.current_page;
      
      
      if(this.indexGetDownReceive < 1){
        var  arrayAux = Array()
        val.data.map((element)=> {
          if(element.status != 'Baixado'){
            arrayAux.push(element)
          }
        })
        this.itemsDownReceiver = [...arrayAux]
        this.indexGetDownReceive++   
        }else{
          this.indexGetDownReceive = 0
        }

    },
    current: function (val) {
      this.GetBills_Receive && this.GetBills_Receive(val);
    },
    getCashier: function (val) {
      this.cashier = Object.keys(val).map((key) => val[key]);
    },
    search: function () {
      this.autoCompleteBillsReceive();
    },
  },
  created() {
    this.GetAllNoPage();
    this.GetBills_Receive();
    this.current_Date();
  },
};
</script>

<style>
</style>


    