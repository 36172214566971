import axios from "axios";
import VueMask, { VueMaskDirective, VueMaskFilter } from "v-mask";
import Vue from "vue";
import App from "./App.vue";
import PrototypesFunctions from "./components/Utils/prototyps";
import intercepetor from "./interceptors";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import responsive from 'vue-responsive';
import apigestao from './apigestao'

require("./store/subscribers");
axios.defaults.baseURL = "https://api.gestaoadmbijueriel.topwaysystems.com.br/api/"
// Axios Reload
// RETIRAR
intercepetor(axios, store)


//ss

Vue.config.productionTip = false;
PrototypesFunctions();

Vue.directive('mask', VueMaskDirective);
Vue.use(responsive)
Vue.filter('VMask', VueMaskFilter)

store.dispatch("auth/attempt", localStorage.getItem("token")).then(() => {
  new Vue({
    responsive,
    router,
    store,
    vuetify,
    VueMask,
    render: (h) => h(App),
  }).$mount("#app");
});