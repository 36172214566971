
import Axios from 'axios'

const {
    GET_ALL_VENDAS
} = require('./actions/vendas')

const moduleVendas = {
    state: {
        vendas: {}
    },
    mutations: {
        GET_ALL_VENDAS(state, payload) {
            state.vendas = payload
        },
    },
    actions: {
        GET_ALL_VENDAS(context, payload) {
            Axios.post('sales/index', { page: payload.page, limit: payload.limit, paginate: payload.paginate }).then((response) => {
                context.commit(GET_ALL_VENDAS, response.data[0])
            })
        }
    },
    getters: {
        getterVendas(state) {
            return state.vendas
        }
    }
}

export default moduleVendas
