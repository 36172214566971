<template>
  <div>
    <v-dialog persistent v-model="getShowDialogRegisterCancelSequenceInvoice">
      <v-card width="100%">
        <v-card-title
          >Cancelamento de Nota de Sequênciade Nota Fiscal</v-card-title
        >
        <v-divider />
        <v-form ref="form" v-model="formValidate">
          <v-container>
            <v-row no-gutters>
              <v-col class="pa-1" cols="12" sm="4">
                <v-select
                  label="Empresa"
                  outlined
                  dense
                  :rules="rules[0]"
                  item-text="fantasyName"
                  :items="enterpriseList"
                  v-model="formData.enterprise"
                ></v-select>
              </v-col>
              <v-col class="pa-1" cols="12" sm="4">
                <v-select
                  label="Tipo De nota"
                  outlined
                  dense
                  :rules="rules[0]"
                  item-text="fantasyName"
                  :items="noteType"
                  v-model="formData.noteType"
                ></v-select>
              </v-col>
              <v-col class="pa-1" cols="12" sm="4">
                <v-text-field
                  label="Série da Nota"
                  outlined
                  dense
                  type="Number"
                  :rules="rules[0]"
                  v-model="formData.seriesNote"
                ></v-text-field>
              </v-col>
              <v-col class="pa-1" cols="12" sm="3"> </v-col>
            </v-row>
            <v-row>
              <v-col class="pr-4 pl-4" cols="12" sm="6">
                <v-text-field
                  label="*Número de Sequência Incial"
                  outlined
                  type="Number"
                  v-model="formData.initialNumberSequence"
                  :rules="rules[0]"
                  dense
                ></v-text-field>
              </v-col>
              <v-col class="pr-4 pl-4" cols="12" sm="6">
                <v-text-field
                  label="*Número de Sequência Final"
                  outlined
                  type="Number"
                  v-model="formData.finalNumberSequence"
                  :rules="rules[0]"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pr-4 pl-4" cols="12">
                <v-textarea
                  counter
                  outlined
                  label="*Motivo"
                  :rules="rules[1]"
                  v-model="formData.reason"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="error" @click="closeDialog">Cancelar</v-btn>
            <v-btn color="success" :disabled="activateButtom" @click="submit"
              >Adicionar</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";
import { ADD_ORDER_SEQUENCE_INVOICE } from "../../../store/actions/cancel_sequecence_invoice";
import { rulesText, rulesTextArea } from "./rules";
import { mapGetters } from "vuex";

export default {
  props: {
    component: Object(),
  },
  computed: {
    ...mapGetters([
      "getShowDialogRegisterCancelSequenceInvoice",
      "getEnterprises",
    ]),
    activateButtom: function () {
      if (this.formValidate) {
        return false;
      } else {
        return true;
      }
    },
  },

  data: function () {
    return {
      rules: [rulesText, rulesTextArea],
      noteType: ["NFE", "NFCE"],
      formValidate: Boolean(),
      currenctComponent: Object(),
      enterpriseList: Array(),
      formData: {
        enterprise: Object(),
        noteType: String(),
        initialNumberSequence: Number(),
        finalNumberSequence: Number(),
        reason: String(),
      },
    };
  },
  methods: {
    closeDialog() {
      this.$store.dispatch("setShowDialogRegisterCancelSequenceInvoice", false);
    },
    submit() {
      this.$store.dispatch(ADD_ORDER_SEQUENCE_INVOICE, this.formData);
    },
    async getAllEnterprise() {
      const payload = {
        page: null,
        limit: null,
        paginate: false,
      };
      await this.$store.dispatch(GET_ALL_ENTERPRISE, payload);
    },
  },
  watch: {
    formValidate: function () {},
    getEnterprises: function () {
      this.enterpriseList.push(this.getEnterprises[0]);
    },
  },
  created() {
    this.getAllEnterprise();
  },
  beforeDestroy() {
    this.formData = {};
  },
};
</script>

<style>
</style>