
<template>
  <div>
    <v-card class="ma-5">
      <CardTitle
        actionsShowDialog="setShowDialogRegisterCorrectionLetter"
        title="Carta de Correção de Nota Fiscal"
      />
      <Table :headers="headers" :lengthPaginator="lengthPaginator" />
      <AddDialog
        v-if="getShowDialogRegisterCorrectionLetter"
        :component="this"
      />
      <LoadScreen/>
    </v-card>
  </div>
</template>

<script>
import { GET_ALL_ENTERPRISE } from "../store/actions/enterprise";
import CardTitle from "../components/correction_letter/CardTitle";
import Table from "../components/correction_letter/Table";
import tableHeaders from "./headers_tables/Correction_letter.js";
import AddDialog from "../components/correction_letter/AddDialog";
import { mapGetters } from "vuex";
import LoadScreen from "../components/load/index.vue"
export default {
  name: "CorrectionLetter",
  components: {
    CardTitle,
    Table,
    AddDialog,
    LoadScreen
  },

  computed: {
    ...mapGetters(["getShowDialogRegisterCorrectionLetter"]),
  },
  data: function () {
    return {
      headers: tableHeaders,
      lengthPaginator: Number(),
    };
  },
  methods: {
    getAllEnterprise() {
      this.$store.dispatch(GET_ALL_ENTERPRISE, {
        page: null,
        limit: null,
        paginate: false,
      });
    },
    calculateNumberPage(itemQuantity) {
      if (itemQuantity % 5 > 1) {
        this.lengthPaginator = itemQuantity / 5 + 1;
      } else {
        this.lengthPaginator = itemQuantity / 5;
      }
    },
  },
  watch: {
    /*
    this.calculateNumberPage(20);
    */
  },
  created() {
    this.getAllEnterprise();
    /*
    Quando o backend estiver pronto !! Realizar um getAll, chamar a func calculateNumberPage passando o tamanho da array
    */
  },
};
</script>

<style>
</style>