const relatorio = [
  {
    "text": "Produto",
    "icon": "mdi-basket",
    "route": "/ReportProduct",
    "class": "white--text",
    "active": false
  },
  {
    "text": "Cliente",
    "icon": "mdi-account-group",
    "route": "/ReportClient",
    "class": "white--text",
    "active": false
  },
  {
    "text": "Fornecedor",
    "icon": "mdi-briefcase-plus",
    "route": "/ReportProvider",
    "class": "white--text",
    "active": false
  },
  {
    "text": "Entrada de Notas",
    "icon": "mdi-file-move-outline",
    "route": "/ReportNoteEntry",
    "class": "white--text",
    "active": false
  },
  {
    "text": "Saldo Estoque",
    "icon": "mdi-chart-box",
    "route": "/ReportStockBalance",
    "class": "white--text",
    "active": false
  },
  {
    "text": "Cts a Pagar",
    "icon": "mdi-cash-minus",
    "route": "/ReportBillsPay",
    "class": "white--text",
    "active": false
  },
  {
    "text": "Contas a Receber",
    "icon": "mdi-cash-plus",
    "route": "/ReportBillsReceive",
    "class": "white--text",
    "active": false
  },
  {
    "text": "Movimentação financeira",
    "icon": "mdi-cash-refund",
    "route": "ReportFinancialMovement",
    "class": "white--text",
    "active": false
  },
  {
    "text": "Livro Fiscal",
    "icon": "mdi-book-outline",
    "route": "/ReportFiscalBook",
    "class": "white--text",
    "active": false
  },
  {
    "text": "Conferência de Vendas",
    "icon": "mdi-book-outline",
    "route": "/saleConferenceReport",
    "class": "white--text",
    "active": true
  },
  {
    "text": "Fechamento de Caixa",
    "icon": "mdi-cash-lock",
    "route": "/closeCashierReport",
    "class": "white--text",
    "active": true
  }


]
export { relatorio };
