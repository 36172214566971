<template>
  <v-row justify="center">
    <v-dialog persistent max-width="800" v-model="component.confirmDialog">
      <v-card>
        <v-container>
          <v-card-title>Confirmação da Nota</v-card-title>
          <v-divider></v-divider>

          <v-form v-model="validAdd" ref="form">
            <v-divider></v-divider>
            <v-row>
              <v-col cols="4">
                <v-autocomplete
                  class="mt-2"
                  :items="accountPlan"
                  item-text="description"
                  item-value="id"
                  v-model="plan"
                  :rules="[(v) => !!v || 'Campo Obrigatório']"
                  outlined
                  required
                  dense
                  label="Plano de Contas"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="mt-1">
              <v-col>
                <v-autocomplete
                  dense
                  outlined
                  item-text="description"
                  return-object
                  v-model="form"
                  :items="formPayment"
                  required
                  :rules="[(v) => !!v || 'Campo Obrigatório']"
                  label="Forma"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete
                  :disabled="valid_form"
                  :items="paymentTerms"
                  item-text="description"
                  dense
                  v-model="condition"
                  outlined
                  :rules="required"
                  return-object
                  label="Condição"
                ></v-autocomplete>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  placeholder="0,00"
                  filled
                  readonly
                  dense
                  v-model="remaining"
                  color="red"
                  outlined
                  label="Restante"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  placeholder="0,00"
                  filled
                  readonly
                  dense
                  v-model="debt"
                  color="red"
                  outlined
                  label="Lançamentos"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  placeholder="0,00"
                  filled
                  readonly
                  v-model="component.totalNF"
                  dense
                  outlined
                  label="Valor da Nota"
                >
                </v-text-field>
              </v-col>

              <v-btn
                class="mt-3 mr-2 success"
                :disabled="!validAdd"
                @click="add_form()"
                rounded
              >
                <v-icon>mdi-plus-circle</v-icon></v-btn
              >
            </v-row>

            <v-data-table
              :headers="header"
              dense
              :items="invoices"
              :footer-props="{
                'items-per-page-options': [5, 10, -1],
                'items-per-page-text': 'Items por página',
              }"
              no-data-text="Sem registro!"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  color="yellow darken-4"
                  class="mr-2"
                  @click="
                    () => {
                      edit_item(item);
                    }
                  "
                  >mdi-pencil</v-icon
                >
              </template>
            </v-data-table>

            <v-dialog v-model="dialogEdit" persistent max-width="280">
              <v-card>
                <v-card-title> Editar Parcela </v-card-title>
                <v-divider></v-divider>
                <v-col>
                  <v-text-field
                    outlined
                    prefix="R$"
                    v-model="amount_value"
                    type="number"
                    label="Valor da parcela"
                  >
                  </v-text-field>
                </v-col>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn class="error" @click="dialogEdit = !dialogEdit"
                    >Cancelar</v-btn
                  >
                  <v-btn class="success mr-6" @click="confirmEdit()"
                    >Confirmar</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-divider></v-divider>
            <v-card-actions>
              <v-row>
                <v-col cols="6">
                  <v-btn block color="error" @click="cancel()">Cancelar</v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn
                    block
                    color="success"
                    @click="submitBills()"
                    :disabled="!valid"
                    >Confirmar</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { GET_ALL_FORM_OF_PAYMENT } from "../../../../store/actions/formofpayment";
import { GET_ALL_ACCOUNT_PLAN } from "../../../../store/actions/account_plan";
import { GET_ALL_PAYMENT_TERM } from "../../../../store/actions/paymentterm";
import { mapGetters } from "vuex";
export default {
  props: {
    component: Object,
    submit: Function,
  },
  data: () => ({
    header: [
      { text: "Tipo do Título", value: "form.description" },
      { text: "R$ Valor", value: "value_title" },
      { text: "Vencimento", value: "issuance_due" },
      { text: "Ações", value: "actions" },
    ],
    formPayment: Array(),
    paymentTerms: Array(),
    accountPlan: Array(),
    valid: Boolean(),
    validAdd: Boolean(),
    valid_form: Boolean(),
    dialogEdit: Boolean(),
    form: Number(),
    plan: Number(),
    debt: Number(),
    remaining: Number(),
    amount_value: Number(),
    condition: Number(),
    invoices: Array(),
    ObjectToUpdate: Object(),
    required: [(v) => !!v || "Obrigatório"],
  }),

  methods: {
    async GetFormPayment() {
      await this.$store.dispatch(GET_ALL_FORM_OF_PAYMENT, {
        page: 0,
        limit: null,
        paginate: false,
      });
    },
    async GetAccountPlan() {
      await this.$store.dispatch(GET_ALL_ACCOUNT_PLAN, {
        page: 0,
        limit: null,
        paginate: false,
      });
    },
    async GetPaymentTerms() {
      await this.$store.dispatch(GET_ALL_PAYMENT_TERM, {
        page: 0,
        limit: null,
        paginate: false,
      });
    },
    current_Date() {
      var dNow = new Date();
      var month = dNow.getMonth() + 1;
      var dec, dFull;

      if (month < 10) {
        month = "0" + month;
      }

      if (dNow.getDate() <= 9) {
        dec = "0";

        dFull = dNow.getFullYear() + "-" + month + "-" + dec + dNow.getDate();
        return dFull;
      } else {
        dFull = dNow.getFullYear() + "-" + month + "-" + dNow.getDate();
        return dFull;
      }
    },
    cancel() {
      this.component.confirmDialog = !this.component.confirmDialog;
      this.amount = 0;
      this.form = Object();
      this.plan = "";
      this.condition = "";
      this.invoices = [];
    },

    add_form() {
      let final = {};
      //Montagem do objeto
      final.type_title = this.condition.description;
      final.form = { ...this.form };
      final.condition = { ...this.condition };
      let totalNf = this.component.totalNF;
      let amountPay = final.condition.amount;
      let idx = 0;
      let issuance_date = this.component.form.date_of_issue;

      //Limpeza do Array
      this.invoices = [];

      // A vista
      if (amountPay === 0) {
        let valuePay = { ...final };
        valuePay.id = i;
        valuePay.status = "Aberto";
        valuePay.issuance_date = issuance_date;
        valuePay.dejection = null;
        valuePay.interest = null;
        valuePay.annotation = `Esse título e referente a nota fiscal ${this.component.form.number_grade}`;
        valuePay.value_title = totalNf;
        valuePay.value_net = totalNf;
        valuePay.title_balance = valuePay.value_title;
        valuePay.number_title = `${this.component.form.number_grade}/001`;
        idx = 0;
        valuePay.issuance_due = this.generate_due(idx);
        this.invoices.push(valuePay);
      } else {
        //Lógica das parcelas
        let amount = parseFloat((totalNf / amountPay).toFixed(2));
        let debtTotal = parseFloat((amount * amountPay).toFixed(2));

        ////Condição com pedencia de valor
        if (debtTotal != totalNf) {
          let remaining = parseFloat((totalNf - debtTotal).toFixed(2));
          for (var i = 1; i <= amountPay; i++) {
            if (i === amountPay) {
              let valuePay = { ...final };
              valuePay.id = i;
              valuePay.value_title = parseFloat(
                (amount + remaining).toFixed(2)
              );
              valuePay.title_balance = valuePay.value_title;
              valuePay.status = "Aberto";
              valuePay.issuance_date = this.current_Date();
              valuePay.dejection = null;
              valuePay.interest = null;
              valuePay.value_net = parseFloat((amount + remaining).toFixed(2));
              valuePay.annotation = `Esse título e referente a nota fiscal ${this.component.form.number_grade}`;
              valuePay.issuance_date = issuance_date;
              idx = i - 1;
              valuePay.issuance_due = this.generate_due(idx);
              valuePay.number_title = `${this.component.form.number_grade}/0${i}`;
              this.invoices.push(valuePay);
            } else {
              let valuePay = { ...final };
              valuePay.id = i;
              valuePay.status = "Aberto";
              valuePay.issuance_date = issuance_date;
              idx = i - 1;
              valuePay.issuance_due = this.generate_due(idx);
              valuePay.dejection = null;
              valuePay.interest = null;
              valuePay.annotation = `Esse título e referente a nota fiscal ${this.component.form.number_grade}`;
              valuePay.number_title = `${this.component.form.number_grade}/0${i}`;
              valuePay.value_title = amount;
              valuePay.value_net = amount;
              valuePay.title_balance = valuePay.value_title;
              this.invoices.push(valuePay);
            }
          }
        } else {
          //Sem pendencia
          for (i = 1; i <= amountPay; i++) {
            let valuePay = { ...final };
            valuePay.id = i;
            valuePay.status = "Aberto";
            valuePay.issuance_date = issuance_date;
            idx = i - 1;
            valuePay.issuance_due = this.generate_due(idx);
            valuePay.dejection = null;
            valuePay.interest = null;
            valuePay.annotation = `Esse título e referente a nota fiscal ${this.component.form.number_grade}`;
            valuePay.number_title = `${this.component.form.number_grade}/0${i}`;
            valuePay.value_title = amount;
            valuePay.value_net = amount;
            valuePay.title_balance = valuePay.value_title;
            this.invoices.push(valuePay);
          }
        }
      }
      this.debt = totalNf;
      this.remaining = 0;
    },
    generate_due(index) {
      let date_of_issue = this.component.form.date_of_issue;
      let aux_deadline = this.condition.deadline.split("/");
      let deadline = aux_deadline.map((e) => parseInt(e));
      let day = 0;
      let month = 0;
      let auxdate = date_of_issue.replaceAll("-", "/");

      let a = new Date(auxdate); // Criação de vencimento da Fatura
      a.setDate(a.getDate() + deadline[index]);
      day = a.getDate();
      if (day < 10) {
        day = `0${day}`;
      } else {
        day = day.toString();
      }
      month = a.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      } else {
        month = month.toString();
      }

      let final_date = `${day}/${month}/${a.getFullYear()}`;

      return final_date;
    },

    edit_item(item) {
      this.dialogEdit = !this.dialogEdit;
      this.amount_value = item.value_title;
      this.ObjectToUpdate = { ...item };
    },
    confirmEdit() {
      const reducer = (acc, cur) => parseFloat((acc + cur).toFixed(2));
      this.ObjectToUpdate.value_title = parseFloat(this.amount_value);
      this.ObjectToUpdate.title_balance = parseFloat(this.amount_value);
      this.invoices.Update(this.ObjectToUpdate);

      let NewDebt = this.invoices.map((e) => e.value_title);

      this.debt = NewDebt.reduce(reducer);
      this.remaining = parseFloat(
        (this.component.totalNF - this.debt).toFixed(2)
      );

      this.dialogEdit = !this.dialogEdit;
    },
    submitBills() {
      this.invoices.forEach((e) => {
        delete e.id;
      });
      this.submit(this.invoices, this.plan, this.condition);
    },
  },
  async created() {
    await this.GetFormPayment();
    await this.GetAccountPlan();
    await this.GetPaymentTerms();
  },
  computed: {
    ...mapGetters(["getFormOfPayment", "getAccount_Plan", "getPaymentTerms"]),
  },
  watch: {
    debt: function () {
      if (this.debt == this.component.totalNF) {
        this.valid = true;
      } else {
        this.valid = false;
      }
    },
    form: function (val) {
      this.component.form.formOfPayment_nf = { ...val};
      //verifica se a forma de pagamento e dinheiro ou a prazo
      if (val.code == "01") {
        this.component. form.indicationPayment = 1;
      } else {
       
        this.component. form.indicationPayment = 0;
      }
      if (val.description !== undefined) {
        let form = val.description.toLowerCase();
        if (form === "dinheiro") {
          this.paymentTerms.forEach((e) => {
            if (e.description.toLowerCase().replace(/\s+/g, "") === "avista") {
              let current = e;
              this.condition = current;
              this.valid_form = true;
            }
          });
        } else {
          this.valid_form = false;
        }
      }
    },
    getFormOfPayment: function (val) {
      this.formPayment = Object.values(val);
    },
    getAccount_Plan: function (val) {
      this.accountPlan = Object.values(val);
    },
    getPaymentTerms: function (val) {
      this.paymentTerms = Object.values(val);
    },
  },
};
</script>

<style>
</style>