module.exports = {
    GETALL_ROLES: 'GETALL_ROLES',
    UPDATE_USERS: 'UPDATE_USERS',
    GETALL_USERS: 'GETALL_USERS',
    CREATE_USERS: 'CREATE_USERS',
    GETALL_PERMISSIONS: 'GETALL_PERMISSIONS',
    DELETE_USER: 'DELETE_USER',
    CREATE_PERMISSION: 'CREATE_PERMISSION',
    UPDATE_PERMISSION: 'UPDATE_PERMISSION',
    DELETE_PERMISSION: 'DELETE_PERMISSION',
}