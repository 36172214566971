<template>
  <v-container>
    <div>
      <v-form :disabled="isDisable" ref="form" v-model="valid">
        <v-row>
          <v-col cols="12" lg="2" xl="6" md="5" sm="6">
            <v-text-field
              outlined
              label="Produto"
              append-icon="mdi-magnify"
              append-outer-icon="mdi-plus-circle"
              @click:append-outer="showDialogs('productdialog')"
              v-model="description.description_item"
              :rules="rules"
              v-on:change="choose_product()"
              @click:append="() => openDialogModalSearchProduct()"
            ></v-text-field
          ></v-col>

          <v-col cols="12" lg="1" xl="6" md="5" sm="6">
            <v-text-field
              outlined
              type="number"
              label="Qtd"
              :rules="rules"
              v-model="product.amount_add"
            ></v-text-field>
          </v-col>

          <v-col cols="12" lg="2" xl="6" md="5" sm="6">
            <v-text-field
              outlined
              type="number"
              label="Val. Venda"
              :rules="rules"
              v-model="product.value_sale"
            ></v-text-field
          ></v-col>

          <v-col cols="12" lg="2" xl="6" md="5" sm="6">
            <v-autocomplete
              outlined
              label="Alíquota"
              item-text="aliquot"
              return-object
              append-outer-icon="mdi-plus-circle"
              :rules="rules"
              :items="aliquotItems"
              v-model="product.aliquot_id"
              @click:append-outer="showDialogs('aliquotdialog')"
              @click:append="
                () => {
                  changeDialogAliquot();
                }
              "
              append-icon="mdi-magnify"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" lg="2" xl="6" md="5" sm="6">
            <v-autocomplete
              label="CFOP"
              item-text="code"
              item-value="id"
              outlined
              return-object
              append-icon="mdi-magnify"
              append-outer-icon="mdi-plus-circle"
              :items="cfopItems"
              :rules="rules"
              v-model="descriptionCFOP"
              @click:append-outer="showDialogs('cfopdialog')"
              @click:append="() => openDialogModalSearchCfop()"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" lg="2" xl="6" md="5" sm="6">
            <v-autocomplete
              item-text="description"
              item-value="id"
              outlined
              return-object
              label="CSOSN"
              v-if="component.validCST"
              v-model="cst_csosn"
              :rules="rules"
              :items="csosnItems"
            ></v-autocomplete>

            <v-autocomplete
              v-else
              v-model="cst_csosn"
              item-text="description"
              :rules="rules"
              :items="cstItems"
              append-outer-icon="mdi-plus-circle"
              @click:append-outer="showDialogs('cstdialog')"
              outlined
              return-object
              label="CST"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="4" md="4" xl="4" lg="4">
            <v-autocomplete
              outlined
              v-model="descriptionPis"
              item-text="description"
              item-value="id"
              :items="pis_cofis"
              label="CST/PIS"
              append-outer-icon="mdi-plus-circle"
              @click:append-outer="showDialogs('pisdialog')"
              return-object
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="4" md="4" xl="4" lg="4">
            <v-autocomplete
              outlined
              v-model="descriptionCofins"
              item-text="description"
              item-value="id"
              :items="pis_cofis"
              append-outer-icon="mdi-plus-circle"
              @click:append-outer="showDialogs('cofinsdialog')"
              label="CST/COFIS"
              return-object
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4" xl="4" lg="4">
            <v-text-field
              outlined
              v-model="product.aliquot_ipi_product"
              label="Valor de IPI"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="1" xl="1" md="2" sm="2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  rounded
                  :disabled="isDisable"
                  elevation="5"
                  v-bind="attrs"
                  @click="addProduct()"
                  v-on="on"
                  class="success mt-2"
                >
                  <v-icon>mdi-plus-circle</v-icon>
                </v-btn>
              </template>
              <span>Adicionar</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-form>
        <v-divider></v-divider>
        <TableRevenues
          :component="component"
          :actionDelete="deleteItens"
          :actionEdit="changeDialogEdit"
          :getters="getRevenusItens"
        />
        <AddProduct
          v-if="dialogModalSearchProduct"
          :changeDialog="openDialogModalSearchProduct"
          :component="this"
        />
        <editItem
          v-if="dialogEdit"
          :dialog="dialogEdit"
          :disabledField="component.validCST"
          :changeDialog="changeDialogEdit"
          :submit="updateItens"
          :component="this"
        />
        <ProductDialog :out_of_register="true" />
        <AliquotDialog />
        <CFOPDialog />
        <CSTDialog />
        <PISDialog v-if="getShowDialogRegisterPISCOFINS" />
        <COFINSDialog v-if="getShowDialogRegisterPISCOFINS" />
      
    </div>
  </v-container>
</template>

<script>
import AddProduct from "./DialogProduct/index.vue";
import TableRevenues from "../../Table/TableRevenues/index.vue";
import editItem from "../../AddDialog/EditItensNote/";
import {
  ADD_REVENUES_ITENS,
  DESTROY_REVENUES_ITENS,
  UPDATE_REVENUES_ITENS,
} from "../../../../store/actions/revenuesitens";
import { GET_PRODUCT_BY_ID } from "../../../../store/actions/product";
import { GET_ALL_CFOP } from "../../../../store/actions/cfop";
import { GET_ALL_ALIQUOT } from "../../../../store/actions/aliquot";
import { GET_ALL_CST } from "../../../../store/actions/cst";
import { GET_ALL_CSOSN } from "../../../../store/actions/csosn";
import { GET_ALL_PIS_COFINS } from "../../../../store/actions/pis_cofins";

import ProductDialog from "../../../products/AddDialog";
import AliquotDialog from "../../../aliquot/AddDialog";
import CFOPDialog from "../../../cfop/AddDialog";
import CSTDialog from "../../../cst/AddDialog";
import PISDialog from "../../../pis_cofins/AddDialog";
import COFINSDialog from "../../../pis_cofins/AddDialog";

import { mapGetters } from "vuex";

export default {
  props: {
    component: Object,
    isDisable: Boolean,
  },
  data: () => ({
    enterprise: Array(),
    dialogModalSearchProduct: Boolean(),
    dialogModalSearchCst: Boolean(),
    dialog_cfop: Boolean(),
    loadingAddProduct: Boolean(),
    dialog_aliquot: Boolean(),
    dialogEdit: Boolean(),
    product: Object(),
    ObjectToUpdate: Object(),
    description: Object(),
    descriptionAliquot: Object(),
    descriptionCFOP: Object(),
    descriptionPis: Object(),
    descriptionCofins: Object(),
    selectedItem: Object(),
    cst_csosn: Object(),
    aliquotItems: Array(),
    cfopItems: Array(),
    cstItems: Array(),
    csosnItems: Array(),
    pis_cofis: Array(),
    rules: [(v) => !!v || "Campo Obrigatório"],
    valid: Boolean(),
  }),
  components: {
    TableRevenues,
    AddProduct,
    editItem,
    ProductDialog,
    AliquotDialog,
    CFOPDialog,
    CSTDialog,
    PISDialog,
    COFINSDialog,
  },
  computed: {
    ...mapGetters([
      "getAliquot",
      "getCfop",
      "getRevenusItens",
      "getCsosn",
      "getCst",
      "getPisCofins",
      "getProduct",
      "aliquotToAdd",
      "getEnterpriseObject",
      "getShowDialogRegisterPISCOFINS"
    ]),
  },
  methods: {
    showDialogs(dialogs) {
      if (dialogs == "productdialog") {
        this.$store.dispatch("setShowDialogRegisterProduct", true);
      } else if (dialogs == "aliquotdialog") {
        this.$store.dispatch("setShowDialogRegisterAliquot", true);
      } else if (dialogs == "cfopdialog") {
        this.$store.dispatch("setShowDialogRegisterCFOP", true);
      } else if (dialogs == "cstdialog") {
        this.$store.dispatch("setShowDialogRegisterCST", true);
      } else if (dialogs == "pisdialog") {
        this.$store.dispatch("setShowDialogRegisterPISCOFINS", true);
      } else if (dialogs == "cofinsdialog") {
        this.$store.dispatch("setShowDialogRegisterPISCOFINS", true);
      }
    },
    
    autoLoad(item){
      this.description.description_item = item.description_item
      this.product.value_sale = item.cost_price
      this.product.aliquot_id = item.aliquot
      this.descriptionCFOP = item.cfop
      this.cst_csosn = item.csons
      this.descriptionPis = item.cst_pis
      this.descriptionCofins = item.cst_confis
      this.cst_csosn = item.cst
      
    },

    clickProduct(item){

      this.product = {...item}
      this.autoLoad(item)
      this.dialogModalSearchProduct = !this.dialogModalSearchProduct
    },

    async choose_product() {
      let id = this.description.description_item;
      await this.$store.dispatch(GET_PRODUCT_BY_ID, id).then((response) => {
        this.product = {...response}
        this.autoLoad(response)
      });
    },
    async addProduct() {
      //Padrão de item para ser adicionado na tabela, Seguir fielmente esse parâmetros
      let products = {
        bar_code: this.product.bar_code,
        ncm_code: this.product.ncm.code,
        calculation_base_mode: this.product.calculation_base_mode,
        cst_code_confis:this.product.cst_confis.code,
        tax_replacement_base_modality: this.product.tax_replacement_base_modality,
        additional_aliquot_tax_st: this.product.additional_aliquot_tax_st,
        percentagem_calc_st_reduction : this.product.percentagem_calc_st_reduction,
        percentage_supported_final_consumer: this.product.percentage_supported_final_consumer,
        source_product:  this.product.source_product,
        value_products: parseFloat(this.product.cost_price),
        aliquot:parseFloat(this.product.aliquot.aliquot) ,
        enterprises: [this.getEnterpriseObject] ,
        description_product:this.product.description_item,
        amount: parseFloat(this.product.amount_add) ,
        amount_add: parseFloat(this.product.amount_add) ,
        value_sale: parseFloat(this.product.cost_price) ,
        aliquot_icms_product: parseFloat(this.product.aliquot.aliquot) ,
        cfop_id:this.product.cfop_id,
        aliquot_ipi_product: parseFloat(this.product.aliquot_ipi_product),
        csosn_id:this.product.csosn_id,
        description_unit:this.product.unit.sigla,
        cst_code_ipi: this.product.cst_ipi ? String(this.product.cst_ipi.code) : null,
        cst_pis_id:this.product.cst_pis_id,
        cst_confis_id:this.product.cst_confis_id,
        product_id:this.product.id,
        cst_id:this.product.cst_id,
        cst_code_pis:this.product.cst_pis.code,
        code_cfop: String(this.product.cfop.code),
        code_csosn:this.product.csons.code,
        code_cst:String(this.product.cst.code),
        unit_id:this.product.unit.id,
        value_sub_total_product: 0,
        value_icms_st_product: 0,
        value_accounting_product: 0,
        value_base_ipi_product: 0,
        value_base_icms_st_product: 0,
        value_ipi_product: 0,
        value_icms_product: 0,
        value_free_icms_product: 0,
        value_icms_outher_product: 0,
        value_ipi_free_product: 0,
        value_ipi_outher_product: 0,
        value_descont: 0,
        percentageRelatedCombatPovert:0,
        value_base_icms_product: 0,
        aliquot_pis: 0,
        aliquot_confis: 0,
        value_base_pis_product: 0,
        value_book:0, 
        value_pis_product: 0,
        value_base_confis_product: 0,
        value_confis_product: 0,
        value_base_pis_st_product: 0,
        value_pis_st_product: 0,
        value_base_cofins_st_product: 0,
        value_cofins_st_product: 0,
        value_other_pis_product: 0,
        value_other_confis_product: 0,
        value_free_confis_product: 0,
        value_free_pis_product: 0,
        value_icms_free_product: 0,
        value_subtotal_product: this.product.cost_price * this.product.amount_add,
      };
       
      await this.$store.dispatch(ADD_REVENUES_ITENS, products).then((response) => {
          this.$refs.form.reset()
      });

    },
    changeDialogEdit(item) {
      this.product = {...item}
      this.dialogEdit = !this.dialogEdit;
    },
    async updateItens(val) {
      let products = {
        value_products: parseFloat(val.value_products),
        aliquot:parseFloat(val.aliquot) ,
        enterprises: [this.getEnterpriseObject] ,
        description_product:val.description_product,
        amount_add: parseFloat(val.amount_add) ,
        value_sale: parseFloat(val.value_sale) ,
        aliquot_icms_product: parseFloat(val.aliquot_icms_product) ,
        cfop_id:val.cfop_id,
        aliquot_ipi_product: parseFloat(val.aliquot_ipi_product),
        csosn_id:val.csosn_id,
        description_unit:val.description_unit,
        cst_code_ipi: String(val.cst_code_ipi),
        cst_pis_id:val.cst_pis_id,
        cst_confis_id:val.cst_confis_id,
        product_id:val.product_id,
        cst_id:val.cst_id,
        cst_code_pis:val.cst_code_pis,
        cst_code_confis:val.cst_code_confis,
        code_cfop: String(val.code_cfop),
        code_csosn:val.code_csosn,
        code_cst:val.code_cst,
        unit_id:val.unit_id,
        value_sub_total_product: 0,
        value_icms_st_product: 0,
        value_accounting_product: 0,
        value_base_ipi_product: 0,
        value_base_icms_st_product: 0,
        value_ipi_product: 0,
        value_icms_product: 0,
        value_free_icms_product: 0,
        value_icms_outher_product: 0,
        value_ipi_free_product: 0,
        value_ipi_outher_product: 0,
        value_descont: 0,
        value_base_icms_product: 0,
        aliquot_pis: 0,
        aliquot_confis: 0,
        value_base_pis_product: 0,
        value_pis_product: 0,
        value_base_confis_product: 0,
        value_confis_product: 0,
        value_base_pis_st_product: 0,
        value_pis_st_product: 0,
        value_base_cofins_st_product: 0,
        value_cofins_st_product: 0,
        value_other_pis_product: 0,
        value_other_confis_product: 0,
        value_free_confis_product: 0,
        value_free_pis_product: 0,
        value_icms_free_product: 0
      };

      await this.$store.dispatch(UPDATE_REVENUES_ITENS, products).then((response) => {
        
        this.changeDialogEdit();
      });
    },

    async GetAliquot() {
      await this.$store.dispatch(GET_ALL_ALIQUOT, {
        page: 0,
        limit: null,
        paginate: false,
      });
    },
    async GetCfop() {
      await this.$store.dispatch(GET_ALL_CFOP, {
        page: 0,
        limit: null,
        paginate: false,
      });
    },
    async GetCst() {
      await this.$store.dispatch(GET_ALL_CST, {
        page: 0,
        limit: null,
        paginate: false,
      });
    },
    async GetCsosn() {
      await this.$store.dispatch(GET_ALL_CSOSN, {
        page: 0,
        limit: null,
        paginate: false,
      });
    },
    async GetPisCofins() {
      await this.$store.dispatch(GET_ALL_PIS_COFINS, {
        page: 0,
        limit: null,
        paginate: false,
      });
    },
    openDialogModalSearchProduct() {
      this.dialogModalSearchProduct = !this.dialogModalSearchProduct;
    },
    openDialogModalSearchCst() {
      this.dialogModalSearchCst = !this.dialogModalSearchCst;
    },
    openDialogModalSearchCfop() {
      this.dialog_cfop = !this.dialog_cfop;
    },
    changeDialogAliquot() {
      this.dialog_aliquot = !this.dialog_aliquot;
    },
    deleteItens(item) {
      if (!this.isDisable) {
        try {
          this.$store.dispatch(DESTROY_REVENUES_ITENS, item.id);
        } catch (error) {
          alert(error);
        }
      } else {
        alert("Não é permitido na ação de Informações.");
      }
    },
    clearItens() {
      try {
        this.$store.dispatch(DESTROY_REVENUES_ITENS, "all");
      } catch (error) {
        alert(error);
      }
    },
    valid_cst_csosn(val) {
      if (this.component.validCST) {
        this.cst_csosn = val.csons;
      } else {
        this.cst_csosn = val.cst;
      }
    },
  },

  watch: {
    description: function (val) {
      if (val.id !== undefined) {
        this.product.product_id = val.id;
        this.descriptionCFOP = val.cfop;
        this.descriptionAliquot = val.aliquot;
        this.descriptionPis = val.cst_pis;
        this.descriptionCofins = val.cst_confis;
        (this.product.description_product = val.description_item),
          (this.product.description_cfop_code = val.cfop.description);
        this.product.cfop_id = val.cfop.id;
        this.product.code_cfop = val.cfop.code;
        this.product.aliquot_id = val.aliquot_id;
        this.product.aliquot = val.aliquot.aliquot;
        this.product.value_sale = val.sale_price;
        this.product.ncm_code = val.ncm.code;
        (this.product.ncm_id = val.ncm.id),
          (this.product.unit_id = val.unit.id),
          (this.product.description_unit = val.unit.sigla),
          (this.product.aliquot_id = val.aliquot_id);
        this.product.aliquot_code = val.aliquot.aliquot;
        this.product.cst_id = val.cst.id;
        this.product.code_cst = val.cst.code;
        this.product.aliquot_icms_product = val.aliquot.aliquot;
        this.product.code_csosn = val.csons.code;
        this.product.cst_code_ipi = null;
        this.product.csosn_id = val.csons.id;
        this.product.bar_code = val.bar_code
        this.product.calculation_base_mode = val.calculation_base_mode
        this.product.source_product = val.source_product
        this.product.tax_replacement_base_modality = val.tax_replacement_base_modality
        this.product.additional_aliquot_tax_st = val.additional_aliquot_tax_st
        this.product.percentagem_calc_st_reduction = val.percentagem_calc_st_reduction
        this.product.percentage_supported_final_consumer = val.percentage_supported_final_consumer
        this.product.aliquot_fcp = val.aliquot_fcp
        this.product.percentageRelatedCombatPovert =  0

       
      if (val.ipi === null) {
          this.product.aliquot_ipi_product = 0;
        } else {
          this.product.aliquot_ipi_product = val.ipi;
        }
        this.valid_cst_csosn(val);
      }
    },
    aliquotToAdd: function () {
      this.aliquotItems.push(this.aliquotToAdd);
      this.product.aliquot_id = this.aliquotToAdd.id;
    },
    // descriptionAliquot: function (val) {
    //   this.product.description_aliquot_code = val.aliquot;
    //   this.product.aliquot_id = val.id;
    // },
    descriptionCFOP: function (val) {
      this.product.description_cfop_code = val.code;
      this.product.cfop_id = val.id;
    },
    descriptionPis: function (val) {
      this.product.cst_code_pis = val.code;
      this.product.cst_pis_id = val.id;
    },
    descriptionCofins: function (val) {
      this.product.cst_confis_id = val.id;
      this.product.cst_code_confis = val.code;
    },
    getAliquot: function () {
      this.aliquotItems = Object.values(this.getAliquot);
      // if (!this.dialog_aliquot) {
      //   this.aliquotItems = Object.values(val);
      // }
    },
    getCfop: function (val) {
      if (!this.dialog_cfop) {
        this.cfopItems = Object.values(val);
      }
    },
    getCsosn: function (val) {
      this.csosnItems = Object.values(val);
    },
    getCst: function (val) {
      this.cstItems = Object.values(val);
    },
    getPisCofins: function (val) {
      this.pis_cofis = Object.values(val);
    },
    // getProduct: function (val) {
    //   this.description = { ...val.data[0] };
    // },
  },

  async created() {
    this.enterprise[0] = { ...this.getEnterpriseObject };

    await this.GetAliquot();
    await this.GetCfop();
    await this.GetPisCofins();
    if (this.component.validCST) {
      this.GetCsosn();
    } else {
      this.GetCst();
    }
  },
  destroyed() {
    this.clearItens();
  },
};
// product.product_id = this.product.id;
// product.amount = this.product.amount_add;
// product.description_cfop_code = this.product.description_cfop_code;
// product.aliquot_id = this.product.aliquot_id;
// product.aliquot = this.product.aliquot;
// product.product_id = this.product.product_id_add;
// product.value_sale = this.product.value_sale;
// product.code_cfop=  this.product.code_cfop
// product.ncm_code = this.description.ncm.code;
// product.enterprises = this.enterprise;
// product.aliquot_ipi_product = this.product.aliquot_ipi_product;

// product.cst_pis_id = this.product.cst_pis_id;
// product.cst_pis_code = this.product.cst_pis_code;
// product.cst_confis_code = this.product.cst_confis_code;
// product.tax_operation_types_id = this.component.form.tax_operation_types_id;
// product.aliquot_pis = this.enterprise[0].aliquot_pis;
// product.aliquot_cofins = this.enterprise[0].aliquot_cofins;
// product.aliquot_icms_product = this.product.aliquot_icms_product;
// product.code_item = 0;
// product.description_unit = this.product.description_unit;
// product.description_product = this.product.description_item;
// product.cst_code_ipi; //= this.description.cst_ipi.code;
// product.cst_confis_id = this.product.cst_cofins_id;
// product.product_id = this.description.product_id;
// product.cfop_id = this.description.cfop_id;
// product.csosn_id = this.description.csosn_id;
// product.cst_id = this.description.cst_id;
// product.revenues_id = null;

//  this.product.aliquot_ipi_product = this.product.aliquot_ipi_product;
// product.cst_id = this.description.cst_id;
// product.revenues_id = null;

//= this.description.cst_ipi.code;
// product.cst_code_ipi; //= this.description.cst_ipi.code;
//addVia description
// this.product.cst_cofins_id = this.product.cst_cofins_id;
// this.product.cst_pis_id = this.product.cst_pis_id;
// product.cst_confis_code = this.product.cst_confis_code;
// product.cst_code_confis = this.description.cst_confis.code;
// product.cst_cofins_code = this.product.cst_confis.code;
// product.cst_confis_id = this.product.cst_cofins_id;
// product.cfop_id = this.description.cfop_id;

//sem necessidade
//
</script>

<style></style>


