var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600"},model:{value:(this._props.component.addDialog),callback:function ($$v) {_vm.$set(this._props.component, "addDialog", $$v)},expression:"this._props.component.addDialog"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v("Forma de Pagamento")]),_c('v-container',[_c('v-divider'),_c('v-form',{ref:"form",staticClass:"mt-6",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-col',[_c('v-text-field',{attrs:{"label":"*Codigo","maxlength":"4","readonly":_vm.justVisible,"outlined":"","rules":[
                () => !!_vm.code || 'Campo Obrigatorio',
                () =>
                  (!!_vm.code && _vm.code.length === 2) ||
                  'Campo tem que ser com 2 digitos',
              ]},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"*Descrição","readonly":_vm.justVisible,"rules":[
                () => !!_vm.description || 'Campo Obrigatorio',
                () =>
                  (!!_vm.description && _vm.description.length >= 2) ||
                  'Campo tem que ser maior do que 2',
              ],"outlined":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"error",on:{"click":() => {
                  this.close();
                }}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-cancel")]),_vm._v(" Cancelar")],1),(!_vm.justVisible)?_c('v-btn',{staticClass:"mr-4",attrs:{"color":"success","disabled":!_vm.valid},on:{"click":() => {
                  _vm.submit();
                }}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-content-save")]),_vm._v(" Salvar")],1):_vm._e()],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }