import { GET_ALL_ENTERPRISE } from "../../../../store/actions/enterprise";
import { GET_ALL_COMISSION_TYPE } from "../../../../store/actions/commission_type"
import { SET_REPORT_COMISSION_SELLERS } from "../../../../store/actions/report_comission_sellers";
import { headerPDF } from "./headers";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

function getReport() {
  try {
    this.$store.dispatch(SET_REPORT_COMISSION_SELLERS, this.formData).then((response) => {
      viewPDF(response)
    })
  } catch (error) {
    return error;
  }
}

function viewPDF(object) {
  // this.iterator++

  var valuesOfTable = Array();

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  if (object.length > 0) {
    for (let count = 0; count < object.length; count++) {
      valuesOfTable.push(Array())
      valuesOfTable[count].push(object[count].client_id)
      valuesOfTable[count].push(object[count].status)
      valuesOfTable[count].push(object[count].data_sale)
      valuesOfTable[count].push(object[count].number_sale)
      valuesOfTable[count].push(object[count].series)
      valuesOfTable[count].push(object[count].value_total_products)
      valuesOfTable[count].push(object[count].value_descount_total)
      valuesOfTable[count].push(object[count].base_cal_icms_total)
      valuesOfTable[count].push(object[count].value_icms_total)
      valuesOfTable[count].push(object[count].value_total_note)
    }
  } else {
    valuesOfTable.push(Array())
    valuesOfTable[0].push(" ")
    valuesOfTable[0].push(" ")
    valuesOfTable[0].push(" ")
    valuesOfTable[0].push(" ")
    valuesOfTable[0].push(" ")
    valuesOfTable[0].push(" ")
    valuesOfTable[0].push(" ")
    valuesOfTable[0].push(" ")
    valuesOfTable[0].push(" ")
    valuesOfTable[0].push(" ")
  }


  var docDefinition = {
    pageOrientation: "landscape",
    content: [
      { text: "Relatório de Comissão de Vendedores", style: "header" },
      {
        layout: "lightHorizontalLines",
        table: {
          headerRows: 1,
          body: [headerPDF],
        },
      },
    ],
    styles: {
      header: {
        fontSize: 22,
        bold: true,
        lineHeight: 2,
      },
    },
  };

  valuesOfTable.forEach(element => {
    docDefinition.content[1].table.body.push(element);
  });
  pdfMake.createPdf(docDefinition).open();
}

function getAllComissionType() {
  var payload = {
    page: null,
    limit: null,
    paginate: false,
  };
  this.$store.dispatch(GET_ALL_COMISSION_TYPE, payload)
}

function getAllEnterprises() {
  var payload = {
    page: null,
    limit: null,
    paginate: false,
  };
  this.$store.dispatch(GET_ALL_ENTERPRISE, payload);
}

export default {
  getAllEnterprises,
  getReport,
  viewPDF,
  getAllComissionType
};
