<template>
  <v-row justify="center">
    <v-dialog v-model="component.dialogDeadline" width="600" persistent>
      <v-card>
        <v-container>
          <v-card-title>Prazo</v-card-title>
          <v-divider></v-divider>

          <v-container>
            <v-row class="mt-4">
              <!-- <p class="mx-4 mt-3">Parcelas</p> -->
              <div style="width: 40%">
                <v-text-field
                  class="mr-2"
                  type="number"
                  outlined
                  label="Parcelas"
                  v-model="amount"
                  dense
                ></v-text-field>
              </div>
            </v-row>
            <v-row class="mt-4">
              <!-- <p class="mx-4 mt-3">Prazo / dias</p> -->
              <div style="width: 40%">
                <v-text-field
                  class="mr-2 mt-2"
                  type="number"
                  outlined
                  label="Prazo / Dias"
                  v-model="deadline"
                  dense
                ></v-text-field>
              </div>
              <v-btn
                class="primary mr-4 mt-2"
                @click="add()"
                :disabled="!isDeadlineValid || validAdd"
                >Adicionar</v-btn
              >
            </v-row>
          </v-container>

          <v-data-table :headers="header" :items="desserts">
            <template v-slot:item.action="{ item, index }">
              <v-icon class="orange--text" @click="edit(item)"
                >mdi-pencil</v-icon
              >
              <v-icon class="red--text" @click="deleteItem(index)"
                >mdi-delete</v-icon
              >
            </template>
          </v-data-table>
          <v-row class="mb-1 mt-1">
            <v-spacer></v-spacer>
            <v-btn rounded class="error mr-2" @click="close()">Cancelar</v-btn>
            <v-btn
              rounded
              class="primary mr-4"
              :disabled="validSave"
              @click="save()"
              >Salvar</v-btn
            >
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editDialog" width="300">
      <v-card>
        <v-card-title>Editar Valor</v-card-title>
        <v-divider></v-divider>
        <v-text-field
          outlined
          v-model="editline"
          class="px-4 mt-4"
        ></v-text-field>
        <v-divider></v-divider>
        <v-row class="mt-4">
          <v-spacer></v-spacer>
          <v-btn class="error mr-2 mb-2" @click="editDialog = false"
            >Cancelar</v-btn
          >
          <v-btn class="primary mr-6" @click="upgrade()">Atualizar</v-btn>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    component: Object,
  },

  data: () => ({
    valid: false,
    validAdd: true,
    validSave: true,
    desserts: [],
    deadline: "",
    count: Number,
    aux: Object,
    editDialog: false,
    id: Number,
    amount: Number,
    editline: "",
    header: [
      { text: "Parcela", value: "id" },
      { text: "Prazo", value: "deadline" },
      { text: "Actions", value: "action" },
    ],
  }),

  computed: {
    isDeadlineValid() {
      return this.deadline >= 0;
    },
    validAdd() {
      return this.deadline === "";
    },
  },

  methods: {
    add() {
      this.count++;
      let aux = {
        id: this.count,
        deadline: this.deadline,
      };
      this.desserts.push(aux);
      this.deadline = "";
    },

    close() {
      this.component.dialogDeadline = !this.component.dialogDeadline;
    },
    deleteItem(index) {
      this.desserts.splice(index, 1);
    },
    edit(item) {
      this.editDialog = !this.editDialog;
      this.id = item.id;
      this.editline = item.deadline;
    },

    upgrade() {
      this.desserts[this.id - 1].deadline = this.editline;
      this.editDialog = !this.editDialog;
    },

    save() {
      let aux = "";
      this.desserts.forEach((e) => {
        if (aux == "") {
          aux = `${e.deadline}`;
        } else {
          aux = `${aux}/${e.deadline}`;
        }
      });
      this.component.deadline = aux;
      this.close();
    },
  },

  watch: {
    amount: function (val) {
      this.component.amount = val;
      if (val > this.desserts.length) {
        this.validAdd = false;
        this.validSave = true;
      } else {
        this.validAdd = true;
        this.validSave = true;
      }
    },
    desserts: function (val) {
      if (val.length >= this.amount) {
        this.validAdd = true;
        this.validSave = false;
      } else {
        this.validAdd = false;
        this.validSave = true;
      }
    },
  },

  created() {
    this.count = 0;
    // this.amount = parseInt(this.component.amount);
  },
};
</script>

<style>
</style>