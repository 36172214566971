<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" ref="cardTite" />
      </v-card-text>
      <v-card-text>
        <v-text-field v-model="search" label="Pesquisar" outlined append-icon="mdi-magnify"></v-text-field>
      </v-card-text>
      <AddDialog
        v-if="addDialog"
        :dialog="addDialog"
        :changeDialog="alterDialog"
        title="Registrar"
        :enterprise="enterprise"
        :enterpriseType="enterpriseType"
        :component="this"
      />
      <Table
        :component="this"
        :getters="getRevenues"
        :search="search"
        :headers="headers"
        :isLoad="loading"
      />
    </v-card>
    <AlertApi />
    <LoadScreen/>
  </Container>
</template>

<script>
// v-if="permission.Visualizar"
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/revenues/CardTitle";
import Table from "../components/revenues/Table/";
import { GET_ALL_REVENUES,GET_FILTER_REVENUE } from "../store/actions/revenues";
import AlertApi from "../components/alert_api/alert_api";
import { mapGetters } from "vuex";
import LoadScreen from "../components/load/index.vue"

export default {
  data: () => ({
    search: "",
    code: "",
    getter: "getRevenues",
    addDialog: Boolean(),
    destroyDialog: false,
    isNote: Boolean(),
    loading: Boolean(),
    idToDestroy: 0,
    objectToUpdate: {},
    enterprise: Object(),
    title: "Faturamento",
    permission: Object(),
    headers: [
      { text: "Status", value: "status" },
      { text: "Data de Faturamento", value: "date_of_issue" },
      { text: "Cliente", value: "cliente.people.name" },
      { text: "Número Nota", value: "number_grade" },
      { text: "Série", value: "series" },
      { text: "Valor dos Produtos", value: "value_total_products" },
      { text: "Valor da Nota", value: "value_total_note" },
      { text: "Base ICMS", value: "base_calc_icms_total" },
      { text: "Valor ICMS", value: "value_total_note" },
      { text: "Base IPI", value: "value_total_note" },
      { text: "Valor IPI", value: "value_total_note" },
      { text: "Valor Isento", value: "value_total_note" },
      { text: "Valor Outros", value: "value_total_note" },
      { text: "Ações", value: "actions" }
    ]
  }),
  computed: { ...mapGetters(["getRevenues", "getMovimentacaoLinks"]) },

  methods: {
    async revenues_filter(page = 1) {
      if (!this.filter === true) {
        this.GetAll();
      } else {
        let value = {
          search: this.filter,
          page: page,
          limit: 10,
          paginate: true
        };
        await this.$store.dispatch(GET_FILTER_REVENUE, value);
      }
    },
    async GetAll(page = 1) {
      await this.$store.dispatch(GET_ALL_REVENUES, {
        status: "Autorizada",
        page: page,
        limit: 10,
        paginate: true
      });
    },

    async alterDialog() {
      this.addDialog = !this.addDialog;
    }
  },

  async created() {
    this.loading = true;
    await this.GetAll();
    this.loading = false;
  },
  watch: {
    search: function() {
      this.revenues_filter();
    }
  },
  mounted() {
    let name = document.URL.split("/").pop();
    this.permission = this.getMovimentacaoLinks.getPermissions(name);

    this.$watch(
      () => {
        return this.$refs.cardTite.selectValue;
      },
      val => {
        this.enterpriseType = val.tax_regime_id;
        this.enterpriseId = val.id;
      }
    );
  },

  components: {
    Container,
    CardTitle,
    Table,
    AlertApi,
    LoadScreen,
    AddDialog: () => import("../components/revenues/AddDialog/")
  }
};
</script>

<style scoped></style>
