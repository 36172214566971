const ADD_SELLER = "ADD_SELLER";
const UPDATE_SELLER = "UPDATE_SELLER";
const GET_ALL_SELLER = "GET_ALL_SELLER";
const DESTROY_SELLER = "DESTROY_SELLER";
const ITEM_TO_UPDATE_SELLER = "ITEM_TO_UPDATE_SELLER"
const GET_AUTOCOMPLETE_SELLER = "GET_AUTOCOMPLETE_SELLER"
module.exports = {
    ADD_SELLER,
    UPDATE_SELLER,
    GET_ALL_SELLER,
    DESTROY_SELLER,
    ITEM_TO_UPDATE_SELLER,
    GET_AUTOCOMPLETE_SELLER
}
