<template>
  <div>
    <v-data-table
      :headers="header"
      :items="results"
      no-data-text="Sem registro!"
      hide-default-footer
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          color="blue"
          class="mr-2"
          @click="
            () => {
              view_info(item);
            }
          "
          >mdi-information-outline</v-icon
        >
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-pagination v-model="current" :length="total" color="success"> </v-pagination>
    <ViewInfo
      v-if="dialog_fiscal"
      :component="this"
      :objectToView="objectToView"
    />
  </div>
</template>

<script>
import ViewInfo from "../AddDialog/";
import { GET_ALL_BOOKSUPERVISION_OUT } from "../../../store/actions/book_supervision_out";
export default {
  props:{
    getters: Object,
    component: Object
  },

  components: {
    ViewInfo,
  },

  data: () => ({
    page: 1,
    dialog_fiscal: Boolean(),
    header: [
      { text: "Data de Entrada da Nota", value: "data_of_issue" },
      { text: "Número NF", value: "number" },
      { text: "Série", value: "serie" },
      { text: "Origem", value: "origin" },
      { text: "Ações", value: "actions", align: "center" },
    ],
    objectToView: Object(),
    results: Array(),
    total: Number(),
    current: Number()
  }),

  methods: {
    async GetAllBookSupervisionOut(page=1){
      let value = {
        page: page,
        limit: 10,
        paginate: true
      }
      await this.$store.dispatch(GET_ALL_BOOKSUPERVISION_OUT, value)
    },
    view_info(item) {
      this.dialog_fiscal = !this.dialog_fiscal;
      this.objectToView = { ...item };
    },

    adjust_date(){
      this.results.forEach(e => {
        e.data_of_issue = e.data_of_issue.split("-").reverse().join("/")
      });
    }
  },
  created(){
    this.GetAllBookSupervisionOut()
  },
   watch:{
    getters: function(val) {
      this.results = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
      this.adjust_date()
    },
    current: function(val){
      this.GetAllBookSupervisionOut(val)
    }
  }
};
</script>

