import axios from "axios";

const {
    GETALL_ROLES,
    GETALL_USERS,
    UPDATE_USERS,
    GETALL_PERMISSIONS
} = require("./actions/permission");
const {
    SPLICE_ERRO
} = require('./actions/alert_api')

const moduleAccount_plan = {
    state: () => ({
        roles: Array(),
        users: Array(),
        permissions: Array(),
    }),

    mutations: {
        GETALL_ROLES: (state, roles) => {
            state.roles = roles
        },
        GETALL_USERS: (state, users) => {
            state.users = users
        },
        GETALL_PERMISSIONS: (state, permissions) => {
            state.permissions = permissions
        },
        UPDATE_USERS: () => {

        },
    },

    actions: {

        async GETALL_ROLES({
            commit
        }, payload) {

            await axios.post("roles", {
                ...payload
            }).then((response) => {
               
                commit(GETALL_ROLES, JSON.parse(JSON.stringify(response.data)));
                SPLICE_ERRO
            }).catch(r => {
                console.log(r.response, 'rrr');
            });
        },

        async GETALL_PERMISSIONS({
            commit
        }) {
            await axios.get("permission").then((response) => {
                commit(GETALL_PERMISSIONS, JSON.parse(JSON.stringify(response.data)));
                SPLICE_ERRO
            }).catch(r => {
                console.log(r.response, 'rrr');
            });
        },
        async GETALL_USERS({
            commit
        }, payload) {
            await axios.post("user/getAll", {
                ...payload
            }).then((response) => {
                commit(GETALL_USERS, JSON.parse(JSON.stringify(response.data[0])));
                SPLICE_ERRO
            }).catch(r => {
                console.log(r.response, 'rrr');
            });
        },
        async UPDATE_USERS({
            commit
        }, payload) {

            await axios.post("user/update", {
                ...payload
            }).then((response) => {

                commit(UPDATE_USERS, JSON.parse(JSON.stringify(response.data[0])));
                commit(SPLICE_ERRO, {
                    alert: response.data.messagens[0],
                    type: 'S'
                })
            }).catch(r => {
                console.log(r, 'rrr');
            });
        },
        async CREATE_USERS({
            commit
        }, payload) {
            await axios.post("user/create", {
                ...payload
            }).then((response) => {

                commit(SPLICE_ERRO, {
                    alert: response.data.mensagem[0],
                    type: 'S'
                })
            }).catch(r => {
                commit
                throw new Error(r)
            });
        },

        async DELETE_USER({
            commit
        }, payload) {
            await axios.post("user/delete", {
                ...payload
            }).then((response) => {
                commit(SPLICE_ERRO, {
                    alert: response.data.mensagem[0],
                    type: 'S'
                })
            }).catch(e => {
                console.log(e.response)
            })

        },
        async CREATE_PERMISSION({
            commit
        }, payload) {
            await axios.post("permission", {
                ...payload
            }).then(() => {
                commit(SPLICE_ERRO, {
                    alert: 'Permissão criada com sucesso!',
                    type: 'S'
                })
            }).catch(r => {
                commit
                throw new Error(r)
            });
        },
        async UPDATE_PERMISSION({
            commit
        }, payload) {
            await axios.post("permission/update", {
                ...payload
            }).then(() => {

                commit(SPLICE_ERRO, {
                    alert: 'Permissão atualizada com sucesso!',
                    type: 'S'
                })
            }).catch(r => {
                commit
                throw new Error(r)
            });
        },
        async DELETE_PERMISSION({
            commit
        }, payload) {
            await axios.post("permission/delete", {
                ...payload
            }).then(() => {

                commit(SPLICE_ERRO, {
                    alert: 'Permissão deletado com sucesso!',
                    type: 'S'
                })
            }).catch(r => {
                commit
                throw new Error(r)
            });
        },


    },

    getters: {
        getRoles: (state) => state.roles,
        getPermissions: (state) => state.permissions,
        getUsers: (state) => ([
            ...state.users
        ]),
    },
};

export default moduleAccount_plan;