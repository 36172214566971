<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="results"
      :search="search"
      no-data-text="Nenhum resultado encontrado"
      no-results-text="Nenhum resultado encontrado"
      hide-default-footer
      @click:row="handleClick"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <!-- exemplo de como deve aplicar as permissoes na view -->

            <v-icon
              color="yellow darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="
                () => {
                  visibleItem(item)
                }
              "
            >mdi-eye</v-icon>
          </template>
          <span>Visualizar Este Item</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <!-- exemplo de como deve aplicar as permissoes na view -->

            <v-icon
              color="yellow darken-4"
              class="mr-2"
              v-bind="attrs"
              v-if="component.permission.Editar"
              v-on="on"
              @click="
                () => {
                  open_Dialog(item);
                }
              "
              >mdi-pencil</v-icon
            >
          </template>
          <span>Editar esse Item</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <!-- exemplo de como deve aplicar as permissoes na view -->
            <v-icon
              color="red darken-4"
              class="mr-2"
              v-bind="attrs"
              v-if="component.permission.Excluir"
              v-on="on"
              @click="
                () => {
                  deleteItem(item);
                }
              "
              >mdi-delete</v-icon
            >
          </template>
          <span>Deletar esse Item</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-pagination
    class="mb-3"
      v-model="current"
      :length="total"
      color="success"
      :total-visible="10"
    ></v-pagination>
    <DestryDialog :component="this" :destroy_cashier="destroy_cashier" />
  </div>
</template>

<script>
import DestryDialog from "./../destroyDialog/index";
import UiBusinessRules from "../../../UIBusinessRule/Cashier/Methods/Table";
import {Data} from "../../../UIBusinessRule/Cashier/Data/Table";
export default {
  props: {
    component: Object,
    getters: Object,
    destroy_cashier: Function,
    search: String,
    headers: Array,
    open_Dialog: Function,
  },
  components: { DestryDialog },
  data: () => ({
   ...Data
  }),
  methods: {
   ...UiBusinessRules
  },
  created() {
    this.component.GetAll();
  },
  watch: {
    getters: function (val) {
      this.results = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
      this.adjust_date(this.results);
    },
    current: function (val) {
      if (this.component.filter) {
        this.component.cashier_filter && this.component.cashier_filter(val);
      } else {
        this.component.GetAll && this.component.GetAll(val);
      }
    },
  },
};
</script>
