<template>
  <div>
    <v-data-table
      :headers="Headers"
      :items="items"      
      no-data-text="Nenhum resultado encontrado"
      no-results-text="Nenhum resultado encontrado"
      hide-default-footer
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="yellow darken-4"
              class="mb-5 mr-3"
              @click="component.call_dialog(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-eye
            </v-icon>
          </template>
          <span>Visualizar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="red darken-4"
              class="mb-5 mr-3"
              @click="calcelDialog(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-cancel
            </v-icon>
          </template>
          <span>Cancelar</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="primary"
              class="mb-5"
              @click="activator_reprint(item)"
            >
              mdi-printer
            </v-icon>
          </template>
          <span>Reimprimir</span>
        </v-tooltip>
        <CancelViewNFCE />
        <CancelNote />
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-pagination
      class="mb-6"
      v-model="current"
      :length="total"
      color="success"
      :total-visible="10"
    ></v-pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ITEM_TO_CANCEL, GET_ALL_TAX_CUPOM, REPRINT_NFCE_WITH_KEY } from "../../../store/actions/taxCupom";
import CancelViewNFCE from "../../notenfce/AddDialog/View_cancel/index.vue";
import CancelNote from "../cancel_cupom/index.vue";
import Headers from "./helpers/header";
export default {
  components: {
    CancelViewNFCE,
    CancelNote,
  },
  props: {
    component: Object(),
  },
  data() {
    return {
      itemSelected: Object(),
      showCancel: false,
      Headers: Headers,
      results: Array(),
      total: Number(),
      current: Number(),
      Content: undefined,
      items: Array(),
      nfcePrint: Object(),
    };
  },
  methods: {
    getAllTaxCupon: function (page = 1) {
      var payload = {
        page: page,
        limit: 10,
        paginate: true,
      };
      this.$store.dispatch(GET_ALL_TAX_CUPOM, payload);
    },
    calcelDialog(item) {
      this.$store.dispatch(ITEM_TO_CANCEL, item);
      this.$store.dispatch("setShowDialogRegisterCancelNote", true);
    },
    async activator_reprint(nfce) {
      const cnpj = this.getEnterprises[0].cnpj.replace(/\D/g, "");
      let payload = {
        cnpjEmitter: cnpj,
        keyNfe: nfce.access_key,
        modelNf: "65",
      };
      if (nfce.status === "Autorizada") {
        await this.$store.dispatch(REPRINT_NFCE_WITH_KEY, payload).then((data)=>{
          this.nfcePrint =  data.nfce
          window.open(this.nfcePrint);
        });
  
      } else {
        alert("Essa nota não pode ser impressa");
      }
    },
  },
  created() {
    this.getAllTaxCupon()
  },
  computed: {
    ...mapGetters(["getListTaxCupom"]),
    ...mapGetters(["getEnterprises"]),
  },
  watch: {
    current: function(val){
      this.getAllTaxCupon(val)
    },
    getListTaxCupom: function (val) {
      
      this.items = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
    },
    rePrintNfce: function (val) {
      this.nfcePrint = val.nfce;
    },
  },
};
</script>

<style>
</style>

//:headers="component.headers"
//:items="results"