<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" ref="cardTite" />
      </v-card-text>
      <v-card-text>
        <v-text-field v-model="search" label="Pesquisar" append-icon="mdi-magnify" outlined></v-text-field>
      </v-card-text>
      <Table
        :component="this"
        :getters="getNoteEntry"
        :destroy_object="destroy_object"
        :search="search"
        :headers="headers"
        :change_addDialog="change_addDialog"
      />
      <ModalNoteEntry
        v-if="addDialog"
        :dialog="addDialog"
        :changeDialog="change_addDialog"
        :component="this"
        :title="registerNote"
        :enterpriseId="enterpriseId"
      />
    </v-card>
    <LoadScreen/>
    <AlertApi />
  </Container>
</template>

<script>
// v-if="permission.Visualizar"
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/note_entry/CardTitle/CardTitle";
import Table from "../components/note_entry/Table/";
import LoadScreen from "../components/load/index.vue"
//import { GET_ALL_PROVIDER } from "../store/actions/provider";
import {
  GET_ALL_NOTEENTRY,
  DESTROY_NOTEENTRY,
  UPDATE_NOTEENTRY,
  GET_FILTER
} from "../store/actions/note_entry";

import AlertApi from "../components/alert_api/alert_api";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    infNFe: {},
    search: "",
    code: "",
    getter: "getNoteEntry",
    registerNote: "Registrar",
    addDialogImportXML: false,
    addDialog: false,
    destroyDialog: false,
    idToDestroy: 0,
    objectToUpdate: {},
    clients: Array(),
    enterpriseId: Number,
    enterprise: Object,
    title: String(),
    titles: ["Entrada de Notas", "Pré-Entrada de Notas"],
    permission: Object(),
    checkinfo: Number,

    headers: [
      { text: "Status", value: "status" },
      { text: "Data de Emissão", value: "date_of_issue" },
      { text: "Data de Entrada", value: "entry_date" },
      { text: "Fornecedor", value: "providerToShow" },
      { text: "Numero Nota", value: "number_grade" },
      { text: "Série", value: "series" },
      { text: "Valor dos Produtos", value: "value_total_products" },
      { text: "Valor da Nota", value: "value_total_note" },
      { text: "BC ICMS", value: "base_calc_icms_total" },
      { text: "Valor ICMS", value: "value_icms_total" },
      { text: "Outros Valores", value: "value_untaxed_tax_total" },
      { text: "Valor base de Substituição", value: "base_calc_susbt_total" },
      {
        text: "Valor de ICMS Substituição",
        value: "value_icms_substituicao_total"
      },
      { text: "Ações", value: "actions" }
    ]
  }),
  computed: {
    ...mapGetters([
      "getNoteEntry",
      "getPhysicalProvider",
      "getLegalProvider",
      "getMovimentacaoLinks",
      "getTaxOperationType"
    ])
  },
  methods: {
    async note_entry_filter(page = 1) {
      if (!this.search === true) {
        this.GetAll();
      } else {
        let value = {
          search: this.search,
          page: page,
          limit: 10,
          paginate: true
        };
        await this.$store.dispatch(GET_FILTER, value);
      }
    },
    async destroy_object(item) {
      const aliquot = {
        id: item.id
      };
      try {
        await this.$store.dispatch(DESTROY_NOTEENTRY, aliquot);
      } catch (error) {
        alert(error, "error");
      }
    },
    async change_addDialog() {
      this.addDialog = !this.addDialog;
    },

    async update_object(item) {
      const aliquot = {
        id: item.id
      };
      try {
        await this.$store.dispatch(UPDATE_NOTEENTRY, aliquot);
      } catch (error) {
        alert(error);
      }
    },

    async GetAll(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: true,
        status: "CONFIRMADA"
      };
      await this.$store.dispatch(GET_ALL_NOTEENTRY, value);
    }
  },

  async created() {
    this.GetAll();
  },
  watch: {
    search: function() {
      this.note_entry_filter();
    }
  },
  mounted() {
    // let name = document.URL.split("/").pop();
    // this.permission = this.getMovimentacaoLinks.getPermissions(name);

    this.$watch(
      () => {
        return this.$refs.cardTite.selectValue;
      },
      val => {
        this.enterprise = val;
        this.enterpriseId = val.id;
      }
    );
  },
  components: {
    Container,
    CardTitle,
    Table,
    AlertApi,
    LoadScreen,
    ModalNoteEntry: () => import("../components/note_entry/AddDialog/index")
  }
};
</script>

<style scoped></style>
