
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";





export default  function generatePdf(enterprise, note_value, other_values, barcode) {
  
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    var docDefinition = {
        content: [
            {
                text: "-----------------------------------------------------------------------------------------------------------------------------------------------------------",
                style: "anotherStyle",
            },
            { text: `${enterprise.fantasyName} - Filial 05`, style: "anotherStyle" },
            {
                text: "Av. Américo Buaiz, 200 - Vitória/Es",
                style: "anotherStyle",
            },
            {
                text: `CNPJ: ${enterprise.cnpj} - IE: 0000`,
                style: "anotherStyle",
            },
            {
                text: "-----------------------------------------------------------------------------------------------------------------------------------------------------------",
                style: "anotherStyle",
            },
            {
                text: "---------------------------------------------------------------Ticket de Troca---------------------------------------------------------------",
                style: "anotherStyle",
            },
            {
                text: `Código....: ${note_value.access_key}                                                                                                       `,
                style: "body",
            },
            {
                text: `Cliente...: Cliente                                                                                                       `,
                style: "body",
            },
            {
                text: `Valor....:   ${other_values}                                                                                                      `,
                style: "body",
            },
            {
                text: `Descrição....:   Descrição teste                                                                                                      `,
                style: "body",
            },
            {
                text: `Quantidade de troca....:   14                                                                                                      `,
                style: "body",
            },
            {
                text: "------------------------------------------------------------------------Histórico-------------------------------------------------------------------",
                style: "anotherStyle",
            },
            {
                text: `Data - ${new Date().getDate()} / ${new Date().getMonth() + 1
                    } / ${new Date().getFullYear()}`
            },
            { text: "Observação - Controle de trocas" },
            {
                text: "-----------------------------------------------------------------------------------------------------------------------------------------------------------",
                style: "anotherStyle",
            },
            {
                text: 'Identificador do Ticket',
                style: "titleStyle",
            },
            {
                svg: `${barcode}`,
                style: "anotherStyle",
            },
        ],
        styles: {
            body: {
                alignment: "left",
            },
            anotherStyle: {
                alignment: "center",
            },
            titleStyle:{
                alignment: "center",
                fontSize: 18,
                bold: true
            }
        },
    };
    pdfMake.createPdf(docDefinition).open();
}