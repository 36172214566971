<template>
  <v-card min-height="300px" class="ma-5 pa-5">
    <CardTitle />
    <Table />
    <AddDialog v-if="getShowDialogRegisterExchangeControl" />
    <LayerLoad />
  </v-card>
</template>

<script>
import CardTitle from "../components/exchange_control/CardTitle/index.vue";
import Table from "../components/exchange_control/table/index.vue";
import AddDialog from "../components/exchange_control/AddDialog/index.vue";
import LayerLoad from "../components/load/index.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    CardTitle,
    Table,
    AddDialog,
    LayerLoad,
  },
  computed:{
      ...mapGetters(['getShowDialogRegisterExchangeControl'])    
  }
};
</script>

<style></style>
