

<template>
  <div>
    <v-responsive class="d-none d-sm-flex">
      <v-sheet class="flex-row d-flex">
        <v-card-title class="mr-auto">{{ title }}</v-card-title>
        <v-sheet class="mr-5 mt-5">
          <v-row>
            <v-select
              :items="enterprises"
              item-text="fantasyName"
              class="ma-1"
              outlined
              dense
            ></v-select>
            <v-btn
              @click="callDialog(actionsShowDialog)"
              color="success"
              height="40"
              class="ma-1"
              >Adicionar</v-btn
            >
          </v-row>
        </v-sheet>
      </v-sheet>
      <v-text-field
        color="success mr-2 mt-3"
        outlined
        class="ma-3 mt-10"
        append-icon="mdi-magnify"
      />
    </v-responsive>
    <v-responsive class="d-flex d-sm-none">
      <v-sheet>
        <v-col>
          <v-card-title>{{ title }}</v-card-title>
          <v-select
            :items="enterprises"
            item-text="fantasyName"
            class="ma-1"
            outlined
          ></v-select>
          <div class="d-flex justify-center">
            <v-btn
              @click="callDialog(actionsShowDialog)"
              color="success"
              width="100%"
              height="40"
              >Adicionar</v-btn
            >
          </div>
        </v-col>
      </v-sheet>
      <v-text-field
        color="success mr-2 mt-3"
        outlined
        class="ma-3 mt-10"
        append-icon="mdi-magnify"
      />
    </v-responsive>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "CardTitleFunctions",
  props: {
    title: String(),
    actionsShowDialog: String(),
  },
  data: function () {
    return {
      enterprises: Array(),
    };
  },
  computed: {
    ...mapGetters(["getEnterprises"]),
  },
  methods: {
    callDialog(state) {
      this.$store.dispatch(`${state}`, true);
    },
  },
  watch: {
    getEnterprises: function () {
      this.enterprises.push(this.getEnterprises[0]);
    },
  },
};
</script>

<style>
</style>