import { ADD_ORDER_SEQUENCE_INVOICE } from "./actions/cancel_sequecence_invoice"
// import axios from 'axios'
import apifiscal from "../apifiscal"

const cancelSequecenceInvoice = {
    state: {
        orderSequenceInvoice: Object()
    },
    getters: {
        getOrderSequenceInvoice: function (state) {
            return state.orderSequenceInvoice;
        }
    },
    mutations: {
        ADD_ORDER_SEQUENCE_INVOICE: function (state, payload) {
            state.orderSequenceInvoice = payload;
        }
    },
    actions: {
        ADD_ORDER_SEQUENCE_INVOICE: function (context, payload) {
            try {
                apifiscal.post("/disablementSequence", payload).then((response) => {
                    context.commit(ADD_ORDER_SEQUENCE_INVOICE, response);
                })
            } catch (error) {
                console.log(error);
            }
        }

    },
}
export default cancelSequecenceInvoice