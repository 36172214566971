import axios from "axios";

const {
  ADD_CST,
  UPDATE_CST,
  GET_ALL_CST,
  DESTROY_CST,
  GET_FILTER_CST,
  AUX_TO_UPDATE_CST
} = require("./actions/cst");

const {
  SPLICE_ERRO
} = require('./actions/alert_api')

const moduleCst = {
  state: () => ({
    csts: {},
    auxToUpdate: {}
  }),

  mutations: {

    GET_ALL_CST: (state, csts) => {
      state.csts = csts;
    },
    GET_FILTER_CST: (state, payload) => {
      if (payload !== undefined) {
        state.csts = payload
      }

    },
    ADD_CST: (state, newCST) => {
      if (state.csts.data) {
        state.csts.data.push(newCST[0])
      } else {
        state.csts.push(newCST[0])
      }
    },
    UPDATE_CST(state, updateCST) {
      state.csts.data.Update(updateCST)
    },
    DESTROY_CST: (state, id) => {
      // let auxState = [...state.csts.data];
      // let index = auxState.indexOf(
      //   auxState.find(value => value.id === id),
      // );
      // if (index >= 0) {
      //   auxState.splice(index, 1);
      // }
      // state.csts = [...auxState];
      state.csts = id
    },
    AUX_TO_UPDATE_CST: function (state, payload) {
      state.auxToUpdate = payload
    }
  },

  actions: {
    async GET_ALL_CST({
      commit
    },
      payload
    ) {

      try {
        const response =  await axios.post("cst/index", {
             page: payload.page,
             limit: payload.limit,
             paginate: payload.paginate
           })
          
          commit(GET_ALL_CST, response.data[0]);
          return response.data[0]
      } catch (error) {
        throw new Error(error)
      }
      
    },

    async GET_FILTER_CST({
      commit
    }, payload) {
      await axios.post("cst/autocomplete", {
          search: payload.search,
          limit: payload.limit,
          page: payload.page,
          paginate: payload.paginate
      }).then((response) => {
        commit(GET_FILTER_CST, response.data[0]);
      })
    },


    ADD_CST({
      commit
    }, payload) {
      return new Promise((resolve, reject) => {
        axios.post("cst/store", payload).then((response) => {
          resolve()
          commit(ADD_CST, response.data);
          commit(SPLICE_ERRO, {
            alert: 'Cadastrado com sucesso!',
            type: 'S',
          })
        }).catch(() => {
          reject()
        })

      })
    },

    UPDATE_CST({
      commit
    }, payload) {
      return new Promise((resolve, reject) => {

        axios.post("cst/update", payload).then((response) => {
          resolve()
          commit(UPDATE_CST, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: "Atualizado com sucesso!",
            type: 'S'
          })
        }).catch(() => {
          reject()
        })

      })
    },

    DESTROY_CST({
      commit
    }, payload) {
      axios
        .post("cst/delete", payload)
        .then((response) => {
          commit(DESTROY_CST, payload);
          commit(SPLICE_ERRO, {
            alert: response.data.menssage[0],
            type: 'S'
          })
        })
    },

    AUX_TO_UPDATE_CST: function (context, payload) {
      context.commit(AUX_TO_UPDATE_CST, payload)
    }
  },

  getters: {
    getCst: (state) => ({ ...state.csts }),
    getAuxCst: (state) => ({ ...state.auxToUpdate }),
  },
};

export default moduleCst;