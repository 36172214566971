


const SEARCH_CUPOM = "SEARCH_CUPOM"
const VALUE_CUPOM = "VALUE_CUPOM"
const TOTAL_CREDIT = "TOTAL_CREDIT"
const EXCHANGE_HEADER = "EXCHANGE_HEADER"
const REGISTER_CREDIT = "REGISTER_CREDIT"
const GET_ALL_EXCHANGE = "GET_ALL_EXCHANGE"
const VIEW_EXCHANGE = "VIEW_EXCHANGE"

export {
    GET_ALL_EXCHANGE,
    SEARCH_CUPOM,
    VALUE_CUPOM,
    TOTAL_CREDIT,
    EXCHANGE_HEADER,
    REGISTER_CREDIT,
    VIEW_EXCHANGE
}