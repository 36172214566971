import axios from "axios";

let TributeApplicationInput = require("../Application/Service/Tribute/TributeInputApplication");

import {
  GET_ALL_NOTE_ENTRY_ITENS,
  DESTROY_NOTE_ENTRY_ITENS,
  ADD_NOTE_ENTRY_ITENS,
  REFRESH_NOTE_ENTRY_ITENS,
  UPDATE_ITENS_NOTE,
} from "./actions/note_entry_itens";

const moduleNoteEntryItens = {
  state: () => ({
    noteEntryItens: Array(),
    noteEntryUpdate: Array(),
    noteItenUpdate: Object()
  }),

  mutations: {
    CLEAN_NOTE_ENTRY_ITENS: (state) => {
      state.noteEntryItens = Array()
     },
    ADD_NOTE_ENTRY_ITENS: (state, payload) => {
      state.noteEntryItens.push(payload);

      let tableId = state.noteEntryItens.indexOf(payload);

      const current = state.noteEntryItens[tableId];
      current.tableId = tableId;

      if (state.noteEntryUpdate.length !== 0) {
        state.noteEntryUpdate = [...state.noteEntryItens];
      }
    },

    REFRESH_NOTE_ENTRY_ITENS: (state, payload) => {
      state.noteEntryItens = payload;
      state.noteEntryUpdate = payload;
    },

    GET_ALL_NOTE_ENTRY_ITENS: (state, payload) => {
      payload.forEach((element, index) => {
        element.tableId = index;
      });
      state.noteEntryItens = payload;
      state.noteEntryUpdate = payload;
    },

    UPDATE_ITENS_NOTE: (state, payload) => {

      state.noteItenUpdate = {...payload}

      // let auxState = [...state.noteEntryItens];
      // let index = auxState.indexOf(
      //   auxState.find((value) => value.id === payload.id)
      // );

      // if (index >= 0) {
      //   auxState.splice(index, 1, payload);
      //   if (state.noteEntryUpdate.length !== 0) {
      //     state.noteEntryUpdate = [...auxState];
      //   }
      // }
      // state.noteEntryItens = [...auxState];
    },

    DESTROY_NOTE_ENTRY_ITENS: (state, payload) => {
      if (payload === "all") {
        state.noteEntryItens = [];
        state.noteEntryUpdate = [];
      } else {
        let auxState = [...state.noteEntryItens];

        let index = auxState.indexOf(
          auxState.find((value) => value.tableId === payload)
        );

        if (index >= 0) {
          auxState.splice(index, 1);
          if (state.noteEntryUpdate.length !== 0) {
            state.noteEntryUpdate[index].onDelete = true;
          }
        }
        state.noteEntryItens = [...auxState];
      }
    },
  },

  actions: {
    async ADD_NOTE_ENTRY_ITENS({ commit }, payload) {
      let productCalculeTribute =
        await TributeApplicationInput.calculeTributeIn(payload);

      commit(ADD_NOTE_ENTRY_ITENS, productCalculeTribute);


      return productCalculeTribute
      // axios.post("invoiceentry/addproduct", payload).then((response) => {

      // }).catch(() => {
      //   alert("Verifique as informações do item!")
      // });
    },

    // axios.post("revenues/addProduct", payload).then((response) => {

    //   commit(ADD_REVENUES_ITENS, response.data);
    //   commit(SPLICE_ERRO, {
    //     alert: 'Produto cadastrado com sucesso!',
    //     type: 'S'
    //   })
    // }).catch(r => {
    //     

    // });

    async UPDATE_ITENS_NOTE({ commit }, payload) {

      let productCalculeTribute =
        await TributeApplicationInput.calculeTributeIn(payload);
         
        commit(UPDATE_ITENS_NOTE, productCalculeTribute);


      return productCalculeTribute

      // return new Promise((resolve, reject) => {
      //   axios
      //     .post("invoiceentry/addproduct", payload)
      //     .then((response) => {
      //       resolve();
      //       response.data.id = payload.id;
      //       commit(UPDATE_ITENS_NOTE, response.data);
      //     })
      //     .catch(() => {
      //       reject();
      //       alert("Verifique as Informações!");
      //     });
      // });
    },

    GET_ALL_NOTE_ENTRY_ITENS({ commit }, payload) {
      commit(GET_ALL_NOTE_ENTRY_ITENS, payload);
    },

    DESTROY_NOTE_ENTRY_ITENS({ commit }, payload) {
      commit(DESTROY_NOTE_ENTRY_ITENS, payload);
    },
    REFRESH_NOTE_ENTRY_ITENS({ commit }, payload) {
      commit(REFRESH_NOTE_ENTRY_ITENS, payload);
    },
  },
  getters: {
    getNoteEntryItens: (state) => state.noteEntryItens,
    getNoteEntryUpdate: (state) => state.noteEntryUpdate,
  },
};

export default moduleNoteEntryItens;
