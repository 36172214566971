<template>
  <v-form v-model="valid">
    <v-row class="ml-2">
      <v-col cols="12" sm="12" md="3" lg="2">
        <v-text-field
          v-model="component.date.date_initial"
          label="Data Inicial"
          type="date"
          :rules="[(v) => !!v || 'Campo obrigatório para Buscar']"
          outlined
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="12" md="2" lg="2">
        <v-text-field
          v-model="component.date.date_final"
          label="Data Final"
          :rules="[(v) => !!v || 'Campo obrigatório para Buscar']"
          type="date"
          outlined
        >
        </v-text-field>
      </v-col>

      <v-col
        ><v-btn
          class="mt-2"
          :disabled="!valid"
          color="success"
          rounded
          @click="component.find()"
          ><v-icon>mdi-magnify</v-icon></v-btn
        ></v-col
      >

      <v-col cols="12" sm="12" md="3" lg="2">
        <v-select
          v-model="component.downtitle.id_cashier"
          :items="this.component.cashier"
          item-text="description"
          item-value="id"
          outlined
          label="Caixa"
        ></v-select>
      </v-col>

      <v-col cols="12" sm="12" md="3" lg="2">
        <v-text-field
          v-model="component.downtitle.date_low"
          outlined
          type="date"
          label="Data de Baixa"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="12" md="2" lg="2" class="mr-3">
        <v-text-field
          v-model="component.total"
          prefix="R$"
          readonly
          outlined
          label="Valor da Baixa"
        >
        </v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  props: {
    component: Object(),
  },

  data: () => ({
    valid: Boolean(),
    data: String(),
  }),
};
</script>

<style>
</style>