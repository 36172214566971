<template>
  <v-container>
    <v-form :disabled="isDisable">
      <v-row>
        <v-col cols="6" sm="4" md="4" xl="2" lg="2">
          <v-text-field
            label="Base Calc do ICMs"
            v-model="component.form.base_calc_icms_total"
            readonly
            required
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="4" md="4" xl="2" lg="2">
          <v-text-field
            readonly
            label="Valor do ICMS"
            v-model="component.form.value_icms_total"
            required
            outlined
          ></v-text-field>
        </v-col>

        <v-col cols="6" sm="4" md="4" xl="2" lg="2">
          <v-text-field
            label="Base Calc ICMS ST"
            v-model="component.form.value_base_icms_st_product"
            readonly
            required
            outlined
          ></v-text-field>
        </v-col>

        <v-col cols="6" sm="6" md="6" xl="3" lg="3">
          <v-text-field
            label="Valor do ICMS ST"
            v-model="component.form.value_icms_substituicao_total"
            readonly
            required
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6" xl="3" lg="3">
          <v-text-field
            label="Valor Contábil"
            v-model="component.form.value_account_total"
            readonly
            required
            outlined
          ></v-text-field>
        </v-col>
      </v-row>


      <v-row>
        <v-col cols="6" sm="4" md="4" xl="2" lg="2" >
          <v-text-field
            label="Base de Cálculo IPI"
            v-model="component.form.base_calc_ipi_total"
            readonly
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="4" md="4" xl="2" lg="2" >
          <v-text-field
            label="Valor Total do IPI"
            v-model="component.form.value_ipi_total"
            readonly
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="4" md="4" xl="2" lg="2" >
          <v-text-field
            label="Valor IPI Outros"
            v-model="component.form.value_ipi_outher_total"
            readonly
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="4" md="4" xl="2" lg="2" >
          <v-text-field
            label="Valor Isento IPI"
            v-model="component.form.value_ipi_free_total"
            readonly
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="4" md="4" xl="2" lg="2" >
          <v-text-field
            label="Valor Total dos Produtos"
            v-model="component.form.value_total_products"
            readonly
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="4" md="4" xl="2" lg="2" >
          <v-text-field
            label="Outros valores"
            outlined
            v-model="component.form.value_icms_outher_product"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>


      <v-row>
        <v-col cols="6" sm="4" md="4" xl="4" lg="4" >
          <v-text-field
            label="Desconto"
            v-model="component.form.value_discount"
            v-on:change="component.subtraction()"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="4" md="4" xl="4" lg="4" >
          <v-text-field
            label="Valor ICMS Isento"
            v-model="component.form.value_icms_free_total"
            readonly
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="4" xl="4" lg="4" >
          <v-text-field
            label="Valor Total da Nota"
            v-model="component.form.value_total_note"
            readonly
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  props: {
    component: Object,
    isDisable: Boolean,
  },
};
</script>

<style></style>
