import { ADD_BARCODE, AUX_TO_UPDATE_BARCODE, UPDATE_BARCODE } from "../../../store/actions/barcode";
import { GET_ALL_PRODUCT, GET_FILTER_PRODUCT } from "../../../store/actions/product";
function getComponent() {
    return this.component;
}

function close_dialog() {
    this.component.addDialog = false;
    this.justVisible = false
    this.$store.dispatch(AUX_TO_UPDATE_BARCODE, Object())
}
function openAddProduct() {
    this.selectTableShow = !this.selectTableShow
}
async function GetAllProduct(page) {
    const payload = {
        page: page,
        limit: 10,
        paginate: true,
      }
      await this.$store.dispatch(GET_ALL_PRODUCT, payload ).then((response) => {
        this.listProduct = response.data[0].data
        this.currentPage = response.data[0].current_page
        this.lastPage = response.data[0].last_page
    });
}

async function product_filter(page = 1) {
    if (!this.search === true) {
      this.GetAllProduct(this.currentPage);
    } else {
      const value = {
        search: this.search,
        page: page,
        limit:10,
        paginate: true,
      };
      await this.$store.dispatch(GET_FILTER_PRODUCT, value).then((response) => {
        this.listProduct = response.data
        this.currentPage = response.current_page
        this.lastPage = response.last_page
      });
    }
  }

function rowClickSelectTable(item) {
    this.product.description = item.description_item
    this.product.id = item.id
    this.selectTableShow = !this.selectTableShow
}

function generateEan13() {

    let barCode = this.product.id
    barCode = barCode.toString()
    for(let i = barCode.length; i<12; i++) {
        let digit = Math.floor(Math.random() * 10)
        digit = digit.toString()
        barCode = barCode + digit
    }
    this.bar_code = checkDigitEAN13(barCode)
}

function checkDigitEAN13(barcode) {
    const sum = barcode.split('')
      .map((n, i) => n * (i % 2 ? 3 : 1))
      .reduce((sum, n) => sum + n, 0)
  
    const roundedUp = Math.ceil(sum / 10) * 10;
  
    let checkDigit = roundedUp - sum;
    checkDigit = checkDigit.toString()
    return barcode + checkDigit

  }

async function create_barcode() {
    const barcode = {
        product_id: this.product.id,
        bar_code: this.bar_code,
    };
    await this.$store.dispatch(ADD_BARCODE, barcode).then(() => {
        this.close_dialog();
    });
}
function submit() {
    if(this.id == "") {
        this.create_barcode()
    } else {
        this.update_barcode()
    }
}

async function update_barcode() {
    const barcode = {
        id: this.id,
        product_id: this.product.id,
        bar_code: this.bar_code,
    };
    await this.$store.dispatch(UPDATE_BARCODE, barcode).then(() => {
        this.close_dialog();
    });
}

function autoload(payload) {
    if(payload.justVisible) {
        this.justVisible = payload.justVisible;
        this.product.id = payload.item.product_id;
        this.product.description = payload.item.products.description_item
        this.bar_code = payload.item.bar_code;
    } else {
        this.product.id = payload.product_id;
        this.product.description = payload.products.description_item
        this.bar_code = payload.bar_code;
        this.id = payload.id;
    }
}

export default {
    getComponent,
    close_dialog,
    create_barcode,
    submit,
    update_barcode,
    autoload,
    openAddProduct,
    GetAllProduct,
    rowClickSelectTable,
    generateEan13,
    checkDigitEAN13,
    product_filter
}