// import axios from "axios"
import Axios from "axios";
import { MockData } from "../components/notenfe/Table/helpers/mockData"
import apifiscal from "../apifiscal"
import IssueNfeApplication from "../Application/Service/IssueNfe/IssueNfeApplication"


const {
    ITEM_TO_REPRINT,
    PRINT_NFE,
    GET_ALL_ISSUE_OF_INVOICE,
    GET_SEQUENCE_NFE_EMITER_NFE
} = require("./actions/nfe")


const moduleNfe = {

    state: () => ({
        nfe: Object(),
        itemToReprint: Object(),
        printNFE: Object(),
        sequenceNfe: Object()
    }),
    mutations: {
        GET_ALL_ISSUE_OF_INVOICE: (state, nfe) => {
            state.nfe = nfe;
        },
        GET_SEQUENCE_NFE_EMITER_NFE: (state, payload)=> {
            state.sequenceNfe = payload
        },
        ISSUE_OF_INVOICE_NFE : (state, nfe) => {
            state.nfe = nfe;
        },
        ITEM_TO_REPRINT: function (state, payload) {
            state.itemToReprint = payload
        },
        PRINT_NFE: function (state, payload) {
            state.printNFE = payload
        }
    },
    actions: {
        GET_ALL_ISSUE_OF_INVOICE :function (context, payload) {
            try {
                var x = 1;
                if (x == 1) {
                    context.commit(GET_ALL_ISSUE_OF_INVOICE, MockData)
                } else {
                    Axios.post("/noteissue/index", payload).then((response) => {
                        context.commit(GET_ALL_ISSUE_OF_INVOICE, response)
                    })
                }
            } catch (error) {
                return error
            }
        },
        ITEM_TO_REPRINT: function (context, payload) {
            context.commit(ITEM_TO_REPRINT, payload)
        },
        PRINT_NFE: function (context, payload) {
            try {
                Axios.post("/noteissue/nfereprintdanfe", payload).then((response) => {
                    context.commit(PRINT_NFE, response)
                })
            } catch (error) {
                return error
            }
        },
       async ISSUE_OF_INVOICE_NFE({ commit }, payload) {
           commit
           let token=localStorage.getItem("token_fiscal");
            let nfe = await IssueNfeApplication.execute(payload);
            return new Promise((resolve) => {
                apifiscal.post('/noteissue/nfe', {
                    destinationNFe: nfe.destinationNfe,
                    emitterNFe: nfe.emitterNFe,
                    identifyNFe: nfe.identifyNFe,
                    productsNFe: nfe.productNfe,
                    transportInfo: nfe.transportInfoNfe
              }, {
                headers: {'Authorization': 'Bearer '+ token}
              }).then(({ data }) => {
                resolve({ data })
              })
      
            })
          },
          async GET_SEQUENCE_NFE_EMITER_NFE({commit}, payload){
            commit
            return new Promise((resolve) => {
                Axios.post("noteModel/getSequenceTypeNfe", {
                    enterprise_id: payload.enterprise_id,
                    typeNoteModel: payload.typeNoteModel,
                  })
                  .then(({ data }) => {
                    resolve({ data });
                    commit(    GET_SEQUENCE_NFE_EMITER_NFE
                        ,data )
                  });
              });
          }

    },
    getters: {
        getNfe: (state) => ({
            ...state.nfe
        }),
        getItemToReprint: (state) => ({
            ...state.itemToReprint
        }),
    },

}

export default moduleNfe;





