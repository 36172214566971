<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
      </v-card-text>
      <v-card-text>
        <v-text-field
          class="mx-4"
          label="Pesquisar"
          outlined
          v-model="filter"
          append-icon="mdi-magnify"
          v-on:change="cashierOperationType_filter()"
        >
        </v-text-field>
      </v-card-text>
      <Table
        v-if="permission.Visualizar"
        :component="this"
        :getters="getTaxOperationType"
        :search="search"
        :destroy_object="destroy_object"
        :headers="headers"
        :change_addDialog="change_addDialog"
      />
    </v-card>
    <AlertApi />
    <AddDialog
      v-if="getShowDialogRegisterTaxOperationType"
      :component="this"
      :objectToUpdate="objectToUpdate"
    />
    <loadScreen />
  </Container>
</template>
<script>
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/tax_operation_type/CardTitle";
import Table from "../components/tax_operation_type/Table/index";
import AlertApi from "./../components/alert_api/alert_api";
import loadScreen from "../components/load/";
import AddDialog from "./../components/tax_operation_type/AddDialog";

import {
  GET_ALL_TAX_OPERATION_TYPE,
  DESTROY_TAX_OPERATION_TYPE,
  GET_FILTER_TAX_OPERATION_TYPE,
} from "../store/actions/tax_operation_types";
import { GET_ALL_CFOP } from "../store/actions/cfop";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    search: "",
    code: "",
    getter: "getTaxOperationType",
    addDialog: false,
    filter: "",
    destroyDialog: false,
    idToDestroy: 0,
    objectToUpdate: Object(),
    title: "Tipo de Operação fiscal",
    headers: [
      { text: "Codigo", value: "code" },
      { text: "Descrição", value: "description" },
      { text: "Tipo", value: "type" },
      { text: "Cfop Normal", value: "normal_output.description" },
      { text: "Cfop Substituição", value: "replacement.description" },
      { text: "Ações", value: "actions" },
    ],
    results: Array(),
    total: Number(),
    permission: Object(),
    current: Number(),
  }),
  methods: {
    async destroy_object(item) {
      const subgroupproduct = {
        id: item.id,
      };
      try {
        await this.$store.dispatch(DESTROY_TAX_OPERATION_TYPE, subgroupproduct);
      } catch (error) {
        alert(error);
      }
    },
    changeDialog() {
      this.addDialog = !this.addDialog;
    },
    change_addDialog(item) {
      this.objectToUpdate = { ...item };
      this.addDialog = !this.addDialog;
    },
    // async GetAll(page = 1) {
    //   let value = {
    //     page: page,
    //     limit: 10,
    //     paginate: true,
    //   };
    //   await this.$store.dispatch(GET_ALL_TAX_OPERATION_TYPE, value);
    // },

    async GetAllNoPage() {
      let value = {
        page: null,
        limit: null,
        paginate: false,
      };
      await this.$store.dispatch(GET_ALL_CFOP, value);
    },

    async taxOperationType_filter(page = 1) {
      let value = {
        search: this.filter,
        page: page,
        limit: 10,
        paginate: true,
      };
      await this.$store.dispatch(GET_FILTER_TAX_OPERATION_TYPE, value);
    },
  },

  async created() {
    // this.GetAll();
  },
  computed: {
    ...mapGetters([
      "getTaxOperationType",
      "getCadastrosLinks",
      "getCfop",
      "getShowDialogRegisterTaxOperationType",
    ]),
  },

  watch: {
    filter: function () {
      this.taxOperationType_filter();
    },
  },

  components: {
    Container,
    CardTitle,
    loadScreen,
    Table,
    AlertApi,
    AddDialog,
  },
  mounted() {
    let name = document.URL.split("/").pop();
    this.permission = this.getCadastrosLinks.getPermissions(name);
  },
};
</script>

<style scoped></style>
