const ISSUE_OF_INVOICE_NFE = 'ISSUE_OF_INVOICE_NFE'
const ITEM_TO_REPRINT = "ITEM_TO_REPRINT"
const PRINT_NFE = "PRINT_NFE"
const GET_ALL_ISSUE_OF_INVOICE =  'GET_ALL_ISSUE_OF_INVOICE'
const GET_SEQUENCE_NFE_EMITER_NFE = "GET_SEQUENCE_NFE_EMITER_NFE"



module.exports = {
    ISSUE_OF_INVOICE_NFE,
    ITEM_TO_REPRINT,
    PRINT_NFE,
    GET_ALL_ISSUE_OF_INVOICE,
    GET_SEQUENCE_NFE_EMITER_NFE
}