<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
      </v-card-text>
      <v-card-text>
        <v-text-field
          class="mx-4"
          label="Pesquisar"
          outlined
          v-model="filter"
          v-on:change="subProductGroup_filter()"
          append-icon="mdi-magnify"
        >
        </v-text-field>
      </v-card-text>
      <Table
        v-if="permission.Visualizar"
        :search="search"
        :component="this"
        :getters="getSubProductGroup"
        :change_addDialog="change_addDialog"
        :destroy_object="destroy_object"
      />
      <AddDialog :component="this"  />
    </v-card>
    <AlertAPI />
    <loadScreen />
  </Container>
</template>
<script>
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/sub_group_product/Card_Title/";
import Table from "../components/sub_group_product/Table/";
import AddDialog from "../components/sub_group_product/AddDialog/index";
import loadScreen from "../components/load/";
import AlertAPI from "../components/alert_api/alert_api";

import {
  DESTROY_SUB_PRODUCT_GROUP,
  GET_ALL_SUB_PRODUCT_GROUP,
  GET_FILTER_SUB_PRODUCT_GROUP,
} from "../store/actions/sub_product_group";
import { mapGetters } from "vuex";
// import { GET_ALL_PRODUCT_GROUP } from "../store/actions/productgroup";

export default {
  data: () => ({
    search: "",
    addDialog: false,
    getter: "getSubProductGroup",
    objectToUpdate: Object(),
    enterpriseId: Number(),
    filter: "",
    permission: Object(),
  }),

  components: {
    Container,
    CardTitle,
    Table,
    loadScreen,
    AddDialog,
    AlertAPI,
  },

  methods: {
    async GetAll(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: true,
      };

      await this.$store.dispatch(GET_ALL_SUB_PRODUCT_GROUP, value);
    },

    async change_addDialog(item) {
      this.addDialog = !this.addDialog;
      this.objectToUpdate = { ...item };
    },

    async destroy_object(item) {
      const subproductgroup = {
        id: item.id,
      };
      try {
        await this.$store.dispatch(DESTROY_SUB_PRODUCT_GROUP, subproductgroup);
      } catch (error) {
        console.log(error.message, { ...error });
      }
    },

    // async GetAllNoPage() {
    //   let value = {
    //     page: null,
    //     limit: null,
    //     pagination: false,
    //   };
    //   await this.$store.dispatch(GET_ALL_PRODUCT_GROUP, value);
    // },

    async subProductGroup_filter(page = 1) {
      let value = {
        search: this.filter,
        page: page,
        limit: 10,
        paginate: true,
      };
      await this.$store.dispatch(GET_FILTER_SUB_PRODUCT_GROUP, value);
    },
  },
  watch: {
    getProductGroup: function () {},
  },
  created() {
    this.GetAll();
    // this.GetAllNoPage();
  },
  mounted() {
    let name = document.URL.split("/").pop();
    this.permission = this.getCadastrosLinks.getPermissions(name);
  },

  computed: {
    ...mapGetters([
      "getSubProductGroup",
      "getCadastrosLinks",
      "getProductGroup",
    ]),
  },
};
</script>

<style scoped></style>
