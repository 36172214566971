import axios from "axios"
import apifiscal from "../apifiscal"
import { GET_ALL_TAX_CUPOM, ITEM_TO_CANCEL, CANCEL_TAX_CUPOM, SET_CNPJ, REPRINT_NFCE_WITH_KEY } from "./actions/taxCupom"

const TaxCupom = {
    state: {
        listOfTaxCupom: Array(),
        itemToCancel: Object(),
        taxCupom: Object(),
        cnpj: String(),
        reprintnfce : Object(),
    },
    getters: {
        getListTaxCupom: function (state) {
            return state.listOfTaxCupom
        },
        getItemToCancel: function (state) {
            return state.itemToCancel
        },
        getCancelTaxCupom: function (state) {
            return state.taxCupom
        },
        getcnpj: function (state) {
            return state.cnpj
        },
        rePrintNfce: (state)=> ({
            ...state.reprintnfce ,
          }),
    },
    mutations: {
        GET_ALL_TAX_CUPOM: function (state, payload) {
            state.listOfTaxCupom = payload
        },
        ITEM_TO_CANCEL: function (state, payload) {
            state.itemToCancel = payload
        },
        CANCEL_TAX_CUPOM: function (state, payload) {
            state.taxCupom = payload
        },
        SET_CNPJ: function (state, payload) {
            state.cnpj = payload
        },
        REPRINT_NFCE_WITH_KEY :(state, payload)=> {
            state.reprintnfce = payload
           }, 
    },
    actions: {

        CANCEL_TAX_CUPOM: async function (context, payload) {
        try {
            let token=localStorage.getItem("token_fiscal");
            await apifiscal.post("/noteissuenfce/cancelnfce", payload, {headers: {'Authorization': 'Bearer '+ token,}}).then((response) => {
                context.commit(CANCEL_TAX_CUPOM, response.data);
        
            })
          } catch (error) {
            //console.log(error)
          }
    
        },
        GET_ALL_TAX_CUPOM: function (context, payload) {
                axios.post("sales/index", { page: payload.page, limit: payload.limit, paginate: payload.paginate }).then((response) => {
                    context.commit(GET_ALL_TAX_CUPOM, response.data[0])
                })
        },
        ITEM_TO_CANCEL: function (context, payload) {
            context.commit(ITEM_TO_CANCEL, payload)
        },
        SET_CNPJ: function (context, payload) {
            context.commit(SET_CNPJ, payload)
        },
        REPRINT_NFCE_WITH_KEY({ commit }, payload) {
            let token = localStorage.getItem("token_fiscal");
          
            return new Promise((resolve, reject) => {
              apifiscal.post("/noteissuenfce/nfcereprintdanfe", payload, {
                headers: {
                  'Authorization': 'Bearer ' + token,
                }
              }).then((response) => {
                if (response && response.data) {
                  commit('REPRINT_NFCE_WITH_KEY', response.data);
                  resolve(response.data);
                } else {
                  reject(new Error('Invalid response'));
                }
              }).catch((error) => {
                reject(error);
              });
            });
        },
        // REPRINT_NFCE_WITH_KEY({commit}, payload){
        //     console.log('action');
        //     console.log('payload', payload);
        //     let token = localStorage.getItem("token_fiscal");
        //     //console.log('token', token);
        //     new Promise((resolve) => {
        //         apifiscal.post("/noteissuenfce/nfcereprintdanfe", payload,{
        //         headers : {
        //           'Authorization': 'Bearer '+ token,
           
        //         }
        //       }).then((response)=>{
        //         resolve({response})
        //         commit(REPRINT_NFCE_WITH_KEY, response.data)
        //       })
        
        //     })
        
        // },
    }
}

export default TaxCupom