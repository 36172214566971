<template>
  <v-row justify="center">
    <v-dialog
      v-model="this.component.DialogEdit"
      fullscreen
      transition="dialog-bottom-transition"
      hide-overlay
    >
      <v-card>
        <v-toolbar color="success" class="white--text" flat>
          <v-btn
            icon
            dark
            @click="
              () => {
                close_addDialog();
              }
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title v-if="!justVisible">Editar Empresa</v-toolbar-title>
          <v-toolbar-title v-if="justVisible"
            >Visualizar Empresa</v-toolbar-title
          >
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-container>
          <v-divider></v-divider>
          <v-tabs>
            <v-tab href="#tab1">
              <v-icon class="mr-1">mdi-account-details</v-icon>
              Gerais
            </v-tab>

            <v-tab href="#tab2">
              <v-icon class="mr-1">mdi-home-account</v-icon> Endereço
            </v-tab>

            <v-tab href="#tab3">
              <v-icon class="mr-1">mdi-receipt</v-icon> Fiscal
            </v-tab>

            <v-tab-item value="tab1" class="mt-6">
              <v-card flat>
                <TabGeral :component="this" :justVisible="justVisible" />
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab2" class="mt-6">
              <v-card flat>
                <TabAddress
                  :component="this"
                  ref="address"
                  :justVisible="justVisible"
                />
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab3" class="mt-6">
              <v-card flat>
                <TabFiscal :component="this" :justVisible="justVisible" />
              </v-card>
            </v-tab-item>
          </v-tabs>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!justVisible"
              color="success"
              :disabled="!valid"
              class="mr-4"
              @click="
                () => {
                  submit();
                }
              "
            >
              <v-icon class="mr-2">mdi-content-save</v-icon>
              Salvar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
import TabGeral from "../../Tab/TabGeral/";
import TabAddress from "../../Tab/TabAddress/";
import TabFiscal from "../../Tab/TabFiscal/";
import {
  UPDATE_ENTERPRISE,
  VIEW_ENTERPRISE,
} from "../../../../store/actions/enterprise";
import { GET_ALL_COMPANY_GROUP } from "../../../../store/actions/companygroup";
import { dispatchGetAll } from "../../..//Methods/dispatchGetAll";
import { mapGetters } from "vuex";

export default {
  components: { TabGeral, TabAddress, TabFiscal },

  props: {
    component: Object,
    edicao: Function,
  },

  computed: { ...mapGetters(["getViewEnterprise"]) },

  data: () => ({
    justVisible: Boolean(),
    oldState: Object,
    cities: Array,
    valid: true,
    validAddress: false,
    validContact: false,
    validGeral: false,
    enterprise: {
      id: Number(),
      status: String(),
      name: String(),
      fantasyName: String(),
      cnpj: String(),
      registrationState: String(),
      registrationMunicipal: String(),
      accountancy: String(),
      aliquot_pis: Number(),
      aliquot_cofins: Number(),
      conter: String(),
      crc: String(),
      csc: String(),
      noteissung_enviroment: Number(),
      enterprise_type: String(),
      country_code: Number(),
      cnai: String(),
      crt: Number(),
      token: String(),
      company_group_id: Number(),
      tax_regime: Number(),
      certified_password: String(),
      description_country: String(),
      token_ibpt: String(),
      certified_path: String(),
      cnpj_authorization: String(),
      cpf_authorization: String(),
      street: String(),
      number: String(),
      complement: String(),
      district: String(),
      city: String(),
      state: String(),
      addresses_id: Number(),
      cep: String(),
      city_id: Number(),
    },
    newcity: Object(),
    initials: [
      { text: "Acre", value: "AC" },
      { text: "Alagoas", value: "AL" },
      { text: "Amapá", value: "AP" },
      { text: "Amazonas", value: "AM" },
      { text: "Bahia", value: "BA" },
      { text: "Ceará", value: "CE" },
      { text: "Espírito Santo", value: "ES" },
      { text: "Goiás", value: "GO" },
      { text: "Maranhão", value: "MA" },
      { text: "Mato Grosso", value: "MT" },
      { text: "Mato Grosso do Sul", value: "MS" },
      { text: "Minas Gerais", value: "MG" },
      { text: "Pará", value: "PA" },
      { text: "Paraíba", value: "PB" },
      { text: "Paraná", value: "PR" },
      { text: "Pernambuco", value: "PE" },
      { text: "Piauí", value: "PI" },
      { text: "Rio de Janeiro", value: "RJ" },
      { text: "Rio Grande do Norte", value: "RN" },
      { text: "Rio Grande do Sul", value: "RS" },
      { text: "Rondônia", value: "RO" },
      { text: "Roraima", value: "RR" },
      { text: "Santa Catarina", value: "SC" },
      { text: "São Paulo", value: "SP" },
      { text: "Sergipe", value: "SE" },
      { text: "Tocatins", value: "TO" },
      { text: "Distrito Federal", value: "DF" },
    ],
  }),

  methods: {
    autoload(payload) {
      this.justVisible = payload;
    },
    close_addDialog() {
      this.component.DialogEdit = !this.component.DialogEdit;
      this.$store.dispatch(VIEW_ENTERPRISE, false);
    },

    submit() {
      if (this.oldState.text === undefined) {
        alert("Estado e cidades são obrigatório!");
      } else {
        this.enterprise.state = this.oldState.text;
        this.enterprise.city_id = this.newcity.id;
        this.enterprise.city = this.newcity.nome;
        this.update_enterprises();
      }
    },

    async update_enterprises() {
      const payload = {
        id: this.enterprise.id,
        status: this.enterprise.status,
        name: this.enterprise.name,
        fantasyName: this.enterprise.fantasyName,
        cnpj: this.enterprise.cnpj,
        registrationState: this.enterprise.registrationState,
        registrationMunicipal: this.enterprise.registrationMunicipal,
        accountancy: this.enterprise.accountancy,
        aliquot_pis: this.enterprise.aliquot_pis,
        aliquot_cofins: this.enterprise.aliquot_cofins,
        conter: this.enterprise.conter,
        crc: this.enterprise.crc,
        csc: this.enterprise.csc,
        noteissung_enviroment: this.enterprise.noteissung_enviroment,
        enterprise_type: this.enterprise.enterprise_type,
        country_code: this.enterprise.country_code,
        cnai: this.enterprise.cnai,
        crt: this.enterprise.crt,
        token: this.enterprise.token,
        company_group_id: this.enterprise.company_group_id,
        tax_regime: this.enterprise.tax_regime,
        certified_password: this.enterprise.certified_password,
        description_country: this.enterprise.description_country,
        token_ibpt: this.enterprise.token_ibpt,
        certified_path: this.enterprise.certified_path,
        cnpj_authorization: this.enterprise.cnpj_authorization,
        cpf_authorization: this.enterprise.cpf_authorization,
        address: {
          street: this.enterprise.street,
          number: this.enterprise.number,
          complement: this.enterprise.complement,
          district: this.enterprise.district,
          city: this.enterprise.city,
          state: this.enterprise.state,
          cep: this.enterprise.cep,
          id: this.enterprise.addresses_id,
          city_id: this.enterprise.city_id,
        },
      };
      try {
        await this.$store.dispatch(UPDATE_ENTERPRISE, payload).then(() => {
          var copyArrayEnterprise = this.component.results;
          copyArrayEnterprise[this.component.indexItemUpdate] = {
            ...payload,
            ...copyArrayEnterprise[this.component.indexItemUpdate],
          };
          this.component.results = [...copyArrayEnterprise];
        });
      } catch (error) {
        alert(error);
      } finally {
        this.component.component.GetAll();
        this.close_addDialog();
      }
    },

    getComponent() {
      return this._props.component;
    },

    async GetAllNoPage() {
      let value = {
        page: null,
        limit: null,
        pagination: false,
      };
      await dispatchGetAll(this, GET_ALL_COMPANY_GROUP, value);
    },

    settingAddress(val) {
      this.initials.forEach((element) => {
        if (element.value == val.address.state) {
          this.oldState = element;
        }
      });
    },
  },

  created() {
    this.autoload(this.getViewEnterprise);

    this.settingAddress(this.component.itemToUpdade);

    this.GetAllNoPage();
    this.enterprise.id = this.component.itemToUpdade.id;
    this.enterprise.name = this.component.itemToUpdade.name;
    this.enterprise.fantasyName = this.component.itemToUpdade.fantasyName;
    this.enterprise.status = this.component.itemToUpdade.status;
    this.enterprise.cnpj = this.component.itemToUpdade.cnpj;
    this.enterprise.registrationState =
      this.component.itemToUpdade.registrationState;
    this.enterprise.registrationMunicipal =
      this.component.itemToUpdade.registrationMunicipal;
    this.enterprise.accountancy = this.component.itemToUpdade.accountancy;
    this.enterprise.conter = this.component.itemToUpdade.conter;
    this.enterprise.crc = this.component.itemToUpdade.crc;
    this.enterprise.enterprise_type =
      this.component.itemToUpdade.enterprise_type;
    this.enterprise.company_group_id =
      this.component.itemToUpdade.company_group_id;
    this.enterprise.tax_regime = this.component.itemToUpdade.tax_regime;

    this.enterprise.street = this.component.itemToUpdade.address.street;
    this.enterprise.number = this.component.itemToUpdade.address.number;
    this.enterprise.complement = this.component.itemToUpdade.address.complement;
    this.enterprise.district = this.component.itemToUpdade.address.district;
    this.enterprise.cep = this.component.itemToUpdade.address.cep;
    this.newcity.nome = this.component.itemToUpdade.address.city;
    this.newcity.id = this.component.itemToUpdade.address.city_id;
    this.enterprise.state = this.component.itemToUpdade.address.state;
    this.enterprise.addresses_id = this.component.itemToUpdade.address.id;

    this.enterprise.crc = this.component.itemToUpdade.crc;
    this.enterprise.csc = this.component.itemToUpdade.csc;
    this.enterprise.noteissung_enviroment =
      this.component.itemToUpdade.noteissung_enviroment;
    this.enterprise.country_code = this.component.itemToUpdade.country_code;
    this.enterprise.cnai = this.component.itemToUpdade.cnai;
    this.enterprise.crt = this.component.itemToUpdade.crt;
    this.enterprise.token = this.component.itemToUpdade.token;
    this.enterprise.certified_password =
      this.component.itemToUpdade.certified_password;
    this.enterprise.description_country =
      this.component.itemToUpdade.description_country;
    this.enterprise.token_ibpt = this.component.itemToUpdade.token_ibpt;
    this.enterprise.certified_path = this.component.itemToUpdade.certified_path;
    this.enterprise.cnpj_authorization =
      this.component.itemToUpdade.cnpj_authorization;
    this.enterprise.cpf_authorization =
      this.component.itemToUpdade.cpf_authorization;
  },

  mounted() {
    this.oldState = this.initials.find((e) => e.text == this.enterprise.state);
  },

  watch: {
    oldState(val) {
      axios
        .get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${val.value}/municipios`
        )
        .then((response) => {
          this.cities = response.data;
        });
    },
  },

  beforeDestroy() {
    this.getComponent().objectToUpdate = {};
  },
};
</script>


