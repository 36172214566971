/* eslint-disable no-unused-vars */
import axios from "axios";

const {
    GET_ALL_STOCK_BALANCE
} = require("./actions/stock_balance");
const {
    SPLICE_ERRO
} = require("./actions/alert_api");

const stockBalance = {
    state: () => ({
        stock_balance: Object(),
    }),

    mutations: {
        GET_ALL_STOCK_BALANCE: (state, stock_balances) => {
            state.stock_balance = stock_balances;
        },
    },

    actions: {
        GET_ALL_STOCK_BALANCE({ commit }, payload) {
            axios.post('/stockbalance/index', { page: payload.page, limit: payload.limit, paginate: payload.paginate }).then(r => {
                commit(GET_ALL_STOCK_BALANCE, r.data[0])
            });
        }
    },

    getters: {
        getStockBalance: (state) => ({
            ...state.stock_balance
        })
    },
};

export default stockBalance;