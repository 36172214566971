const GET_ALL_BILLSRECEIVE = "GET_ALL_BILLSRECEIVE"
const UPDATE_BILLSRECEIVE = "UPDATE_BILLSRECEIVE"
const DESTROY_BILLSRECEIVE = "DESTROY_BILLSRECEIVE"
const ADD_BILLSRECEIVE = "ADD_BILLSRECEIVE"
const DOWNLOAD_BILLSRECEIVE = "DOWNLOAD_BILLSRECEIVE"
const FIND_BILLSRECEIVE = "FIND_BILLSRECEIVE"
const AUTO_COMPLETE = "AUTO_COMPLETE"
const VIEW_BILLS_RECEIVE = "VIEW_BILLS_RECEIVE"



module.exports = {
    GET_ALL_BILLSRECEIVE,
    UPDATE_BILLSRECEIVE,
    DESTROY_BILLSRECEIVE,
    ADD_BILLSRECEIVE,
    DOWNLOAD_BILLSRECEIVE,
    FIND_BILLSRECEIVE,
    AUTO_COMPLETE,
    VIEW_BILLS_RECEIVE
}