




const cancelSequenceInvoice = [
    { "text": "id", "value": "id" },
    { "text": "CNPJ", "value": "cnpj" },
    { "text": "Número Incial do Cancelamento", "value": "initialNumberDiscontinued" },
    { "text": "Número Final do Cancelamento", "value": "finalNumberDiscontinued" },
    { "text": "Justificativa", "value": "justification" },
    { "text": "Série da Nota", "value": "noteSerie" },
]


export default cancelSequenceInvoice