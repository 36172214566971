<template>
  <v-col>
    <v-form ref="form" v-model="form">
      <div class="mainframe">
        <div class="title">
          <p>Relatório Comissão de Vendedores</p>
        </div>
        <div class="actionsForm">
          <div>
            <v-select
              class="mr-2"
              :items="listEnterprises"
              v-model="formData.enterprise_id"
              item-text="fantasyName"
              item-value="id"
              outlined
              dense
              return-object
              flat
              label="Empresa"
            />
          </div>
          <v-btn :disabled="!form" @click="getReport" class="success ml-2"
            >Visualizar</v-btn
          >
        </div>
      </div>

      <v-row>
        <v-divider />
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            type="date"
            :rules="rules"
            v-model="formData.initial_date_sale"
            outlined
            label="*Data da venda Inicial"
          />
        </v-col>
        <v-col>
          <v-text-field
            type="date"
            v-model="formData.final_date_sale"
            :rules="rules"
            outlined
            label="*Data da venda Final"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            label="*Tipo de Comissão"
            v-model="formData.commission_type"
            outlined
            :rules="rules"
            :items="comission_type"
            item-text="description"
            item-value="id"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="formData.initial_code_seller"
            outlined
            label="Código do Vendedor Inicial"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="formData.final_code_seller"
            outlined
            label="Código do Vendedor Final"
          />
        </v-col>
      </v-row>
    </v-form>
    <AlertApi />
  </v-col>
</template>

<script>
import data from "./helpers/data";
import methods from "./helpers/methods";
import { watch } from "./helpers/watch";
import { mapGetters } from "vuex";
import AlertApi from "../../alert_api/alert_api.vue";
export default {
  props: {},
  components: {
    AlertApi,
  },
  data: function () {
    return {
      ...data,
    };
  },
  methods: {
    ...methods,
  },
  computed: {
    ...mapGetters([
      "getEnterprises",
      "getDataReportSales",
      "getCommission_types",
      "getDataReportComissionSellers",
    ]),
  },
  watch: {
    ...watch,
  },
  created() {
    this.getAllEnterprises();
    this.getAllComissionType();
  },
};
</script>

<style>
.mainframe {
  display: flex;
}
.title {
  width: 50%;
  display: flex;
  align-items: flex-start;
}
.actionsForm {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
</style>