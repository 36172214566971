const faturamento = [
  {
    text: "Faturamento",
    icon: "mdi-cash-multiple",
    route: "/Revenues",
    class: "white--text",
    permission_col: [],
    active: true,
  },
  {
    text: "Pré-Pedido",
    icon: "mdi-cash-multiple",
    route: "/PreOrderRevenues",
    class: "white--text",
    permission_col: [],
    active: true,
  },
  {
    text: "Nota fiscal",
    icon: "mdi-cash",
    route: "/NotaFiscal",
    class: "white--text",
    active: true,
  },

];

export { faturamento };
