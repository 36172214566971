import Axios from "axios"
import { SEARCH_CUPOM, VALUE_CUPOM, TOTAL_CREDIT, EXCHANGE_HEADER, REGISTER_CREDIT, GET_ALL_EXCHANGE, VIEW_EXCHANGE } from "./actions/exchange_control"

export default {
    state: {
        cupomInformation: Object(),
        cuponValue: Number(),
        totalCredit: Number(),
        exchangeHeader: Object(),
        creditRegistered: Object(),
        exchangeItems: Object(),
        exchangeToAdd: Object(),
        viewExchange: Object(),
    },
    getters: {
        getCupomInformation: (store) => store.cupomInformation,
        getCuponValue: (store) => store.cuponValue,
        getTotalCredit: (store) => store.totalCredit,
        getExchangeHeader: (store) => store.exchangeHeader,
        getCreditRegistered: (store) => store.creditRegistered,
        getExchangeItems: (store) => store.exchangeItems,
        getExchangeToAdd: (store) => store.exchangeToAdd,
        getViewExchange: (store) => store.viewExchange
    },
    mutations: {
        EXCHANGE_HEADER: function (store, payload) {
            const exchangeHeader = {
                exchangeHeader: payload,
                billsToPayInformation: store.cupomInformation
            }
            store.exchangeHeader = exchangeHeader
        },
        VALUE_CUPOM: function (store, payload) {
            store.cuponValue = payload
        },
        TOTAL_CREDIT: function (store, payload) {
            store.totalCredit = payload
        },
        REGISTER_CREDIT: function (store, payload) {
            store.exchangeToAdd = payload
        },
        GET_ALL_EXCHANGE: function (store, payload) {
            store.exchangeItems = payload
        },
        SEARCH_CUPOM: function (store, payload) {
            store.cupomInformation = { ...payload }
        },
        VIEW_EXCHANGE: function (store, payload) {
            store.viewExchange = payload
        }
    },
    actions: {
        EXCHANGE_HEADER: function (context, payload) {
            context.commit(EXCHANGE_HEADER, payload)
        },
        TOTAL_CREDIT: function (context, payload) {
            context.commit(TOTAL_CREDIT, payload)
        },
        VALUE_CUPOM: function (context, payload) {
            context.commit(VALUE_CUPOM, payload)
        },
        GET_ALL_EXCHANGE: async function (context, payload) {
            try {
                const response = await Axios.post("/exchange/index", payload)

                context.commit(GET_ALL_EXCHANGE, response.data[0])
                
                return response.data[0]
            } catch (error) {
                throw new Error(error)
            }
        },
        REGISTER_CREDIT: async function (context, payload) {
            try {
                const response = await Axios.post("/exchange/store", payload)
                context.commit(REGISTER_CREDIT, response)
                return response
            } catch (error) {
                return new Error(error)
            }

        },
        SEARCH_CUPOM: function (context, payload) {
            try {
                Axios.post("/sales/getbynumbersale", payload).then((response) => {
                    context.commit(SEARCH_CUPOM, response.data[0])
                })
            } catch (error) {
                throw new Error(error)
            }
        },
        VIEW_EXCHANGE: function (context, payload) {
            context.commit(VIEW_EXCHANGE, payload)
        }
    },
}