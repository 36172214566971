<template>
  <div>
    <v-spacer />
    <v-text-field label="Pesquisar" outlined append-icon="mdi-magnify" />
    <v-divider />
    <v-data-table 
      :items="itemsExchange" 
      :headers="Header"
      no-data-text="Sem registro!"
      hide-default-footer
    >
      <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="blue"
            class="mr-2"
            @click="
              () => {
                view_info(item);
              }
            "
            >mdi-information-outline</v-icon
          >
        </template>
        <span>Visualizar Este Item</span>
    </v-data-table>
    <v-divider></v-divider>
    <v-pagination 
      class="mb-1 mt-10"  
      v-model="paginationInformation.current_page" 
      :length="paginationInformation.last_page"
    > </v-pagination>
    <ViewInfo
      v-if="dialog"
      :component="this"
      :objectToView="objectToView"
    />
  </div>
</template>

<script>
import Header from "./headers";

import { GET_ALL_EXCHANGE, VIEW_EXCHANGE } from "../../../store/actions/exchange_control";
import { mapGetters } from "vuex";

export default {


  data: () => ({
    Header,
    dialog: Boolean(),
    itemsExchange: Array(),
    objectToView: Object(),
    paginationInformation: {
      current_page:0,
      last_page:0,
      total_page: 0 
    }
  }),
  computed: {
    ...mapGetters(["getExchangeItems", "getExchangeToAdd", "getShowDialogRegisterExchangeControl"])
  },
  methods: {
    view_info(item) {
      this.$store.dispatch(VIEW_EXCHANGE, {item: item, justVisible: true});
      this.$store.dispatch("setShowDialogRegisterExchangeControl", true);
    },
    getAllExchange(page = 1) {
      const payload = {
        page,
        paginate: true,
        limit: 10,
      };
      this.$store.dispatch(GET_ALL_EXCHANGE, payload).then((response) => {
        this.itemsExchange = response
        this.paginationInformation.current_page = response.current_page
        this.paginationInformation.last_page = response.last_page
        this.paginationInformation.total_page = response.total
      });
    },
  },
  watch:{
    getExchangeToAdd: function(val){
      this.itemsExchange.push(val.data)
    },
    'paginationInformation.current_page': function(value){
      this.getAllExchange(value)
    }
    // getExchangeItems: function(val){
    //   this.itemsExchange = val.data
    //   this.paginationInformation.current_page = val.current_page
    //   this.paginationInformation.last_page = val.last_page
    // },
  },
  created(){
    this.getAllExchange()
  }
};
</script>

<style></style>
