<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="results"
      :search="search"
      no-data-text="Nenhum resultado encontrado"
      no-results-text="Nenhum resultado encontrado"
      hide-default-footer
      @click:row="handleClick"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <!-- <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="yellow darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="
                () => {
                  change_addDialog(item);
                }
              "
              >mdi-pencil</v-icon
            >
          </template>
          <span>Editar esse Item</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="red darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="
                () => {
                  deleteItem(item);
                }
              "
              >mdi-delete</v-icon
            >
          </template>
          <span>Deletar esse Item</span>
        </v-tooltip> -->

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="yellow darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="
                () => {
                  information(item);
                }
              "
              >mdi-eye</v-icon
            >
          </template>
          <span>Informações</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-pagination
      v-model="current"
      :length="total"
      color="success"
      :total-visible="10"
    ></v-pagination>
    <DestryDialog :component="this" :destroy_object="destroy_object" />
  </div>
</template>

<script>
import DestryDialog from "./../destroyDialog/destroyDialog";
import { GET_ALL_STOCK_BALANCE } from "../../../store/actions/stock_balance";
// import { GET_ALL_NCM } from "../../../store/actions/ncm";
// import { GET_ALL_UNIT } from "../../../store/actions/unit";
//import { dispatchGetAll } from "../../Methods/dispatchGetAll";
import { mapGetters } from "vuex";

export default {
  props: {
    component: Object,
    getters: Object,
    destroy_object: Function,
    search: String,
    headers: Array,
    information: Function,
  },

  components: { DestryDialog },

  computed: {
    ...mapGetters(["getStockBalance"]),
    // ...mapGetters(["getUnit"]),
    // ...mapGetters(["getNcm"]),
  },

  data: () => ({
    destroyDialog: false,
    currentItem: {},
    results: Array(),
    total: Number(),
    current: Number(),
    itemSelectedOnClick: Object(),
    unit: [],
    ncm: []
  }),

  methods: {
    // executeAlterUpdateDialog(item) {
    //   const component = this._props.component;
    //   this.setObjectToUpdate(component, item);
    //   this.alterAddDialog(component);
    //   this._props.component.show_object();
    // },
    //alterAddDialog() {},
    // deleteItem(item) {
    //   this.destroyDialog = true;
    //   this.currentItem = item;
    //   //confirm("Deseja Continuar a exclusão?") && this.destroy_object(item);
    // },
    // setIdToDestroy(component, id) {
    //   component.idToDestroy = id;
    // },
    // alterDestroyDialog(component) {
    //   component.destroyDialog = !component.destroyDialog;
    // },
    // setObjectToUpdate(component, object) {
    //   component.objectToUpdate = object;
    // },
    // alterUpdateDialog(component) {
    //   component.updateDialog = !component.updateDialog;
    // },
    handleClick(i) {
      this.itemSelectedOnClick = i;
    },

    async GetAll(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: true,
      };
      await this.$store.dispatch(GET_ALL_STOCK_BALANCE, value);
    },

    // async GetAllNoPage(){
    //   let value = {
    //     page: null,
    //     limit: null,
    //     paginate: false
    //   }
    //   await dispatchGetAll(this, GET_ALL_UNIT, value);
    //   await dispatchGetAll(this, GET_ALL_NCM, value);
    // },

    func_unit(item) {
      item.data.forEach((e) => {
        this.component.unit.find((f) => {
          if (e.products.unit_id == f.id) {
            return (e.products.unit = f);
          }
        });
      });
    },

    func_ncm(item){  
      item.data.forEach((e) => {
        this.component.ncm.find((f) => {
          if (e.products.ncm_id == f.id) {
            return (e.products.ncm = f);
          }
        });
      });
    },
  },

  mounted() {
    //this.GetAllNoPage();
    this.$nextTick(() => {
      this.$nextTick(() => {
        this.GetAll();
      })
    })
  },

  watch: {
    getStockBalance: function (val) {
      this.func_unit(val);
      this.func_ncm(val);
      this.results = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
    },
    current: function (val) {
      this.GetAll && this.GetAll(val);
    },

    // getUnit: function(val){
    //   this.unit = Object.keys(val).map(key => val[key])
    //   console.log(this.unit);
    // },
    // getNcm: function(val){
    //   this.ncm = Object.keys(val).map(key => val[key])
    //   console.log(this.ncm);
    // }
  },
};
</script>
