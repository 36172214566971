<template>
  <div>
    <v-data-table
      :headers="headers"
      :search="search"
      :items="results"
      no-data-text="Não há operações registradas"
      hide-default-footer
      @click:row="handleClick"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="yellow darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="
                () => {
                  visibleItem(item);
                }
              "
              >mdi-eye</v-icon
            >
          </template>
        </v-tooltip>

        <v-icon
          color="yellow darken-4"
          class="mr-2"
          v-if="component.permission.Editar"
          @click="
            () => {
              change_addDialog(item);
            }
          "
          >mdi-pencil</v-icon
        >
        <v-icon
          color="red darken-4"
          class="mr-2"
          v-if="component.permission.Excluir"
          @click="
            () => {
              delete_item(item);
            }
          "
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <div>
      <v-pagination
        class="mb-3"
        v-model="current"
        :length="total"
        :total-visible="10"
        color="success"
      ></v-pagination>
      <DestroyDialog :component="this" :destroy_object="destroy_object" />
    </div>
  </div>
</template>

<script>
import DestroyDialog from "../DestroyDialog/destroyDialog";
import { headers } from "./script";
//import Delete from "./Delete_Item/";
import {
  GET_ALL_CASHIER_OPERATION_TYPE,
  AUX_TO_UPDATE_CASHIER_OPERATION_TYPE,
} from "../../../store/actions/cashier_operation_type";
//import { dispatchGetAll } from "../../../components/Methods/dispatchGetAll";
import { mapGetters } from "vuex";

export default {
  props: {
    search: String(),
    change_addDialog: Function,
    destroy_object: Function,
    component: Object(),
  },

  components: {
    DestroyDialog,
  },

  data: () => ({
    page: 1,
    total: Number(),
    current: Number(),
    headers,
    results: Array(),
    currentItem: {},
    destroyDialog: false,
    //edit_item: {},
    //delete_object: {},
    //dialog_edit: false,
    //dialog_delete: false,
  }),

  computed: { ...mapGetters(["getCashierOperationType"]) },

  methods: {
    visibleItem(item) {
      this.$store.dispatch(AUX_TO_UPDATE_CASHIER_OPERATION_TYPE, {
        item: item,
        justVisible: true,
      });
      this.component.addDialog = true;
    },

    delete_item(item) {
      this.destroyDialog = true;
      this.currentItem = item;
    },
    async GetAll(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: true,
      };
      await this.$store.dispatch(GET_ALL_CASHIER_OPERATION_TYPE, value);
    },
  },

  created() {
    this.GetAll();
  },

  watch: {
    getCashierOperationType: function (val) {
      this.results = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
    },
    current: function (val) {
      if (this.component.filter) {
        this.component.cashierOperationType_filter &&
          this.component.cashierOperationType_filter(val);
      } else {
        this.GetAll && this.GetAll(val);
      }
    },
  },
};
</script>
