import { GET_FILTER_PRODUCT_GROUP } from "../../../store/actions/productgroup";
import {
    GET_ALL_PRODUCT_GROUP,
    DESTROY_PRODUCT_GROUP,
} from "../../../store/actions/productgroup";
import { CHANGE_LOAD } from "../../../store/actions/load";


async function destroy_productGroup(item) {
    const cst = {
        id: item.id,
    };
    try {
        await this.$store.dispatch(DESTROY_PRODUCT_GROUP, cst);
    } catch (error) {
        alert(error);
    }
}
function open_editDialog(item) {
    this.addDialog = !this.addDialog;
    this.objectToUpdate = item;
}
async function GetAll(page = 1) {
    this.$store.commit(CHANGE_LOAD, true);
    let value = {
        page: page,
        limit: 10,
        paginate: true,
    };
    await this.$store.dispatch(GET_ALL_PRODUCT_GROUP, value);
}

async function productgroup_filter(page = 1) {
    page
    if (!this.filter === true) {
        this.GetAll();
    } else {
        let value = {
            search: this.filter,
            page: null,
            limit: 10,
            paginate: true,
        };
        await this.$store.dispatch(GET_FILTER_PRODUCT_GROUP, value);
    }
}




export default {
    destroy_productGroup,
    open_editDialog,
    GetAll,
    productgroup_filter,
}