




var watch = {
    getDataReportSales: function (val) {
        this.viewPDF(val);
        this.$refs.form.reset()
        this.$refs.form.resetValidation()
    },
    getEnterprises(val) {
        this.listEnterprises = Object.values(val);
        this.formData.enterprise_id = this.listEnterprises[0];
      },
    getCommission_types: function (val) {
        this.comission_type = Object.values(val)
    }
}

export {
    watch
}