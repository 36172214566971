const GET_ALL_BILLSPAY = "GET_ALL_BILLSPAY"
const UPDATE_BILLSPAY = "UPDATE_BILLSPAY"
const DESTROY_BILLSPAY = "DESTROY_BILLSPAY"
const ADD_BILLSPAY = "ADD_BILLSPAY"
const DOWNLOAD_BILLSPAY = "DOWNLOAD_BILLSPAY";
const FIND_BILLSPAY = "FIND_BILLSPAY"
const AUTO_COMPLETE = "AUTO_COMPLETE"
// s


module.exports = {
    GET_ALL_BILLSPAY,
    UPDATE_BILLSPAY,
    DESTROY_BILLSPAY,
    ADD_BILLSPAY,
    DOWNLOAD_BILLSPAY,
    FIND_BILLSPAY,
    AUTO_COMPLETE
}