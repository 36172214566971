<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
      </v-card-text>
      <v-card-text>
        <v-text-field
          class="mx-4"
          label="Pesquisar"
          outlined
          v-model="filter"
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-card-text>
      <!-- se a rota nao tiver visualizar ele nem vai rendenizar por conta do middleware -->
      <!-- porem a validaçao na rotina deve ser feita caso por venture a validaçao do middleware passe -->
      <Table
        v-if="permission.Visualizar"
        :component="this"
        :getters="getAliquot"
        :destroy_object="destroy_object"
        :search="search"
        :headers="headers"
        :change_addDialog="change_addDialog"
      />
      <ModalAliquot
        :action="action"
        :component="this"
        @close="addDialogAliquot = $event"
      />
    </v-card>
    <AlertApi />
    <loadScreen />
  </Container>
</template>

<script>
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/aliquot/CardTitle/";
import Table from "../components/aliquot/Table/";
import ModalAliquot from "../components/aliquot/AddDialog/";
import loadScreen from "../components/load/";
import AlertApi from "../components/alert_api/alert_api";
import UiBusinessRules from "../UIBusinessRule/Aliquot/View"
// import {
//   GET_ALL_ALIQUOT,
//   DESTROY_ALIQUOT,
//   GET_FILTER_ALIQUOT,
// } from "../store/actions/aliquot";

import { mapGetters } from "vuex";

export default {
  components: {
    Container,
    CardTitle,
    Table,
    AlertApi,
    ModalAliquot,
    loadScreen,
  },

  computed: { ...mapGetters(["getAliquot", "getCadastrosLinks"]) },

  data: () => ({
    search: String(),
    filter: String(),
    action: String(),
    addDialogAliquot: Boolean(),
    destroyDialog: Boolean(),
    objectToUpdate: Object(),
    title: "Aliquota",
    headers: [
      { text: "Descrição", value: "description" },
      { text: "Aliquota", value: "aliquot" },
      { text: "Ações", value: "actions" },
    ],
    permission: Object(),
  }),

  methods: {
    ...UiBusinessRules
  },

  async created() {
    await this.GetAll();
  },

  async mounted() {
    // usando js para pegar o nome da rota, vai funcionar para todas rotas, menos a rota /
    let name = document.URL.split("/").pop();
    this.permission = this.getCadastrosLinks.getPermissions(name);
    // usar getter especifico para comparar as rotas, getPermission deve receber ultimo parametro da rota
    // exemplo se a rota é http://localhost:8080/Aliquot voce deve passar para ele somente o Aliquot
  },

  watch: {
    addDialogAliquot: function (val) {
      if (val == false) {
        this.GetAll();
      }
    },
    filter: function () {
      this.aliquot_filter();
    },
  },
};
</script>

<style scoped></style>
