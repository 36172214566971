const GET_ALL_REVENUES = "GET_ALL_REVENUES";
const UPDATE_REVENUES = "UPDATE_REVENUES";
const DESTROY_REVENUES = "DESTROY_REVENUES";
const ADD_REVENUES = "ADD_REVENUES";
const CONFIRM_REVENUES = "CONFIRM_REVENUES";
const ADD_PRENOTE = "ADD_PRENOTE";
const REVERSE_REVENUE = "REVERSE_REVENUE";
const GET_FILTER_REVENUE = " GET_FILTER_REVENUE";

module.exports = {
  GET_FILTER_REVENUE,
  GET_ALL_REVENUES,
  UPDATE_REVENUES,
  DESTROY_REVENUES,
  ADD_REVENUES,
  CONFIRM_REVENUES,
  ADD_PRENOTE,
  REVERSE_REVENUE,
};
