<template>
  <v-form ref="form" v-model="validationForm">
    <v-row>
      <v-col>
        <v-autocomplete
          class="ma-2"
          hide-no-data
          outlined
          v-model="component.product.cst_ipi_id"
          label="*CST IPI"
          :rules="component.textRules"
          :readonly="component.justVisible"
          item-text="description"
          item-value="id"
          :items="cstipiItemsSelect"
          append-outer-icon="mdi-plus-circle"
          @click:append-outer="changeIpiDialog"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          class="ma-2"
          type="number"
          outlined
          :readonly="component.justVisible"
          label="Classe de enquadramento"
          v-model="component.product.framing_class"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          class="ma-2"
          type="number"
          outlined
          :readonly="component.justVisible"
          label="Cnpj do Produtor"
          v-model="component.product.cnpj_producer"
        ></v-text-field>
      </v-col>

      <v-col>
        <v-text-field
          class="ma-2"
          type="number"
          outlined
          :readonly="component.justVisible"
          label="Codigo do selo controle Ipi"
          v-model="component.product.code_stamp"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          class="ma-2"
          type="number"
          outlined
          :readonly="component.justVisible"
          label="Quantidade do selo Ipi"
          v-model="component.product.amount_stamp"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-layout>
          <v-select
            class="ma-2"
            :readonly="component.justVisible"
            label="Forma de calculo do Ipi"
            v-model="component.product.ipi_calculation_form"
            full-width
            outlined
            :items="items"
          >
          </v-select>
        </v-layout>
      </v-col>
      <v-col>
        <v-text-field
          class="ma-2"
          type="number"
          outlined
          :readonly="component.justVisible"
          label="IPI"
          v-model="component.product.ipi"
        ></v-text-field>
      </v-col>
    </v-row>
    <DialogRegister
      v-if="getShowDialogRegisterIPI"
      :component="this"
      title="Cadastro de Cst IPI"
      :addDialogPisCofins="showDialog"
      :add_value="add_pis_cofins"
      :update_value="update_pis_cofins"
    />
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
import DialogRegister from "../../../global_components/dialog_register.vue";
import { ADD_CST_IPI, UPDATE_CST_IPI,GET_ALL_CST_IPI } from "../../../../store/actions/cstipi";

export default {
  components: {
    DialogRegister,
  },
  props: {
    component: Object(),
  },
  data: () => ({
     validationForm:false,
    auxIPI: 0,
    add_pis_cofins: ADD_CST_IPI,
    update_pis_cofins: UPDATE_CST_IPI,

    showDialog: false,
    items: ["Porcentagem", "Em valor"],
    cstipiItemsSelect: Array(),
  }),
  computed: {
    ...mapGetters(["getCstIpi", "getShowDialogRegisterIPI"]),
  },
  methods: {
    getAllCstIpi(page = 1){
      const payload = {
        page,
        paginate: true,
        limit: 10
      }
      this.$store.dispatch(GET_ALL_CST_IPI,payload).then((response) => {
        this.cstipiItemsSelect = [...response.data]
      })
    },
    changeIpiDialog() {
      this.$store.dispatch("setShowDialogRegisterIPI", true);
    },
    reset() {
      this.$refs.form.resetValidation();
    },
  },

  watch: {
     validationForm:function(val){
      this.component.validations.push(val)
    },
    // getCstIpi: function (val) {
    //   val;
    //   this.cstipiItemsSelect = [];
    //   this.cstipiItemsSelect = Object.keys(this.getCstIpi).map(
    //     (key) => this.getCstIpi[key]
    //   );
    //   if (this.auxIPI == 0) {
    //     this.auxIPI = 1;
    //   } else {
    //     this.component.product.cst_ipi_id =
    //       this.cstipiItemsSelect[this.cstipiItemsSelect.length - 1];
    //   }
    // },
  },
  created() {
    this.getAllCstIpi()
    // this.cstipiItemsSelect = Object.keys(this.getCstIpi).map(
    //   (key) => this.getCstIpi[key]
    // );
  },
};
</script>
