let Data = [
    {
        code: '123912',
        description: "Caixa de som Jbl",
        amount: 1,
        seller_price: "R$ 500",
        exchange_amout: 1,
        credit_value: "R$ 500",
    },
]

let header = [
    "Código",
    "Descrição",
    "Quantidade",
    "Preço de Venda",
    "Sub Total",
    "Quantidade de troca",
    "Valor de Crédito",
]




export { Data, header }