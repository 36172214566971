


const correctionLetterHeadersTable = [
    { "text": "id", "value": "id" },
    { "text": "chave", "value": "key" },
    { "text": "Fornecedor/Cliente", "value": "provider" },
    { "text": "Valor Produtos", "value": "productValue" },
    { "text": "Valor da nota", "value": "noteValue" },
    { "text": "Valor Base de Calculo", "value": "calcValue" },
]



export default correctionLetterHeadersTable