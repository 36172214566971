<template>
  <div>
    <v-dialog persistent v-model="getShowDialogRegisterCorrectionLetter">
      <v-card width="100%">
        <v-card-title>Pedido de Correção de Nota Fiscal</v-card-title>
        <v-divider />
        <v-form ref="form" v-model="formValidate">
          <v-container>
            <v-row no-gutters>
              <v-col class="pa-1" cols="12" sm="3">
                <v-text-field
                  label="*Data de Emissão"
                  outlined
                  type="date"
                  dense
                  v-model="formData.insueDate"
                  :rules="rules[0]"
                ></v-text-field>
              </v-col>
              <v-col class="pa-1" cols="12" sm="3">
                <v-select
                  label="Empresa"
                  outlined
                  dense
                  :rules="rules[0]"
                  item-text="fantasyName"
                  item-value="id"
                  :items="enterpriseList"
                  v-model="formData.enterprise_id"
                ></v-select>
              </v-col>
              <v-col class="pa-1" cols="12" sm="3">
                <v-text-field
                  label="*Número da Nota"
                  outlined
                  type="Number"
                  v-model="formData.numberNote"
                  :rules="rules[0]"
                  dense
                ></v-text-field>
              </v-col>
              <v-col class="pa-1" cols="12" sm="3">
                <v-text-field
                  label="Série da Nota"
                  outlined
                  dense
                  type="Number"
                  :rules="rules[0]"
                  v-model="formData.seriesNote"
                ></v-text-field>
              </v-col>
              <v-col class="pa-1" cols="12" sm="3"> </v-col>
            </v-row>
            <v-row>
              <v-col class="pr-4 pl-4" cols="12">
                <v-text-field
                  label="*Chave da Nota Fiscal"
                  outlined
                  :rules="rules[0]"
                  v-model="formData.keyFiscalNote"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pr-4 pl-4" cols="12">
                <v-textarea
                  counter
                  outlined
                  label="*Motivo"
                  :rules="rules[1]"
                  v-model="formData.reason"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="error" @click="closeDialog">Cancelar</v-btn>
            <v-btn color="success" :disabled="activateButtom" @click="submit"
              >Adicionar</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";
import { ADD_ORDER_CORRECTION_LETTER } from "../../../store/actions/correction_letter";
import { rulesText, rulesTextArea } from "./rules/rules";
import { mapGetters } from "vuex";

export default {
  props: {
    component: Object(),
  },
  computed: {
    ...mapGetters(["getShowDialogRegisterCorrectionLetter", "getEnterprises"]),
    activateButtom: function () {
      if (this.formValidate) {
        return false;
      } else {
        return true;
      }
    },
  },

  data: function () {
    return {
      rules: [rulesText, rulesTextArea],
      formValidate: Boolean(),
      currenctComponent: Object(),
      enterpriseList: Array(),
      formData: {
        enterprise_id: Number(),
        numberNote: Number(),
        seriesNote: Number(),
        insueDate: Date(),
        keyFiscalNote: String(),
        reason: String(),
      },
    };
  },
  methods: {
    closeDialog() {
      this.$store.dispatch("setShowDialogRegisterCorrectionLetter", false);
    },
    submit() {
      this.$store.dispatch(ADD_ORDER_CORRECTION_LETTER, this.formData);
    },
    async getAllEnterprise() {
      const payload = {
        page: null,
        limit: null,
        paginate: false,
      };
      await this.$store.dispatch(GET_ALL_ENTERPRISE, payload);
    },
  },
  watch: {
    formValidate: function () {
    },
    getEnterprises: function () {
      this.enterpriseList.push(this.getEnterprises[0]);
    },
  },
  created() {
    this.getAllEnterprise();
  },
  beforeDestroy() {
    this.formData = {};
  },
};
</script>

<style>
</style>