import {
  GET_ALL_ALIQUOT,
  DESTROY_ALIQUOT,
  GET_FILTER_ALIQUOT,
} from "../../store/actions/aliquot";


async function destroy_object(item) {
  const aliquot = {
    id: item.id,
  };
  try {
    await this.$store.dispatch(DESTROY_ALIQUOT, aliquot);
  } catch (error) {
    console.log(error.message, { ...error });
  }
}
async function change_addDialog(item) {
  this.action = "Atualizar";
  this.addDialogAliquot = !this.addDialogAliquot;
  this.objectToUpdate = item;
}

async function GetAll(page = 1) {
  let value = {
    page: page,
    limit: 10,
    paginate: true,
  };
  await this.$store.dispatch(GET_ALL_ALIQUOT, value);
}

async function aliquot_filter(page = 1) {
  if (!this.filter === true) {
    this.GetAll();
  } else {
    let value = {
      search: this.filter,
      page: page,
      limit: 10,
      paginate: true,
    };
    await this.$store.dispatch(GET_FILTER_ALIQUOT, value);
  }
}

export default {
  destroy_object,
  change_addDialog,
  GetAll,
  aliquot_filter,
};
