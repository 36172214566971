<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
      </v-card-text>
      <v-card-text>
        <v-text-field
          class="mx-4"
          label="Pesquisar"
          outlined
          v-model="filter"
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-card-text>
      <Table
        v-if="permission.Visualizar"
        :component="this"
        :getters="getCfop"
        :destroy_cfop="destroy_cfop"
        :search="search"
        :headers="headers"
        :open_Dialog="open_Dialog"
      />
      <ModalCFOP :component="this" />
    </v-card>
    <AlertApi />
    <loadScreen />
  </Container>
</template>

<script>
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/cfop/CardTitle/index";
import Table from "../components/cfop/Table/index";
import ModalCFOP from "../components/cfop/AddDialog/index";
import AlertApi from "./../components/alert_api/alert_api";
import loadScreen from "../components/load";
import UiBusinessRules from "../UIBusinessRule/CFOP/View/View";
import { mapGetters } from "vuex";
import { mapState } from "vuex";

// v-if="addDialogCfop"
//         :addDialogCfop="addDialogCfop"
//         :component="this"
//         @close="addDialogCfop = $event"

export default {
  data: () => ({
    search: "",
    code: "",
    getter: "getCfop",
    filter: "",
    addDialogCfop: false,
    objectToUpdate: {},
    title: "CFOP",
    headers: [
      { text: "Código", value: "code" },
      { text: "Descrição", value: "description" },
      { text: "Ações", value: "actions" },
    ],
    permission: Object(),
  }),
  methods: {
    ...UiBusinessRules,
  },
  components: {
    ModalCFOP,
    Container,
    CardTitle,
    loadScreen,
    Table,
    AlertApi,
  },
  async created() {
    this.GetAll();
  },
  computed: {
    ...mapGetters([
      "getCfop",
      "getCadastrosLinks",
      "getShowDialogRegisterCFOP",
    ]),
    ...mapState(["dialogCFOP"]),
  },

  watch: {
    filter: function () {
      this.cfop_filter();
    },
  },

  mounted() {
    // usando js para pegar o nome da rota, vai funcionar para todas rotas menos a rota /
    let name = document.URL.split("/").pop();
    this.permission = this.getCadastrosLinks.getPermissions(name);
    // usar getter especifico para comparar as rotas, getPermission deve receber ultimo parametro da rota
    // exemplo se a rota é http://localhost:8080/Aliquot voce deve passar para ele somente o Aliquot
  },
};
</script>

<style scoped></style>
