<template>
  <v-row>
    <v-card-title>{{ title }}</v-card-title>
    <v-spacer></v-spacer>
    <v-col class="d-flex" cols="12" sm="3">
      <v-select
        class="mt-3"
        label="Empresa"
        :items="enterprises"
        item-text="fantasyName"
        item-value="id"
        v-model="component.enterpriseId"
        outlined
        dense
        flat
        height="10px"
      ></v-select>
    </v-col>
    <v-btn @click="downTitle()" class="mr-2 mt-6" color="success">Baixar</v-btn>
  </v-row>
</template>

<script>
import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";

import { mapGetters } from "vuex";
export default {
  props: {
    component: Object(),
    title: String(),
    downTitle: Function,
  },

  data: () => ({
    enterprises: Array(),
  }),

  methods: {
    async GetAllNoPage() {
      let value = {
        page: null,
        limit: null,
        paginate: false,
      };
      await this.$store.dispatch(GET_ALL_ENTERPRISE, value);
    },
  },
  computed: {
    ...mapGetters(["getEnterprises"]),
  },
  watch: {
    getEnterprises(val) {
      this.enterprises = Object.values(val);
      this.component.enterpriseId = this.enterprises[0].id;
    },
  },
  created() {
    this.GetAllNoPage();
  },
};
</script>

<style>
</style>