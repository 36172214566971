<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
      </v-card-text>
      <v-card-text>
        <v-text-field outlined v-model="search" label="Pesquisar" append-icon="mdi-magnify"></v-text-field>
      </v-card-text>
      <Table
        v-if="permission.Visualizar"
        :component="this"
        :getters="getEnterprises"
        :destroy_object="destroy_object"
        :search="search"
      />
      <AddDialog :component="this" />
    </v-card>
    <AlertAPI />
    <loadScreen />
  </Container>
</template>

<script>
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/enterprise/CardTitle/";
import Table from "../components/enterprise/Table/";
import AlertAPI from "../components/alert_api/alert_api";
import AddDialog from "../components/enterprise/AddDialog/index";
import loadScreen from "../components/load/";

import {
  DESTROY_ENTERPRISE,
  GET_ALL_ENTERPRISE,
  GET_FILTER_ENTERPRISE
} from "../store/actions/enterprise";
import { mapGetters } from "vuex";

export default {
  components: {
    Container,
    CardTitle,
    loadScreen,
    Table,
    AddDialog,
    AlertAPI
  },

  data: () => ({
    search: String(),
    code: "",
    getter: "getEnterprises",
    addDialog: false,
    destroyDialog: false,
    idToDestroy: 0,
    objectToUpdate: {},
    title: "Empresa",
    permission: Object()
  }),

  methods: {
    async GetAll(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: true
      };
      await this.$store.dispatch(GET_ALL_ENTERPRISE, value);
    },
    async enterprise_filter(page = 1) {
      if (!this.filter === true) {
        this.GetAll();
      } else {
        let value = {
          search: this.search,
          page: page,
          limit: 10,
          paginate: true
        };
        await this.$store.dispatch(GET_FILTER_ENTERPRISE, value);
      }
    },
    async change_addDialog(item) {
      this.addDialog = !this.addDialog;
      this.objectToUpdate = { ...item };
    },

    async destroy_object(item) {
      const enterprise = {
        id: item.id
      };
      try {
        await this.$store.dispatch(DESTROY_ENTERPRISE, enterprise);
      } catch (error) {
        return error
      }
    }
  },
  watch: {
    search: function() {
      this.enterprise_filter();
    }
  },
  created() {
    this.GetAll();
  },
  mounted() {
    let name = document.URL.split("/").pop();
    this.permission = this.getCadastrosLinks.getPermissions(name);
  },

  computed: {
    ...mapGetters(["getEnterprises", "getCadastrosLinks"])
  }
};
</script>

<style scoped></style>
