import axios from "axios";

const {
  ADD_PIS_COFINS,
  UPDATE_PIS_COFINS,
  GET_ALL_PIS_COFINS,
  DESTROY_PIS_COFINS,
  GET_FILTER_PIS_COFINS,
  AUX_TO_UPDATE_PIS_COFINS
} = require("./actions/pis_cofins");

const {
  SPLICE_ERRO
} = require('./actions/alert_api')

const modulePisCofins = {
  state: () => ({
    itemToAddPisConfins:Object(),
    pisCofins: {},
    itemToUpdatePisCofins: Object(),
  }),

  mutations: {
    GET_ALL_PIS_COFINS: (state, pis) => {
      state.pisCofins = pis;
    },

    GET_FILTER_PIS_COFINS: (state, payload) => {
      if (payload !== undefined) {
        state.pisCofins = payload
      }

    },

    ADD_PIS_COFINS: (state, newPIS) => {
      state.itemToAddPisConfins = newPIS
      // state.pisCofins = newPIS
      // if (state.pisCofins.data) {
      //   state.pisCofins.data.push(newPIS)
      // } else {
      //   state.pisCofins.push(newPIS);
      // }
    },

    UPDATE_PIS_COFINS(state, updatePIS) {
      state.pisCofins.data.Update(updatePIS)
    },

    DESTROY_PIS_COFINS: (state, id) => {
      let auxState = [...state.pisCofins.data];
      let index = auxState.indexOf(
        auxState.find(value => value.id === id),
      );
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.pisCofins = [...auxState];
    },
    AUX_TO_UPDATE_PIS_COFINS: function (state, payload) {
      state.itemToUpdatePisCofins = payload
    }
  },

  actions: {
    async GET_ALL_PIS_COFINS({
      commit
    },
      payload
    ) {
      try {
       const response = await axios.post("piscofins/index", { page: payload.page, limit: payload.limit, paginate: payload.paginate })
      
      commit(GET_ALL_PIS_COFINS, response.data[0]);

        return response.data[0]

      } catch (error) {
        return new Error(error)
      }

    },

    GET_FILTER_PIS_COFINS({
      commit
    }, payload) {
      axios.post("piscofins/autocomplete", {
          search: payload.search,
          limit: payload.limit,
          page: payload.page,
          paginate: payload.paginate
      }).then((response) => {
        commit(GET_FILTER_PIS_COFINS, response.data[0]);
      })
    },

    ADD_PIS_COFINS({
      commit
    }, payload) {
      return new Promise((resolve, reject) => {

        axios.post("piscofins/store", payload).then((response) => {
          resolve()
          commit(ADD_PIS_COFINS, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: response.data.menssage,
            type: 'S',

          })
        }).catch(r => {
          reject(r.response.data.errors)

        });
      })
    },

    UPDATE_PIS_COFINS({
      commit
    }, payload) {

      return new Promise((resolve, reject) => {

        axios.post("piscofins/update", payload).then((response) => {
          resolve()
          commit(UPDATE_PIS_COFINS, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: response.data.menssage,
            type: 'S'
          })
        }).catch(() => {
          reject()
        })

      })

    },

    DESTROY_PIS_COFINS({
      commit
    }, payload) {
      axios
        .post("piscofins/delete", payload)
        .then((response) => {
          commit(DESTROY_PIS_COFINS, response.id);
          commit(SPLICE_ERRO, {
            alert: "Deletado com sucesso!",
            type: 'S'
          })
        })
    },
    AUX_TO_UPDATE_PIS_COFINS: function (context, payload) {
      context.commit(AUX_TO_UPDATE_PIS_COFINS, payload)
    }
  },

  getters: {
    getItemToAddPisConfins: (state) => state.itemToAddPisConfins,
    getPisCofins: (state) => ({ ...state.pisCofins }),
    getItemToUpdatePisCofins: (state) => ({ ...state.itemToUpdatePisCofins }),

  },
};

export default modulePisCofins;