





import axios from "axios";
import interceptors from "../interceptors-v2";
const {
  ADD_SUB_PRODUCT_GROUP,
  UPDATE_SUB_PRODUCT_GROUP,
  GET_ALL_SUB_PRODUCT_GROUP,
  DESTROY_SUB_PRODUCT_GROUP,
  GET_FILTER_SUB_PRODUCT_GROUP,
  ITEM_TO_UPDATE_SUB_PRODUCT_GROUP
} = require("./actions/sub_product_group");
const {
  SPLICE_ERRO
} = require("./actions/alert_api");

const moduleSubGroup = {
  state: () => ({
    subproductgroups: {},
    itemToUpdateSubGroupProduct: Object(),
    subProductGroupToAdd: Object()
  }),

  mutations: {
    GET_ALL_SUB_PRODUCT_GROUP: (state, subproductgroups) => {
      state.subproductgroups = subproductgroups;
    },
    GET_FILTER_SUB_PRODUCT_GROUP: (state, payload) => {
      state.subproductgroups = payload
    },
    ADD_SUB_PRODUCT_GROUP: (state, newSubproductgroups) => {
      state.subProductGroupToAdd = newSubproductgroups;
    },
    UPDATE_SUB_PRODUCT_GROUP(state, editSubproductgroups) {
      state.subproductgroups.data.Update(editSubproductgroups)
    },

    DESTROY_SUB_PRODUCT_GROUP: (state, id) => {
      let auxState = [...state.subproductgroups.data];
      let index = auxState.indexOf(
        auxState.find(value => value.id === id),
      );
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.subproductgroups = [...auxState];
    },
    ITEM_TO_UPDATE_SUB_PRODUCT_GROUP: function (state, payload) {
      state.itemToUpdateSubGroupProduct = payload;
    },
  },

  actions: {
    async GET_ALL_SUB_PRODUCT_GROUP({ commit }, payload) {

      await axios.post("subproductgroup/index", { page: payload.page, limit: payload.limit, paginate: payload.paginate })
        .then((response) => {
          commit(GET_ALL_SUB_PRODUCT_GROUP, response.data[0]);
        });
    },

    GET_FILTER_SUB_PRODUCT_GROUP({
      commit
    }, payload) {
      axios.get("subproductgroup/autocomplete", {
        params: {
          search: payload.search,
          limit: payload.limit,
          page: payload.page,
          paginate: payload.paginate
        }
      }).then((response) => {
        commit(GET_FILTER_SUB_PRODUCT_GROUP, response.data[0]);
      })
    },

    async ADD_SUB_PRODUCT_GROUP({
      commit
    }, payload) {

      try {
        const response = await axios.post('subproductgroup/store', payload);
  
        commit(ADD_SUB_PRODUCT_GROUP, response.data[0]);
        commit(SPLICE_ERRO, {
          alert: "Cadastrado com sucesso!",
        });
  
        return response.data[0]
        
      } catch (error) {
        interceptors(error.response)
        // reject()
      }


      // return new Promise((resolve, reject) => {

        // axios.post("subproductgroup/store", payload).then((response) => {
        //   resolve()
        //   commit(ADD_SUB_PRODUCT_GROUP, response.data[0]);
        //   commit(SPLICE_ERRO, {
        //     alert: "Cadastrado com sucesso!",
        //   });
        // }).catch((error) => {
        //   interceptors(error.response)
        //   reject()
        // })
      // })
    },

    UPDATE_SUB_PRODUCT_GROUP({
      commit
    }, payload) {
      return new Promise((resolve, reject) => {

        axios.post("subproductgroup/update", payload).then((response) => {
          resolve()
          commit(UPDATE_SUB_PRODUCT_GROUP, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: "Atualizado com sucesso!",
          });
        }).catch(() => {
          reject()
        })

      })
    },

    DESTROY_SUB_PRODUCT_GROUP({
      commit
    }, payload) {
      axios
        .post("subproductgroup/delete", {
          ...payload
        })
        .then((response) => {
          commit(DESTROY_SUB_PRODUCT_GROUP, payload.id);
          commit(SPLICE_ERRO, {
            alert: response.data.menssage[0],
          });

        })
    },
    ITEM_TO_UPDATE_SUB_PRODUCT_GROUP: function (context, payload) {
      context.commit(ITEM_TO_UPDATE_SUB_PRODUCT_GROUP, payload)
    },
  },

  getters: {
    getSubProductGroupToAdd: (state) => ({
       ...state.subProductGroupToAdd
    }),
    getSubProductGroup: (state) => ({
      ...state.subproductgroups
    }),
    getItemToUpdateSubGroupProduct: function (state) {
      return state.itemToUpdateSubGroupProduct;
    },
  },
};

export default moduleSubGroup;