import axios from "axios";

const {
  ADD_SELLER,
  UPDATE_SELLER,
  GET_ALL_SELLER,
  DESTROY_SELLER,
  ITEM_TO_UPDATE_SELLER,
  GET_AUTOCOMPLETE_SELLER
} = require("./actions/seller");

const { SPLICE_ERRO } = require("./actions/alert_api");

const moduleSeller = {
  state: () => ({
    sellers: Object(),
    itemToUpdateSeller: Object(),
    itemToAddSeller: Object(),
    legalAutocomplete: Array(),
    physicalAutocomplete: Array(),
  }),

  mutations: {
    ITEM_TO_UPDATE_SELLER: function(state, payload){
        state.itemToUpdateSeller = payload
    },
    GET_ALL_SELLER: (state, sellers) => {
      state.sellers = sellers;
    },
    ADD_SELLER: (state, newSellers) => {
      state.itemToAddSeller = newSellers;
    },
    UPDATE_SELLER(state, payload) {
      state.sellers = payload
    },

    GET_AUTOCOMPLETE_SELLER: (state, payload) => {
      if (payload.type_people === "PhysicalPeople") {

      state.physicalAutocomplete = payload[0];
    } else{
      state.legalAutocomplete = payload[0];
    }
  },

    DESTROY_SELLER: (state, id) => {
      let auxState = [...state.sellers.data];
      let index = auxState.indexOf(
        auxState.find(value => value.id === id),
      );
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.sellers = [...auxState];
    },

  },

  actions: {
    ITEM_TO_UPDATE_SELLER: function(context, payload){
      context.commit(ITEM_TO_UPDATE_SELLER, payload)
  },
    async GET_ALL_SELLER({ commit }, payload) {
      await axios.post("seller/index", { page: payload.page, limit: payload.limit, paginate: payload.paginate }).then((response) => {
        commit(GET_ALL_SELLER, response.data[0]);
      });
    },

    ADD_SELLER({
      commit
    }, payload) {
      return new Promise((resolve, reject) => {
        axios.post("seller/create", payload).then((response) => {
          resolve()
          commit(ADD_SELLER, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: "Cadastrado com sucesso!",
            type: 'S'
          })
        }).catch(() => {
          reject()
        })

      })
    },

    GET_AUTOCOMPLETE_SELLER({ commit }, payload) {
      axios
        .post("seller/autocomplete", {
          page: payload.page,
          limit: payload.limit,
          paginate: payload.paginate,
          type_people: payload.type_people,
          search: payload.search,
          enterprise_id: payload.enterprise_id,
        })
        .then((response) => {
          commit(GET_AUTOCOMPLETE_SELLER, response.data);
        });
    },

    UPDATE_SELLER({
      commit
    }, payload) {

      return new Promise((resolve, reject) => {

        axios.post("seller/update", payload)
          .then((response) => {
            resolve()
            commit(UPDATE_SELLER, response.data[0]);
            commit(SPLICE_ERRO, {
              alert: "Atualizado com sucesso!",
              type: 'S'
            })
          }).catch(() => {
            reject()
          })

      })
    },

    DESTROY_SELLER({
      commit
    }, payload) {
      axios
        .post("seller/delete", payload)
        .then((response) => {
          console.log(response)
          commit(DESTROY_SELLER, payload.id);
          commit(SPLICE_ERRO, {
            alert: response.data.menssage[0],
            type: 'S'
          })
        })
    },
  },

  getters: {
    getItemToAddSeller: (state) => state.itemToAddSeller,
    getItemToUpdateSeller: (state) => state.itemToUpdateSeller,
    getSellerPhysicalAuto: (state) => state.physicalAutocomplete,
    getSellerLegalAuto: (state) => state.legalAutocomplete,
    getSeller: (state) => ({
      ...state.sellers
    }),
  },
};

export default moduleSeller;