<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <v-row>
          <v-card-title class="ml-2">Usuários</v-card-title>
          <v-spacer></v-spacer>
          <AddDialog
            :component="this"
            v-if="dialog"
            :title="title"
            :dialog="dialog"
            :actionDialog="close"
          />
          <v-btn color="success" class="mr-5 mt-4" @click="activeAdd()"
            >Adicionar</v-btn
          >
        </v-row>
        <v-text-field
          outlined
          class="ma-2"
          label="Pesquisar"
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-card-text>
      <Table
        :items="allUsers"
        :editItem="editItem"
        :changeDestroyDialog="changeDestroyDialog"
        :change_password="change_password"
      />
      <DestroyDialog
        v-if="destroyDialog"
        :component="this"
        :destroy_object="destroy_object"
      />
      <ChangePass v-if="dialogPass" :component="this" />
    </v-card>
    <AlertApi />
  </Container>
</template>

<script>
import Container from "../components/Utils/Container/Container";
import AddDialog from "../components/User/AddDialog/";
import Table from "../components/User/Table/";
import ChangePass from "../components/User/Table/ChangePassword/";
import DestroyDialog from "./../components/User/destroyDialog/destroyDialog";
import AlertApi from "../components/alert_api/alert_api";
import {
  GETALL_USERS,
  UPDATE_USERS,
  CREATE_USERS,
  DELETE_USER,
} from "../store/actions/permission";
import { mapGetters } from "vuex";
export default {
  components: {
    AddDialog,
    Container,
    Table,
    DestroyDialog,
    ChangePass,
    AlertApi,
  },
  data: () => ({
    dialog: Boolean(),
    currentItem: Object(),
    permition: undefined,
    user: {
      status: String(),
      name: String(),
      password: String(),
      email: String(),
      permissions: Array(),
      roles: Array(),
      cashier_operation: Object(),
    },
    title: String(),
    user_confirm_pass: String(),
    user_finish: Object(),
    allUsers: Array(),
    isLoading: Boolean(),
    isAuth: Boolean(),
    destroyDialog: Boolean(),
    changePassword: Boolean(),
    itemToDestroy: Object(),
    dialogPass: Boolean(),
  }),
  methods: {
    changeDestroyDialog(itemToDestroy) {
      this.itemToDestroy = itemToDestroy;
      this.switchDestroyDialog(true);
    },
    switchDestroyDialog(val = undefined) {
      if (val != undefined) this.destroyDialog = val;
      else this.destroyDialog = !this.destroyDialog;
    },
    checkAuth() {
      if (
        this.user.password === this.user_confirm_pass &&
        this.user.password.length > 0
      )
        this.isAuth = true;
      else this.isAuth = false;
    },
    async destroy_object() {
      this.isLoading = true;
      var item = this.itemToDestroy;
      await this.$store.dispatch(DELETE_USER, item);
      await this.getAll();

      this.isLoading = false;
    },
    activeAdd() {
      this.dialog = !this.dialog;
      this.changePassword = true;
    },
    close() {
      this.status = true;
      this.user.name = "";
      this.user.password = "";
      this.user.email = "";
      this.user.enterprises = [];
      this.user.roles = [];
      this.changePassword = true;
      this.user_confirm_pass = "";
      this.title = "Cadastrar";

      if (this.dialog) {
        this.dialog = !this.dialog;
      } else if (this.dialogPass) {
        this.dialogPass = !this.dialogPass;
      }
    },
    applyUser(user) {
      for (const key in user) {
        this.user[key] = user[key];
      }
    },
    changeAddDialog() {
      this.user_confirm_pass = "";
      this.dialog = !this.dialog;
    },
    editItem(item) {
      this.title = "Editar";
      this.user_confirm_pass = this.user.password;
      this.changePassword = false;
      this.applyUser(item);
      this.user.cashier_operation = item.cashier_operation
      this.changeAddDialog();
    },
    change_password(item) {
      this.user_confirm_pass = "";
      this.changePassword = true;
      this.applyUser(item);
      this.dialogPass = !this.dialogPass;
    },
    async save() {
      await this.$store.dispatch(CREATE_USERS, this.user_finish);
    },
    async update() {
      await this.$store.dispatch(UPDATE_USERS, this.user_finish);
    },

    async confirm(cashier) {
      if (cashier.name != null) {
        this.user.cashier_operation = cashier;
        this.user.cashier_operation.limit_descont_percentual = parseFloat(
          cashier.limit_descont_percentual
        );
        this.user.cashier_operation.limit_descont_value = parseFloat(
          cashier.limit_descont_value
        );
      } else {
        this.user.cashier_operation = {};
      }

      // if (this.user.password === this.user_confirm_pass) {
        try {
          this.isLoading = true;
          this.user_finish = { ...this.user };
          (await this.user_finish.id) != undefined
            ? await this.update()
            : await this.save();
        } catch (error) {
          console.log(error);
        } finally {
          this.close();
          await this.getAll();
          this.isLoading = false;
        }
      // } else {
        // alert("As senhas devem ser iguais!");
      // }
    },
    async getAll() {
      await this.$store.dispatch(GETALL_USERS);
      this.allUsers = this.getUsers;
    },
  },
  async mounted() {
    await this.getAll();
  },
  computed: {
    ...mapGetters(["getUsers"]),
  },
};
</script>

<style>
</style>