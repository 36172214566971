<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
      </v-card-text>
      <v-card-text>
        <v-text-field
          v-model="filter"
          label="Pesquisar"
          outlined
          append-icon="mdi-magnify"
        >
        </v-text-field>
      </v-card-text>
      <Table
        v-if="permission.Visualizar"
        :component="this"
        :getters="getProduct"
        :destroy_object="destroy_object"
        :change_addDialog="change_addDialog"
      />
      <AddDialog
        v-if="getShowDialogRegisterProduct"
        :component="this"
        :objectToUpdate="objectToUpdate"
      />
    </v-card>
    <AlertApi />
    <loadScreen />
  </Container>
</template>

<script>
import Container from "../components/Utils/Container/Container";
import Table from "../components/products/Table/index.vue";
import CardTitle from "../components/products/CardTitle/";
import AddDialog from "../components/products/AddDialog/index.vue";
import loadScreen from "../components/load/";
import AlertApi from "../components/alert_api/alert_api";
import { mapGetters } from "vuex";

import {
  DESTROY_PRODUCT,
  GET_ALL_PRODUCT,
  GET_FILTER_PRODUCT,
  ITEM_UPDATE_PRODUCT,
} from "../store/actions/product";

export default {
  components: {
    Container,
    Table,
    loadScreen,
    CardTitle,
    AlertApi,
    AddDialog,
  },

  data: () => ({
    alterDialog: Boolean(),
    filter: String(),
    code: "",
    enterpriseId: Number(),
    getter: "getProduct",
    addDialog: false,
    destroyDialog: false,
    idToDestroy: 0,
    objectToUpdate: Object(),
    title: "Produto",
    permission: Object(),
  }),
  methods: {
    async GetAll(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: true,
      };
      await this.$store.dispatch(GET_ALL_PRODUCT, value);
    },

    async product_filter(page = 1) {
      if (!this.filter === true) {
        this.GetAll();
      } else {
        let value = {
          search: this.filter,
          page: page,
          limit:10,
          paginate: true,
        };
        await this.$store.dispatch(GET_FILTER_PRODUCT, value);
      }
    },

    async destroy_object(item) {
      const product = {
        id: item.id,
      };
      try {
        await this.$store.dispatch(DESTROY_PRODUCT, product);
      } catch (error) {
        console.log(error.message, { ...error });
      }
    },

    async change_addDialog(item) {
      this.addDialog = !this.addDialog;
      this.objectToUpdate = { ...item };
    },
  },

  async created() {
    this.GetAll();
    this.$store.dispatch(ITEM_UPDATE_PRODUCT, {});
  },

  mounted() {
    let name = document.URL.split("/").pop();
    this.permission = this.getCadastrosLinks.getPermissions(name);
  },
  watch: {
    filter: function () {
      this.product_filter();
    },
  },

  computed: {
    ...mapGetters([
      "getProduct",
      "getCadastrosLinks",
      "getShowDialogRegisterProduct",
    ]),
  },
};
</script>
