<template>
  <v-form ref="form" v-model="validationForm">
    <v-row>
      <v-col>
        <v-autocomplete
          class="ma-2"
          hide-no-data
          outlined
          v-model="component.product.cst_pis_id"
          label="*CST/PIS"
          item-text="description"
          item-value="id"
          :readonly="component.justVisible"
          :rules="component.textRules"
          :items="cst_pis_cofins"
          append-outer-icon="mdi-plus-circle"
          @click:append-outer="() => changePisCofinsDialog()"
        >
        </v-autocomplete>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-layout>
          <v-select
            class="ma-2"
            label="Forma de calculo do Pis"
            :readonly="component.justVisible"
            v-model="component.product.pis_calculation_form"
            outlined
            :items="formcalpis"
          >
          </v-select>
        </v-layout>
      </v-col>

      <v-col>
        <v-text-field
          class="ma-2"
          :readonly="component.justVisible"
          type="number"
          outlined
          label="Aliquota Pis (%)"
          v-model="component.product.framing_class"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-layout>
          <v-select
            class="ma-2"
            :readonly="component.justVisible"
            label="Forma de calculo de Subst. Trib"
            v-model="component.product.pis_st_calculation_form"
            outlined
            :items="formpis"
          >
          </v-select>
        </v-layout>
      </v-col>
    </v-row>

    <ModalPisCofins v-if="getShowDialogRegisterPISCOFINS" :component="this" />
  </v-form>
</template>

<script>
import ModalPisCofins from "../../../pis_cofins/AddDialog/index";

import { mapGetters } from "vuex";
import {GET_ALL_PIS_COFINS} from "../../../../store/actions/pis_cofins"
export default {
  props: {
    component: Object(),
  },

  components: {
    ModalPisCofins,
  },

  data: () => ({
     validationForm:false,
    auxPIS: 0,
    page: Number(),
    last_page: Number(),
    item_listNcm: Array(),
    item_list: Array(),
    addDialogPisCofins: false,
    cst_pis_cofins: Array(),
    objectToUpdate: Object(),
    vSelect: String(),
    items: [
      "Nacional",
      "Estrangeira - Imoportação direta",
      "Estrangeira - Adquirida no mercado interno",
    ],
    formpis: [
      "Nacional",
      "Estrangeira - Imoportação direta",
      "Estrangeira - Adquirida no mercado interno",
    ],
    formcalpis: ["Porcentagem", "Em valor"],
  }),

  methods: {
    //Continuação
   async getAllPisCofins(page = 1){
    const payload = {
      page,
      paginate: true,
      limit: 200
    }
      await this.$store.dispatch(GET_ALL_PIS_COFINS,payload).then((response) => {
        this.cst_pis_cofins = [...response.data]
      })
    },
    change_addDialog(item) {
      this.objectToUpdate = item;
    },
    changePisCofinsDialog() {
      this.$store.dispatch("setShowDialogRegisterPISCOFINS", true);
    },
    reset() {
      this.$refs.form.resetValidation();
    },
  },

  computed: {
    ...mapGetters(["getPisCofins", "getShowDialogRegisterPISCOFINS"]),
  },

  watch: {
     validationForm:function(val){
      this.component.validations.push(val)
    },
    // getPisCofins: function (val) {
    //   
    //   val;
    //   this.cst_pis_cofins = [];
    //   this.cst_pis_cofins = Object.keys(this.getPisCofins).map(
    //     (key) => this.getPisCofins[key]
    //   );
    //   if (this.auxPIS == 0) {
    //     this.auxPIS = 1;
    //   } else {
    //     this.component.product.cst_pis_id =
    //       this.cst_pis_cofins[this.cst_pis_cofins.length - 1];
    //   }
    // },
  },

  created() {
    this.getAllPisCofins()
    // this.cst_pis_cofins = Object.keys(this.getPisCofins).map(
    //   (key) => this.getPisCofins[key]
    // );
  },
};
</script>
