<template>
  <v-form ref="form" v-model="validationForm">
    <v-container>
      <v-row>
        <v-col>
          <v-select
            outlined
            :items="list"
            label="*Tipo de Produto"
            item-text="text"
            item-value="value"
            :readonly="component.justVisible"
            v-model="component.product.product_type"
            :rules="component.textRules"
            required
          ></v-select>
        </v-col>
        <v-col>
          <v-text-field
            outlined
            label="Preço de Custo"
            prefix="R$"
            type="number"
            :readonly="component.justVisible"
            v-model="component.product.cost_price"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            outlined
            prefix="R$"
            type="number"
            :rules="component.textRules"
            label="*Preço de Venda"
            :readonly="component.justVisible"
            v-model="component.product.sale_price"
            v-on:change="component.compareCust()"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            outlined
            prefix="R$"
            type="number"
            label="Custo de Nota"
            :readonly="component.justVisible"
            v-model="component.product.note_price"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            outlined
            prefix="R$"
            type="number"
            label="Custo de Contábil"
            :readonly="component.justVisible"
            v-model="component.product.accounting_price"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            outlined
            type="number"
            label="Estoque Mínimo"
            :readonly="component.justVisible"
            v-model="component.product.minimum_stock"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            outlined
            type="number"
            label="Estoque Máximo"
            :readonly="component.justVisible"
            v-model="component.product.max_stock"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            outlined
            type="number"
            label="Giro de Estoque"
            :readonly="component.justVisible"
            v-model="component.product.stock_turning"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-text-field
            outlined
            label="Dia Inicial da Promoção"
            type="Date"
            placeholder="null"
            :readonly="component.justVisible"
            v-model="component.product.initial_promotion_date"
            class="transparent-label"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            outlined
            type="Date"
            placeholder="null"
            label="Dia Final Promoção"
            :readonly="component.justVisible"
            v-model="component.product.final_promotion_date"
            class="transparent-label"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            class="mb-5"
            v-show="this.price_promo"
            type="number"
            prefix="R$"
            outlined
            label="Preço Promocional"
            :readonly="component.justVisible"
            v-model="component.product.promotion_price"
            required
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
export default {
  props: {
    component: Object(),
  },
  data: () => ({
    validationForm: false,
    list: [
      { text: "Revenda", value: "Revenda" },
      { text: "Insumo", value: "Insumo" },
      { text: "Patrimônio", value: "Patrimônio" },
      { text: "Consumo", value: "Consumo" },
    ],
    productType: Array(),
    price_promo: Boolean(),
  }),
  methods: {
    reset() {
      this.$refs.form.resetValidation();
    },
  },
  watch: {
    validationForm: function (value) {
      this.component.validations.push(value);
    },
    "component.product.initial_promotion_date": function (val) {
      if (!val) {
        this.price_promo = false;
      } else {
        this.price_promo = true;
      }
    },
    getProductType: function (val) {
      this.productType = Object.keys(val).map((key) => val[key]);
    },
  },
};
</script>

 <style>
.transparent-label {
  background-color: white;
}
</style>