<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" ref="cardTite" />
      </v-card-text>
      <v-card-text>
        <v-text-field
          v-model="search"
          label="Pesquisar"
          outlined
          append-icon="mdi-magnify"
        >
        </v-text-field>
      </v-card-text>
      <Table
        :component="this"
        :getters="getRevenues"
        :search="search"
        :headers="headers"
        :isLoad="loading"
      />
      <AddDialog
        v-if="addDialog"
        :dialog="addDialog"
        :changeDialog="alterDialog"
        title="Registrar"
        :enterpriseId="enterpriseId"
        :enterpriseType="enterpriseType"
        :component="this"
      />
    </v-card>
    <AlertApi />
    <LoadScreen/>
  </Container>
</template>

<script>
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/pre_order_revenues/CardTitle/";
import Table from "../components/pre_order_revenues/Table/";
import { GET_ALL_REVENUES } from "../store/actions/revenues";
import AlertApi from "../components/alert_api/alert_api";
import { mapGetters } from "vuex";
import LoadScreen from "../components/load/index.vue"
export default {
  data: () => ({
    search: "",
    code: "",
    getter: "getRevenues",
    addDialog: Boolean(),
    destroyDialog: false,
    isNote: Boolean(),
    loading: Boolean(),
    idToDestroy: 0,
    objectToUpdate: {},
    enterpriseType: Number(),
    enterpriseId: Number(),
    title: "Pré-Pedido",
    headers: [
      { text: "Status", value: "status" },
      { text: "Data de Faturamento", value: "date_of_issue" },
      { text: "Cliente", value: "cliente.people.name" },
      { text: "Número Nota", value: "number_grade" },
      { text: "Série", value: "series" },
      { text: "Valor dos Produtos", value: "value_total_products" },
      { text: "Valor da Nota", value: "value_total_note" },
      { text: "Base ICMS", value: "base_calc_icms_total" },
      { text: "Valor ICMS", value: "value_icms_total" },
      { text: "Base IPI", value: "value_total_note" },
      { text: "Valor IPI", value: "value_total_note" },
      { text: "Valor Isento", value: "value_total_note" },
      { text: "Valor Outros", value: "value_total_note" },
      { text: "Ações", value: "actions" },
    ],
  }),
  computed: { ...mapGetters(["getRevenues"]) },

  methods: {
    async GetAll(page = 1) {
      await this.$store.dispatch(GET_ALL_REVENUES, {
        status: "Pre-pedido",
        page: page,
        limit: 10,
        paginate: true,
      });
    },

    async alterDialog() {
      this.addDialog = !this.addDialog;
    },
  },
  watch: {
    isNote: function () {
      this.GetAll();
    },
  },

  async created() {
    this.loading = true;
    await this.GetAll();
    this.loading = false;
  },

  mounted() {
    this.$watch(
      () => {
        return this.$refs.cardTite.selectValue;
      },
      (val) => {
        this.enterpriseType = val.tax_regime_id;

        this.enterpriseId = val.id;
      }
    );
  },

  components: {
    Container,
    CardTitle,
    Table,
    AlertApi,
    LoadScreen,
    AddDialog: () => import("../components/pre_order_revenues/AddDialog/"),
  },
};
</script>

<style scoped></style>
