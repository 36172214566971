<template>
  <div class="mainFrame">
    <v-card width="100%" class="pa-5 ma-5">
      <ReportSales />
    </v-card>
  </div>
</template>

<script>
import ReportSales from "../../../components/report/comission_sellers/index.vue";
export default {
  components: {
    ReportSales
  }
};
</script>

<style>
.mainFrame {
  margin-bottom: 30px;
}
</style>