<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
      </v-card-text>
      <v-card-text>
        <v-text-field
          class="mx-4"
          label="Pesquisar"
          outlined
          v-model="filter"
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-card-text>
      <Table
        v-if="permission.Visualizar"
        :component="this"
        :getters="getCsosn"
        :destroy_csosn="destroy_csosn"
        :search="search"
        :headers="headers"
        :open_Dialog="open_Dialog"
      />
      <ModalCsosn :component="this" @close="addDialog = $event" />
    </v-card>
    <AlertApi />
    <loadScreen />
  </Container>
</template>

<script>
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/csosn/CardTitle/index";
import Table from "../components/csosn/Table/index";
import ModalCsosn from "../components/csosn/AddDialog/index";
import AlertApi from "../components/alert_api/alert_api";
import loadScreen from "../components/load/";
import UIBusinessRule from "../UIBusinessRule/Csons/View/View";
import { mapGetters } from "vuex";


export default {
  data: () => ({
    search: "",
    code: "",
    getter: "getCsosn",
    filter: "",
    addDialogCsosn: false,
    destroyDialog: false,
    idToDestroy: 0,
    objectToUpdate: Object(),
    permission: Object(),
    title: "Csosn",
    headers: [
      { text: "Codigo", value: "code" },
      { text: "Descrição", value: "description" },
      { text: "Ações", value: "actions" },
    ],
  }),

  methods: {
...UIBusinessRule
  },

  async created() {
    this.GetAll();
  },

  watch: {
    filter: function () {
      this.csosn_filter();
    },
  },

  mounted() {
    // usando js para pegar o nome da rota, vai funcionar para todas rotas menos a rota /
    let name = document.URL.split("/").pop();
    this.permission = this.getCadastrosLinks.getPermissions(name);
    // usar getter especifico para comparar as rotas, getPermission deve receber ultimo parametro da rota
    // exemplo se a rota é http://localhost:8080/Aliquot voce deve passar para ele somente o Aliquot
  },

  components: {
    Container,
    CardTitle,
    Table,
    ModalCsosn,
    loadScreen,
    AlertApi,
  },

  computed: {
    ...mapGetters(["getCsosn", "getCadastrosLinks"]),
  },
};
</script>

<style scoped></style>
