const REGIME_ENTERPRISE_LUCRO_REAL = 1;
const REGIME_ENTERPRISE_LUCRO_PRESUMIDO = 2;
const REGIME_ENTERPRISE_SIMPLES_NACIONAL = 3;
const REGIME_ENTERPRISE_MEI = 4;

async function calculeTributeIcms(
  typeRegimeEnterprise,
  productRevenues,
  cfop_entry
) {
  try {
    let productTributeIcms = await checkTypeOperationIcms(
      cfop_entry,
      typeRegimeEnterprise,
      productRevenues
    );
    return productTributeIcms;
  } catch (error) {
    console.log(error);
  }
}

function checkTypeOperationIcms(
  cfop_entry,
  typeRegimeEnterprise,
  productRevenues
) {
  switch (typeRegimeEnterprise) {
    case REGIME_ENTERPRISE_LUCRO_REAL:
      return rangeCfopCalcuteIcmsRegimeNormal(cfop_entry, productRevenues);

    case REGIME_ENTERPRISE_LUCRO_PRESUMIDO:
      return rangeCfopCalcuteIcmsRegimeNormal(cfop_entry, productRevenues);

    case REGIME_ENTERPRISE_SIMPLES_NACIONAL:
      return rangeCfopRegimeSimplesNacional();

    case REGIME_ENTERPRISE_MEI:
      return calculeIcmsNormalOperationOutPutInternal(productRevenues);

    default:
      throw "Regime de empresa não encontado!";
  }
}

async function rangeCfopCalcuteIcmsRegimeNormal(cfop_entry, productRevenues) {
  try {
    if (cfop_entry >= 5100 && cfop_entry <= 5359) {
      return await calculeIcmsNormalOperationOutPutInternal(productRevenues);
    } else if (cfop_entry >= 5401 && cfop_entry <= 5415)
      return await calculeIcmsSubstituicaotributeOutPutOperationOutPutInternal(
        productRevenues
      );
    else if (cfop_entry >= 6100 && cfop_entry <= 6359)
      return await calculeIcmsNormalOperationOutPutState(productRevenues);
    else if (cfop_entry >= 6400 && cfop_entry <= 6415)
      return await calculeIcmsSubstituicaoTributariaOutPutSate(productRevenues);
    throw "Cfop informado não é referente a saída!";
  } catch (error) {
    return error 
  }
}

function rangeCfopRegimeSimplesNacional() {}

async function calculeIcmsNormalOperationOutPutInternal(productRevenues) {
   
  try {
    productRevenues.value_products =
      productRevenues.amount_add * productRevenues.value_sale;
    productRevenues.value_base_icms_product =
      productRevenues.amount_add * productRevenues.value_sale;
    productRevenues.value_accounting_product = productRevenues.value_products;
    productRevenues.aliquot_icms_product = productRevenues.aliquot;
    productRevenues.value_icms_product =
      productRevenues.value_base_icms_product *
      (productRevenues.aliquot_icms_product / 100);
    productRevenues.value_free_icms_product = 0;
    productRevenues.value_icms_outher_product = 0;
    productRevenues.value_base_icms_st_product = 0;
    productRevenues.value_icms_st_product = 0;

    return productRevenues;
  } catch (error) {
    return error
  }
}
async function calculeIcmsSubstituicaotributeOutPutOperationOutPutInternal(
  productRevenues
) {
  try {
    productRevenues.value_products =
      productRevenues.amount_add * productRevenues.value_sale;
    productRevenues.value_base_icms_product = 0;
    productRevenues.aliquot_icms_product = 0;
    productRevenues.value_accounting_product = productRevenues.value_products;
    productRevenues.value_icms_product = 0;
    productRevenues.value_free_icms_product = 0;
    productRevenues.value_icms_outher_product =
      productRevenues.amount_add * productRevenues.value_sale;
    productRevenues.value_base_icms_st_product = 0;
    productRevenues.value_icms_st_product = 0;
    return productRevenues;
  } catch (error) {
    return error
  }
}

async function calculeIcmsNormalOperationOutPutState(productRevenues) {
  try {
    productRevenues.value_products =
      productRevenues.amount_add * productRevenues.value_sale;
    productRevenues.value_base_icms_product = 0;
    productRevenues.aliquot_icms_product = 0;
    productRevenues.value_accounting_product = productRevenues.value_products;
    productRevenues.value_icms_product = 0;
    productRevenues.value_free_icms_product = 0;
    productRevenues.value_icms_outher_product =
      productRevenues.amount_add * productRevenues.value_sale;
    productRevenues.value_base_icms_st_product = 0;
    productRevenues.value_icms_st_product = 0;

    return productRevenues;
  } catch (error) {
    return error
  }
}

async function calculeIcmsSubstituicaoTributariaOutPutSate(productRevenues) {
  try {
    productRevenues.value_products = productRevenues.amount_add * productRevenues.value_sale;
    productRevenues.value_base_icms_product = 0;
    productRevenues.aliquot_icms_product = 0;
    productRevenues.value_accounting_product = productRevenues.value_products;
    productRevenues.value_icms_product = 0;
    productRevenues.value_free_icms_product = 0;
    productRevenues.value_icms_outher_product =
      productRevenues.amount_add * productRevenues.value_sale;
    productRevenues.value_base_icms_st_product = 0;
    productRevenues.value_icms_st_product = 0;

    return productRevenues;
  } catch (error) {
    return error
  }
}

module.exports = {
  calculeTributeIcms,
};
