let destinationNFe = {
  cnpjorcpfDestination: "",
  idDestination: "",
  nameDestination: "",
  publicPlaceDestination: "",
  numberDestination: "",
  complementDestination: "",
  districtDestination: "",
  codeCountyDestination: "",
  nameCountyDestination: "",
  ufDestination: "",
  zipCodeDestination: "",
  codeCountryDestination: "",
  nameCountryDestination: "",
  numberPhoneDestination: "",
  recipientIEIndicatorDestination: "",
  stateRegistrationDestinationNumber: "",
  stateSuframaRegistration: "",
  stateMunicipalRegistrationDestination: "",
  emailDestination: "",
};

async function execute(revenues) {
  try {
    (destinationNFe.cnpjorcpfDestination = await removeMaskCpfOrCnpj(revenues)),
      (destinationNFe.nameDestination = await checkNameClient(revenues)),
      (destinationNFe.publicPlaceDestination = revenues.client.people.address[0].street),
      (destinationNFe.numberDestination = revenues.client.people.address[0].number),
      (destinationNFe.complementDestination = revenues.client.people.address[0].complement),
      (destinationNFe.districtDestination = revenues.client.people.address[0].district),
      (destinationNFe.codeCountyDestination = String(revenues.client.people.address[0].city.id)),
      (destinationNFe.nameCountyDestination = revenues.client.people.address[0].city.state.abbr),
      (destinationNFe.ufDestination =revenues.client.people.address[0].city.state.abbr),
      (destinationNFe.zipCodeDestination = revenues.client.people.address[0].cep),
      (destinationNFe.codeCountryDestination = "1058"),
      (destinationNFe.nameCountryDestination = "BRASIL"),
      (destinationNFe.numberPhoneDestination = revenues.client.people.contact[0].phone),
      (destinationNFe.recipientIEIndicatorDestination = revenues.client.people.legalpeople === null ? "9" : "1"),
      (destinationNFe.stateRegistrationDestinationNumber = revenues.client.people.legalpeople === null ? "ISENTO" : revenues.client.people.legalpeople.stateRegistration),
      (destinationNFe.stateSuframaRegistration = ""),
      (destinationNFe.stateMunicipalRegistrationDestination = ""),
      (destinationNFe.emailDestination = revenues.client.people.contact[0].primaryEmail);

    return destinationNFe;
  } catch (error) {
    return error;
  }
}

async function removeMaskCpfOrCnpj(revenues) {
  if (revenues.client.people.legalpeople === null) {
    let cpf = revenues.client.people.physicalpeople.cpf;
    cpf = cpf.replaceAll(".", "").replaceAll("-", "");
    return cpf;
  } else {
    let cnpj = revenues.client.people.legalpeople.cnpj;
    cnpj = cnpj.replaceAll("-", "").replaceAll(".", "").replaceAll("/", "");
    return cnpj;
  }
}

async function checkNameClient(revenues) {
  if (revenues.client.people.legalpeople === null) {
    return revenues.client.people.physicalpeople.name;
  } else {
    return revenues.client.people.legalpeople.socialName;
  }
}

module.exports = {
  execute,
};
