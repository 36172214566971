<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
      </v-card-text>
      <v-card-text>
        <v-text-field
          class="mx-4"
          label="Pesquisar"
          outlined
          v-model="filter"
          append-icon="mdi-magnify"
        >
        </v-text-field>
      </v-card-text>
      <Table
        v-if="permission.Visualizar"
        :component="this"
        :getters="getAccount_Plan"
        :destroy_object="destroy_object"
        :search="search"
        :headers="headers"
        :change_addDialog="change_addDialog"
      />
      <ModalAccountplans :component="this" />
    </v-card>
    <AlertApi />
    <loadScreen />
  </Container>
</template>

<script>
import ModalAccountplans from "../components/account_plans/AddDialog/index";
import Container from "../components/Utils/Container/Container";
import Table from "../components/account_plans/Table/Index";
import CardTitle from "../components/account_plans/CardTitle/index";
import loadScreen from "../components/load/";

import {
  GET_ALL_ACCOUNT_PLAN,
  DESTROY_ACCOUNT_PLAN,
  GET_FILTER_ACCOUNT_PLAN,
} from "../store/actions/account_plan";
import { mapGetters } from "vuex";
import AlertApi from "./../components/alert_api/alert_api";

export default {
  data: () => ({
    showDialog: Boolean(),
    permission: Object(),
    search: "",
    getter: "getAccount_Plan",
    addDialog: false,
    filter: String(),
    objectToUpdate: {},
    title: "Plano de Contas",
    selectValue: Object(),
    headers: [
      { text: "Status", value: "status" },
      { text: "Descrição", value: "description" },
      { text: "Código Contabil", value: "accountCode" },
      { text: "Gera Movimentação", value: "generateMovement" },
      { text: "Tipo Contabil", value: "accountType" },
      { text: "Plano Contabil", value: "planType" },
      { text: "Ações", value: "actions" },
    ],
  }),
  async created() {
    this.GetAll();
  },
  components: {
    ModalAccountplans,
    Container,
    loadScreen,
    CardTitle,
    Table,
    AlertApi,
  },
  computed: {
    ...mapGetters([
      "getAccount_Plan",
      "getCadastrosLinks",
      
    ]),
  },
  methods: {
    async destroy_object(item) {
      const car_adm = {
        id: item.id,
      };
      try {
        await this.$store.dispatch(DESTROY_ACCOUNT_PLAN, car_adm);
      } catch (error) {
        alert(error);
      }
    },
    async GetAll(page = 1) {
      await this.$store.dispatch(GET_ALL_ACCOUNT_PLAN, {
        page: page,
        limit: 10,
        paginate: true,
      });
    },
    change_addDialog(item) {
      this.addDialog = !this.addDialog;
      this.objectToUpdate = item;
    },
    async accountplan_filter(page = 1) {
      if (!this.filter) {
        this.GetAll();
      } else {
        let value = {
          search: this.filter,
          page: page,
          limit: 10,
          paginate: true,
        };
        await this.$store.dispatch(GET_FILTER_ACCOUNT_PLAN, value);
      }
    },
  },
  watch: {
   
    filter: function () {
      this.accountplan_filter();
    },
  },
  mounted() {
    let name = document.URL.split("/").pop();
    this.permission = this.getCadastrosLinks.getPermissions(name);
  },
};
</script>
