


const productToUpdate = async function () {
    this.product.accounting_price = this.productToUpdateStore.accounting_price;
    this.product.additional_aliquot_tax_st = this.productToUpdateStore.additional_aliquot_tax_st;
    this.product.additional_value_margin = this.productToUpdateStore.additional_value_margin;
    this.product.aliquot_fcp = this.productToUpdateStore.aliquot_fcp;
    this.product.aliquot_id = this.productToUpdateStore.aliquot_id;
    this.product.amount_stamp = this.productToUpdateStore.amount_stamp;
    this.product.avarenge_cost = this.productToUpdateStore.avarenge_cost;
    this.product.bar_code = this.productToUpdateStore.bar_code;
    this.product.calculation_base_mode = this.productToUpdateStore.calculation_base_mode;
    this.product.cfop_id = this.productToUpdateStore.cfop_id;
    this.product.cnpj_producer = this.productToUpdateStore.cnpj_producer;
    this.product.code_stamp = this.productToUpdateStore.ipi_calculation_form;
    this.product.cost_price = this.productToUpdateStore.cost_price;
    this.product.csosn_id = this.productToUpdateStore.csosn_id;
    this.product.cst_cofins_id = this.productToUpdateStore.cst_cofins_id;
    this.product.cst_id = this.productToUpdateStore.cst ? this.productToUpdateStore.cst.id : undefined  ; // erro
    this.product.cst_ipi_id = this.productToUpdateStore.cst_ipi_id;
    this.product.cst_pis_id = this.productToUpdateStore.cst_pis_id;
    this.product.date_last_buy = this.productToUpdateStore.date_last_buy;
    this.product.date_last_register = this.productToUpdateStore.date_last_register;
    this.product.description_item = this.productToUpdateStore.description_item;
    this.product.description_pdv = this.productToUpdateStore.description_pdv;
    this.product.enterprise_id = this.productToUpdateStore.enterprise_id;
    this.product.final_promotion_date = this.productToUpdateStore.final_promotion_date;
    this.product.framing_class_cest = this.productToUpdateStore.framing_class_cest;
    this.product.id = this.productToUpdateStore.id;
    this.product.initial_promotion_date = this.productToUpdateStore.initial_promotion_date;
    this.product.ipi = this.productToUpdateStore.ipi;
    this.product.ipi_calculation_form = this.productToUpdateStore.ipi_calculation_form;
    this.product.management_cost = this.productToUpdateStore.management_cost;
    this.product.manufacturer_code = this.productToUpdateStore.manufacturer_code;
    this.product.master_box = this.productToUpdateStore.master_box;
    this.product.max_stock = this.productToUpdateStore.max_stock;
    this.product.mechandise_cost = this.productToUpdateStore.mechandise_cost;
    this.product.minimum_stock = this.productToUpdateStore.minimum_stock;
    this.product.ncm_id = this.productToUpdateStore.ncm_id;
    this.product.nfci = this.productToUpdateStore.nfci;
    this.product.note_price = this.productToUpdateStore.note_price;
    this.product.percentage_aliquot_tax_st = this.productToUpdateStore.percentage_aliquot_tax_st;
    this.product.percentage_calc_base_reduction = this.productToUpdateStore.percentage_calc_base_reduction;
    this.product.percentage_deferral = this.productToUpdateStore.percentage_deferral;
    this.product.percentage_fcp_tax = this.productToUpdateStore.percentage_fcp_tax;
    this.product.percentage_supported_final_consumer = this.productToUpdateStore.percentage_supported_final_consumer;
    this.product.percentagem_calc_st_reduction = this.productToUpdateStore.percentagem_calc_st_reduction;
    this.product.photo = this.productToUpdateStore.photo;
    this.product.product_group_id = this.productToUpdateStore.product_group_id;
    this.product.product_type = this.productToUpdateStore.product_type;
    this.product.promotion_price = this.productToUpdateStore.promotion_price;
    this.product.reason_exemption_icms = this.productToUpdateStore.reason_exemption_icms;
    this.product.reference = this.productToUpdateStore.reference;
    this.product.sale_price = this.productToUpdateStore.sale_price;
    this.product.source_product = this.productToUpdateStore.source_product;
    this.product.status = this.productToUpdateStore.status;
    this.product.stock_turning = this.productToUpdateStore.stock_turning;
    this.product.sub_group_product_id = this.productToUpdateStore.sub_group_product_id;
    this.product.tax_bemefot_code_uf = this.productToUpdateStore.tax_bemefot_code_uf;
    this.product.tax_replacement_base_modality = this.productToUpdateStore.tax_replacement_base_modality;
    this.product.unit_id = this.productToUpdateStore.unit_id;
    this.product.use_type = this.productToUpdateStore.use_type;
    this.product.user_change = this.productToUpdateStore.user_change;
    if (this.product.photo != null){
        this.linkImage = this.product.photo
    }

   //this.product.cst_id = this.productToUpdateStore.cst.code

}

const print = function () {

}

module.exports = {
    productToUpdate,
    print
}