<template>
  <div>
    <v-data-table
      no-data-text="Sem registro!"
      :headers="header"
      :items="items"
      hide-default-footer
      :footer-props="{
        'items-per-page-options': [5, 10, 20, -1],
        'items-per-page-text': 'Items por página',
      }"
    >
      <template v-slot:item.down="{ item }">
        <v-simple-checkbox
          color="success"
          :ripple="false"
          v-model="item.down"
        ></v-simple-checkbox>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-pagination
      v-model="component.current"
      :length="component.total_table"
      color="success"
      :total-visible="10"
    ></v-pagination>
  </div>
</template>

<script>
export default {
  props: {
    component: Object(),
    items: Array,
    header: Array,
  },
};
</script>

<style>
</style>