<template>
  <div>
    <v-simple-table class="mt-5 mb-5">
      <template v-slot:default>
        <thead>
          <tr>
            <th v-for="(name, index) in header" :key="index" class="text-left">
              {{ name }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td>{{ item.code }}</td>
            <td>{{ item.description }}</td>
            <td>{{ item.amount }}</td>
            <td>{{ item.seller_price }}</td>
            <td>{{ item.subtotal }}</td>
            <!-- @click="setIndex(index)" -->
            <td>
              <v-text-field
                @click="setIndex(index)"
                @blur="onBlurTextField()"
                v-model="item.amount_exchange"
                hint="Tecle Enter para Calcular o valor do item"
                placeholder="Tecle Enter para Calcular o valor do item"
              />
            </td>
            <!-- v-model="item.amount_exchange" -->
            <td>{{ item.credit_value }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <svg id="barcode"></svg>
  </div>
</template>

<script>
import JsBarcode from "jsbarcode"
import { Data, header } from "./header";
import { mapGetters } from "vuex";
import generatePdf from "./generatePDF";
import {generateGtinFunction} from "../../../Utils/global_functions/generateGtin"
import {
  VALUE_CUPOM,
  TOTAL_CREDIT,
  REGISTER_CREDIT,
} from "../../../../store/actions/exchange_control";
export default {
  props: {
    component: Object(),
  },
  data: () => ({
    amountChange: Number(),
    numberTitle: Number(),
    items: Array(),
    indexChange: null,
    totalCredit: Number(),
    totalNote: Number(),
    credit: Object(),
    Data,
    header,
  }),
  computed: {
    ...mapGetters([
      "getCupomInformation",
      "getExchangeHeader",
      "getTotalCredit",
      "getSelectedEnterprise",
      "getCuponValue",
    ]),
  },
  methods: {
    generatePdf,
    submit() {
      this.creditGenerate(this.totalNote);
      
      let credit = this.credit

      this.$store.dispatch(REGISTER_CREDIT, credit).then((response) => {   
        
        JsBarcode("#barcode", this.numberTitle );
  
        const getElementDOM = document.getElementById('barcode')
        const tagHTML = getElementDOM.outerHTML
        // 
        this.generatePdf(this.getSelectedEnterprise, response.data, this.component.form.coupon_value, tagHTML );
      }).finally(() => {
        this.$store.dispatch('setShowDialogRegisterExchangeControl', false)
      });
    },

    getRandomIntInclusive(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return String(Math.floor(Math.random() * (max - min + 1)) + min);
    },
    setIndex(value) {
      this.indexChange = value;
    },
    sumValueCupom(items) {
      items.forEach((element) => {
        this.totalCredit = this.totalCredit + element.credit_value;
      });
      this.$store.dispatch(TOTAL_CREDIT, this.totalCredit);
    },
    validationFieldsArray(index, items) {
      
      this.totalCredit = 0;
      if (index == null || index == undefined) {
        alert("Por Favor, Clique no campo de Quantidade de trocas");
      } else if (items[index].amount_exchange <= items[index].amount) {
        items[index].credit_value =
          items[index].amount_exchange * items[index].seller_price;
        this.sumValueCupom(items);
        this.component.validateAmountChange = true
      } else {
        this.component.validateAmountChange = false
        alert("Você não pode trocar essa quantidade de itens");
      }
    },
    onBlurTextField(){
      this.validationFieldsArray(this.indexChange, this.items);
    },
    watchKey(e) {
      if (e.keyCode == 13) {
        this.validationFieldsArray(this.indexChange, this.items);
      }
    },
    creditGenerate(totalNote) {
       this.numberTitle = generateGtinFunction()
      this.credit = {
        exchange: {
          access_key: this.getExchangeHeader.exchangeHeader.access_key,
          client_id: this.getExchangeHeader.exchangeHeader.client_id,
          coupon_inf: this.getExchangeHeader.exchangeHeader.coupon_inf,
          coupon_value: totalNote,
          data_sale: this.getExchangeHeader.exchangeHeader.data_sale,
          total_credit: this.getTotalCredit,
        },
        billsToPay: [
          {
            status: "Aberto",
            issuance_date: `${new Date().getFullYear()}-${
              new Date().getMonth() + 1
            }-${new Date().getDate()}`,
            issuance_due: `${new Date().getFullYear()}-${
              new Date().getMonth() + 1
            }-${new Date().getDate()}`,
            type_title: "CT",
            number_title: this.numberTitle ,//this.getExchangeHeader.exchangeHeader.coupon_inf, //numero do cupom
            provider_id: this.getExchangeHeader.exchangeHeader.client_id, //client_id ------- tem que ver
            value_title: totalNote, // valor do crédito
            value_net: totalNote, // valor do crédito
            dejection: 0, //0
            title_balance: totalNote, // valor do crédito
            annotation:
              "Crédito de troca referente ao cupom: " +
              this.getExchangeHeader.exchangeHeader.coupon_inf,
            account_plans_id: 1, // 1  ---- tem que ver
            enterprise_id: this.getSelectedEnterprise.id,
            invoiceentry_id: null,
          },
        ],
        exchangeItems: [...this.items],
        enterprise_id: this.getSelectedEnterprise.id,
        //falta
        access_key: this.getExchangeHeader.billsToPayInformation.access_key,
        base_calc_icms_total:
          this.getExchangeHeader.billsToPayInformation.base_cal_icms_total,
        data_exchange: `${new Date().getFullYear()}-${
          new Date().getMonth() + 1
        }-${new Date().getDate()}`,
        number_exchange: this.getRandomIntInclusive(10000000, 99999999), // deixar por ultimo
        sale_id: this.getExchangeHeader.billsToPayInformation.id,
        value_credit_icms_total: this.totalCredit,
        value_cupom_fiscal: this.getCuponValue,
        value_exchange: this.totalCredit,
        value_icms_total:
          this.getExchangeHeader.billsToPayInformation.value_icms_total,
        value_total_note: totalNote,
        value_total_products: this.getCuponValue,
      };
    },
    loadTable(value) {
       
      if (value.itens_sales.length > 0) {
        value.itens_sales.forEach((element) => {
          this.items.push({
            code: element.code_item,
            description: element.product.description_item, //Não veio no mock
            amount: element.amount,
            seller_price: element.value_sale,
            subtotal: element.amount * element.value_sale,
            amount_exchange: 0,
            credit_value: 0,

            //implementar

            product_id: element.product.id,
            value_base_icms_product: element.value_base_icms_product,
            value_credit_icms: 0,
            value_icms_product: element.value_icms_product,
            value_sale: element.value_sale,
            value_subtotal_product: element.value_subtotal_product,
          });
        });

        this.sumValueCupom(this.items);
      } else {
        alert("Lista de itens vaiza !");
      }
    },
  },
  created() {
    document.addEventListener("keydown", this.watchKey);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.watchKey, false);
  },
  watch: {
    "component.submitFlag": function () {
      this.submit();
    },
    items: function (val) {   

      val.forEach((element) => {
        this.totalNote = this.totalNote + element.subtotal;
      });
      this.$store.dispatch(VALUE_CUPOM, this.totalNote);
    },
    getCupomInformation: function (value) {
      this.loadTable(value);
    },
  },
};
</script>

<style></style>
