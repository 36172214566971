<template>
  <v-container>
    <div>
      <v-form v-model="component.valid" ref="form" :disabled="isDisable">
        <v-row>
          <v-col>
            <v-select
              label="*Empresa Emitente"
              item-text="fantasyName"
              return-object
              outlined
              append-outer-icon="mdi-plus-circle"
              @click:append-outer="showDialog('enterprisedialog')"
              :items="enterprises"
              :rules="[(v) => !!v || 'Campo obrigatório']"
              v-model="component.form.enterprise_id"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" md="4" xl="3" lg="3">
            <v-text-field
              label="*Data de Emissão"
              placeholder="null"
              type="Date"
              v-model="component.form.date_of_issue"
              outlined
              :rules="[
                () => !!component.form.date_of_issue || 'Campo Obrigatorio',
              ]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" xl="3" lg="3">
            <v-select
              label="*Tipo de Nota"
              v-model="component.form.type_grade_id"
              :items="getModeItens"
              item-text="description"
              item-value="id"
              :rules="[(v) => !!v || 'Campo obrigatório']"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4" md="4" xl="3" lg="3">
            <v-autocomplete
              outlined
              label="*Finalidade da Nota"
              item-text="text"
              item-value="value"
              :items="finality_note"
              v-model="component.form.finality_nf"
              :rules="[(v) => !!v || 'Campo obrigatório']"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12" md="12" xl="3" lg="3">
            <v-autocomplete
              outlined
              item-text="description"
              item-value="id"
              label="*Tipo de Operação"
              append-outer-icon="mdi-plus-circle"
              @click:append-outer="showDialog('taxoperationtype')"
              :items="taxOperationItens"
              v-model="component.form.tax_operation_types_id"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="4" md="4" xl="6" lg="6">
            <v-autocomplete
              label="*Cliente"
              v-model="description"
              :items="clients_items"
              append-outer-icon="mdi-plus-circle"
              @click:append-outer="showDialog('addclient')"
              item-text="name"
              return-object
              outlined
              :rules="[(v) => !!v || 'Campo obrigatório']"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4" xl="3" lg="3">
            <v-text-field
              label="Número da Nota"
              disabled
              v-model="component.form.number_grade"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" xl="3" lg="3">
            <v-text-field
              label="Serie"
              disabled
              v-model="component.form.series"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="4" md="4" xl="4" lg="4">
            <v-text-field
              label="Chave de Acesso da NFE"
              v-model="component.form.access_key"
              disabled
              outlined
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" xl="4" lg="4">
            <v-text-field
              label="Prot. Autorização de uso"
              v-model="component.form.authorization_protocol"
              :rules="[(v) => !!v || 'Campo obrigatório']"
              disabled
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" xl="4" lg="4" >
            <v-text-field
              outlined
              label="Informações Adicionais"
              v-model="component.form.note"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- <AddClient v-if="addDialogClient" :component="this" /> -->
        <AddClient :out_of_register="true" v-if="getShowDialogRegisterClient" />
        <EnterpriseDialog />
        <OperationTypeDialog />
      </v-form>
    </div>
  </v-container>
</template>

<script>
// import AddClient from "./DialogClient/";
import AddClient from "../../../Client/AddDialog/index.vue";
import { GET_ALL_TAX_OPERATION_TYPE } from "../../../../store/actions/tax_operation_types";
import {
  GET_ALL_NOTE_MODEL,
  GET_SEQUENCE_NFE,
} from "../../../../store/actions/note_model";
import { GET_ALL_CLIENT_TO_SELECT_FIELD } from "../../../../store/actions/client";
import { GET_ALL_ENTERPRISE } from "../../../../store/actions/enterprise";

import EnterpriseDialog from "../../../enterprise/AddDialog";
import OperationTypeDialog from "../../../tax_operation_type/AddDialog";

import { mapGetters } from "vuex";

export default {
  props: {
    component: Object,
    client: Object,
    isDisable: Boolean,
  },
  components: {
    AddClient,
    EnterpriseDialog,
    OperationTypeDialog,
  },

  data: () => ({
    indexNewRegister: 0,
    addDialogClient: false,
    enterprises: Object(),
    item_typeoperation: Array(),
    description: String(),
    item_client: Array(),
    taxOperationItens: Array(),
    getModeItens: Array(),
    client_id: Object(),
    typeNote: Object(),
    clients_items: Array(),
    finality_note: [
      { text: "NFE-NORMAL", value: "1" },
      { text: "NFE-COMPLEMENTAR", value: "2" },
      { text: "NFE-AJUSTE", value: "3" },
      { text: "DEVOLUCAO PRODUTO", value: "4" },
    ],
  }),
  methods: {
    showDialog(dialog) {
      if (dialog == "taxoperationtype") {
        this.$store.dispatch("setShowDialogRegisterTaxOperationType", true);
      } else if (dialog == "enterprisedialog") {
        this.$store.dispatch("setShowDialogRegisterEnterprise", true);
      } else if (dialog == "addclient") {
        this.$store.dispatch("setShowDialogRegisterClient", true);
      }
    },

    async operationOutTypeMovement() {
      this.item_typeoperation = await this.taxOperationItens.filter(
        (item) => item.type === "SAIDA"
      );
    },

    async GetAllOperationType() {
      await this.$store.dispatch(GET_ALL_TAX_OPERATION_TYPE, {
        page: 0,
        limit: null,
        paginate: false,
      });
    },
    async GetAllClient() {
      await this.$store.dispatch(GET_ALL_CLIENT_TO_SELECT_FIELD, {
        page: null,
        limit: null,
        paginate: false,
      });
    },
    name_client(value) {
      this.clients_items = value;
      this.clients_items.forEach((e) => {
        if (e.type_people === "LegalPeople") {
          e.name = e.people.legalpeople.fantasyName;
        } else {
          e.name = e.people.physicalpeople.name;
        }
      });
    },
    async GetAllNoteModel() {
      await this.$store.dispatch(GET_ALL_NOTE_MODEL, {
        page: 0,
        limit: null,
        paginate: false,
      });
      this.component.form.number_grade = this.getModeItens.sequence;
      this.component.form.series = this.getModeItens.series;
    },
    async GetAllEnterprise() {
      let page = 1;
      await this.$store.dispatch(GET_ALL_ENTERPRISE, {
        page: page,
        limit: 10,
        paginate: false,
      });
    },
  },
  watch: {
    getPhysicalClients: function () {
      this.GetAllClient();
    },
    getLegalClients: function () {
      this.GetAllClient();
    },
    getTaxOperationType: function (val) {
      this.taxOperationItens = Object.values(val).filter(
        (item) => item.type === "SAIDA"
      );
      this.component.form.typeOperation = { ...this.taxOperationItens };
    },

    "component.form.enterprise_id": function (val) {
      this.component.form.enterprise_nf = { ...val };
    },
    getNoteModel: function (val) {
      this.getModeItens = Object.values(val).filter(
        (item) => item.tax_document_type === "NF-e"
      );
      this.component.form.noteNf = { ...this.getModeItens };
    },
    getSelectClient: function (val) {
      this.name_client(val);
      if (this.indexNewRegister > 0) {
        this.description = val[val.length - 1];
      }
      this.indexNewRegister++;
    },
    description: function (val) {
      this.component.form.client_id = val.id;
      this.component.form.client = { ...val };
    },
    "component.form.type_grade_id": function (note_model_id) {
      this.$store.dispatch(GET_SEQUENCE_NFE, {
        id: note_model_id,
      });
    },
    getSequenceNfe: function (val) {
      this.component.form.number_grade = val[0].sequence;
      this.component.form.series = val[0].series;
    },
    getEnterprises(val) {
      this.enterprises = Object.values(val.data);
      this.selectValue = this.enterprises[0];
    },
  },
  computed: {
    ...mapGetters([
      "getNoteModel",
      "getTaxOperationType",
      "getSequenceNfe",
      "getEnterprises",
      "getShowDialogRegisterClient",
      "getPhysicalClients",
      "getLegalClients",
      "getSelectClient",
    ]),
  },

  async created() {
    await this.GetAllOperationType();
    await this.GetAllNoteModel();
    await this.GetAllClient();
    await this.GetAllEnterprise();
    this.description = { ...this.client };
  },
};
</script>

<style>
</style>
