<template>
  <v-form ref="form" v-model="validationForm">
    <v-row>
      <v-col>
        <v-autocomplete
          hide-no-data
          class="ma-2"
          type="number"
          outlined
          v-model="component.product.cst_id"
          :readonly="component.justVisible"
          label="*CST de Saída"
          item-text="code"
          :rules="component.textRules"
          item-value="id"
          :items="cstItemsSelect"
          append-outer-icon="mdi-plus-circle"
          @click:append-outer="() => changeCstDialog()"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          hide-no-data
          class="ma-2"
          type="number"
          outlined
          v-model="component.product.csosn_id"
          :readonly="component.justVisible"
          label="*CSOSN de Saída"
          item-text="code"
          item-value="id"
          :rules="component.textRules"
          :items="csosnItemsSelect"
          append-outer-icon="mdi-plus-circle"
          @click:append-outer="() => changeCsosnDialog()"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          hide-no-data
          class="ma-2"
          outlined
          label="*Aliquota de Saída"
          item-text="aliquot"
          item-value="id"
          append-outer-icon="mdi-plus-circle"
          :rules="component.textRules"
          v-model="component.product.aliquot_id"
          :readonly="component.justVisible"
          :items="aliquotItemsSelect"
          @click:append-outer="() => changeAliquotDialog()"
        >
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          hide-no-data
          class="ma-2"
          type="number"
          outlined
          label="*CFOP de Saída"
          item-text="code"
          item-value="id"
          append-outer-icon="mdi-plus-circle"
          :readonly="component.justVisible"
          :rules="component.textRules"
          v-model="component.product.cfop_id"
          :items="cfopItemsSelect"
          @click:append-outer="() => changeCfopDialog()"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-layout>
          <v-autocomplete
            hide-no-data
            class="ma-2"
            label="*Origem mercadoria"
            v-model="component.product.source_product"
            :readonly="component.justVisible"
            :rules="[
              () => !!component.product.source_product || 'Campo Obrigatório!',
            ]"
            full-width
            outlined
            :items="items"
          >
          </v-autocomplete>
        </v-layout>
      </v-col>

      <v-col>
        <v-autocomplete
          class="ma-2"
          hide-no-data
          outlined
          v-model="component.product.ncm_id"
          :readonly="component.justVisible"
          label="*NCM"
          :rules="[
            () => !!this.component.product.ncm_id || 'Campo Obrigatório!',
          ]"
          item-text="code"
          item-value="id"
          :items="ncmItemsSelect"
          append-outer-icon="mdi-plus-circle"
          @click:append-outer="() => changeNcmDialog()"
        >
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          class="ma-2"
          hide-no-data
          :readonly="component.justVisible"
          outlined
          v-model="component.product.calculation_base_mode"
          label="*Modalidade BC"
          item-text="text"
          item-value="value"
          :items="modBC"
          :rules="[
            () => !!component.product.calculation_base_mode || 'Campo Obrigatório!',
          ]"
          required
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <div v-if="value_st">
      <v-row>
        <v-col>
          <v-text-field
            class="ma-2"
            :readonly="component.justVisible"
            type="string"
            outlined
            label="Codigo CEST"
            v-model="component.product.framing_class_cest"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            class="ma-2"
            :readonly="component.justVisible"
            type="number"
            outlined
            label="Codigo Beneficio Fiscal na UF "
            v-model="component.product.tax_bemefot_code_uf"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-autocomplete
            class="ma-2"
            hide-no-data
            :readonly="component.justVisible"
            outlined
            v-model="component.product.tax_replacement_base_modality"
            label="Modalidade BC ST"
            item-text="text"
            item-value="value"
            :items="modST"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            class="ma-2"
            type="number"
            :readonly="component.justVisible"
            outlined
            label="Margem de valor adicional (MVA)"
            v-model="component.product.additional_value_margin"
          ></v-text-field>
        </v-col>

        <v-col>
          <v-text-field
            class="ma-2"
            type="number"
            :readonly="component.justVisible"
            outlined
            label="Redução da base ST Icms(%)"
            v-model="component.product.percentagem_calc_st_reduction"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            class="ma-2"
            :readonly="component.justVisible"
            type="number"
            outlined
            label="Percentual Icms St (%)"
            v-model="component.product.additional_aliquot_tax_st"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            class="ma-2"
            :readonly="component.justVisible"
            type="number"
            outlined
            label="NFCI "
            v-model="component.product.nfci"
          ></v-text-field>
        </v-col>

        <v-col>
          <v-text-field
            class="ma-2"
            type="number"
            :readonly="component.justVisible"
            outlined
            label="Percentual FCP (%)"
            v-model="component.product.aliquot_fcp"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            class="ma-2"
            type="number"
            :readonly="component.justVisible"
            outlined
            label="Percentual FCP retido ST (%)"
            v-model="component.product.percentage_fcp_tax"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            class="ma-2"
            type="number"
            :readonly="component.justVisible"
            outlined
            label="Percentual Red. BC (%) "
            v-model="component.product.percentage_calc_base_reduction"
          ></v-text-field>
        </v-col>

        <v-col>
          <v-autocomplete
            class="ma-2"
            :readonly="component.justVisible"
            hide-no-data
            outlined
            v-model="component.product.reason_exemption_icms"
            label="Motivo desoneracao Icms"
            item-text="text"
            item-value="value"
            :items="modDesIcms"
          >
          </v-autocomplete>
        </v-col>
        <v-col>
          <v-text-field
            class="ma-2"
            type="number"
            :readonly="component.justVisible"
            outlined
            label="Percentual Diferimento (%) "
            v-model="component.product.percentage_deferral"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            class="ma-2"
            type="number"
            :readonly="component.justVisible"
            outlined
            label="Aliquota Sup. Cons. Final "
            v-model="component.product.percentage_supported_final_consumer"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>

    <ModalNcm
      v-if="addDialogNcm"
      :addDialogNcm="addDialogNcm"
      :component="this"
      @close="addDialogNcm = $event"
    />
    <ModalAliquot v-if="getShowDialogRegisterAliquot" :component="this" />
    <ModalPisCofins
      v-if="addDialogPisCofins"
      :addDialogPisCofins="addDialogPisCofins"
      :component="this"
      @close="addDialogPisCofins = $event"
    />
    <ModalCfop v-if="getShowDialogRegisterCFOP" :component="this" />
    <ModalCst v-if="getShowDialogRegisterCST" :component="this" />
    <ModalCsosn v-if="getShowDialogRegisterCSOSN" :component="this" />
  </v-form>
</template>

<script>
import ModalNcm from "../../../ncm/AddDialog/index";
import ModalAliquot from "../../../aliquot/AddDialog/index";
import ModalCfop from "../../../cfop/AddDialog/index";
import ModalCst from "../../../cst/AddDialog/index";
import ModalCsosn from "../../../csosn/AddDialog/index";
import ModalPisCofins from "../../../pis_cofins/AddDialog/index";
import { GET_ALL_ALIQUOT } from "../../../../store/actions/aliquot";
import { GET_ALL_CFOP } from "../../../../store/actions/cfop";
import { GET_ALL_CST } from "../../../../store/actions/cst";
import { GET_ALL_CSOSN } from "../../../../store/actions/csosn";
import { GET_ALL_NCM } from "../../../../store/actions/ncm";
import { mapGetters } from "vuex";
export default {
  components: {
    ModalNcm,
    ModalAliquot,
    ModalPisCofins,
    ModalCfop,
    ModalCst,
    ModalCsosn,
  },

  props: {
    component: Object(),
  },

  data: () => ({
    validationForm:false,
    auxSetValuesFields: {
      auxCST: 0,
      auxCSOSN: 0,
      auxNCM: 0,
    },
    page: Number(),
    last_page: Number(),
    item_listNcm: Array(),
    item_list: Array(),
    addDialogNcm: false,
    addDialogAliquot: false,
    addDialogPisCofins: false,
    addDialogCfop: false,
    addDialogCst: false,
    addDialogCsosn: false,
    ncmItemsSelect: Array(),
    aliquotItemsSelect: Array(),
    cstPisCofinsItemsSelect: Array(),
    cfopItemsSelect: Array(),
    cstItemsSelect: Array(),
    csosnItemsSelect: Array(),
    objectToUpdate: Object(),
    vSelect: String(),
    myTeste: "valor aleatório",
    items: [
      { text: "Nacional", value: "0" },
      { text: "Estrangeira - Imoportação direta", value: "2" },
      { text: "Estrangeira - Adquirida no mercado interno", value: "3" },
    ],
    modBC: [
      { text: "Margem de valor agregado", value: "0" },
      { text: "Pauta", value: "1" },
      { text: "Preço Tabelado Max", value: "2" },
      { text: "Valor de Operação", value: "3" },
    ],
    modST: [
      { text: "Preço tabelado ou máximo sugerido", value: "0" },
      { text: "Lista Negativa (valor)", value: "1" },
      { text: "Lista Positiva (valor)", value: "2" },
      { text: "Lista Neutra (valor)", value: "3" },
      { text: "Margem Valor Agregado (%)", value: "4" },
      { text: "Pauta (valor)", value: "5" },
      { text: "Valor da Operação (%)", value: "6" },
    ],
    modDesIcms: [
      { text: "Uso na agropecuária", value: 3 },
      { text: "Utilitários e Motocicletas da Amazônia Ocidental", value: 6 },
      { text: "Suframa", value: 7 },
      { text: "Órgão de fomento e desenvolvimento agropecuário.", value: 12 },
      { text: "Outros", value: 9 },
    ],
    value_st: true,
  }),

  computed: {
    ...mapGetters([
      "getNcm",
      "getAliquot",
      "getCfop",
      "getCst",
      "getCsosn",
      "getPisCofins",
      "getShowDialogRegisterCSOSN",
      "getShowDialogRegisterAliquot",
      "getShowDialogRegisterCFOP",
      "getShowDialogRegisterCST",
      "getAliquotToAdd",
      "getCfopToAdd",
    ]),
  },

  watch: {
     validationForm:function(val){
      this.component.validations.push(val)
    },
    //Deprecated
    // getNcm: function (val) {
    //   val;
    //   this.ncmItemsSelect = [];
    //   this.ncmItemsSelect = Object.keys(this.getNcm).map(
    //     (key) => this.getNcm[key]
    //   );
    //   if (this.auxSetValuesFields.auxNCM == 0) {
    //     this.auxSetValuesFields.auxNCM = 1;
    //   } else {
    //     this.component.product.ncm_id =
    //       this.ncmItemsSelect[this.ncmItemsSelect.length - 1].id;
    //   }
    // },
    getAliquotToAdd: function () {
      this.aliquotItemsSelect.push(this.getAliquotToAdd);
      this.component.product.aliquot_id = this.getAliquotToAdd.id;
    },
    getAliquot: function (value) {
      this.aliquotItemsSelect = value.data;
    },
    getCfopToAdd: function () {
      this.cfopItemsSelect.push(this.getCfopToAdd);
      this.component.product.cfop_id = this.getCfopToAdd.id;
    },
    getCfop: function (val) {
       
      this.filterCFOP(val)
      // this.cfopItemsSelect = Object.values(this.getCfop);
    },
    //Deprecated
    // getCst: function (val) {
    //   val;
    //   this.cstItemsSelect = Object.keys(this.getCst).map(
    //     (key) => this.getCst[key]
    //   );
    //   if (this.auxSetValuesFields.auxCST == 0) {
    //     this.auxSetValuesFields.auxCST = 1;
    //   } else {
    //     this.component.product.cst_id =
    //       this.cstItemsSelect[this.cstItemsSelect.length - 1];
    //   }
    // },

    //Deprecated
    // getCsosn: function (val) {
    //   val;
    //   this.csosnItemsSelect = Object.keys(this.getCsosn).map(
    //     (key) => this.getCsosn[key]
    //   );

    //   if (this.auxSetValuesFields.auxCSOSN == 0) {
    //     this.auxSetValuesFields.auxCSOSN = 1;
    //   } else {
    //     this.component.product.csosn_id =
    //       this.csosnItemsSelect[this.csosnItemsSelect.length - 1];
    //   }
    // },

    getPisCofins: function (val) {
      this.cstPisCofinsItemsSelect = Object.keys(val).map((key) => val[key]);
    },
  },

  created() {
    this.getAll();
    //Deprecated
    // this.ncmItemsSelect = Object.keys(this.getNcm).map(
    //   (key) => this.getNcm[key]
    // );
    this.cstPisCofinsItemsSelect = Object.keys(this.getPisCofins).map(
      (key) => this.getPisCofins[key]
    );
    //Deprecated
    // this.cstItemsSelect = Object.keys(this.getCst).map(
    //   (key) => this.getCst[key]
    // );
    this.csosnItemsSelect = Object.keys(this.getCsosn).map(
      (key) => this.getCsosn[key]
    );
  },

  destroyed() {
    this.$destroy;
  },

  methods: {
    // filterCFOP(value){
    //   var auxArrayCfop = value
    //   auxArrayCfop.map((element) => {
    //     if(element.code >= 5000 && element.code <= 8000){
    //       this.cfopItemsSelect.push(element)
    //     }
    //   })
    // },
    filterCFOP(value) {
      if (!Array.isArray(value)) {
        return [];
      }
      else {
        this.cfopItemsSelect = value.filter(element => {
          return element.code >= 5000 && element.code <= 8000;
        });
      }
    },
    getAll(page = 1) {
      var payload = {
        page: page,
        limit: 10,
        paginate: true,
      };
      this.$store.dispatch(GET_ALL_ALIQUOT, payload);
      this.$store.dispatch(GET_ALL_CFOP, payload).then((response) => {
        
        this.filterCFOP(response.data)
      });
      this.$store.dispatch(GET_ALL_CST, payload).then((response) => {
        this.cstItemsSelect = [...response.data]
      });
      this.$store.dispatch(GET_ALL_CSOSN, payload).then((response) => {
        this.csosnItemsSelect = [...response.data]
      });
      this.$store.dispatch(GET_ALL_NCM, payload).then((response) => {
        
        this.ncmItemsSelect = [...response.data]
      });
    },
    change_addDialog(item) {
      this.objectToUpdate = item;
    },
    changeNcmDialog() {
      this.addDialogNcm = !this.addDialogNcm;
    },
    changeAliquotDialog() {
      this.$store.dispatch("setShowDialogRegisterAliquot", true);
    },
    changeCfopDialog() {
      this.$store.dispatch("setShowDialogRegisterCFOP", true);
    },
    changeCstDialog() {
      this.$store.dispatch("setShowDialogRegisterCST", true);
    },
    changeCsosnDialog() {
      this.$store.dispatch("setShowDialogRegisterCSOSN", true);
    },
    changePisCofinsDialog() {
      this.addDialogPisCofins = !this.addDialogPisCofins;
    },
    reset() {
      this.$refs.form.resetValidation();
    },

    enableTaxFieldIcms(val) {
      switch (val) {
        case 0:
          this.cst_tribute_integralmente();
          break;
        case 10:
          this.cst_tribute_icms_st();
          break;

        default:
          break;
      }
    },

    cst_tribute_integralmente() {
      (this.csosn_show = false),
        (this.code_cest = false),
        (this.mode_st = false),
        (this.mva = false),
        (this.nfci = true),
        (this.percentagem_st_reduction = false);
      this.aliquot_fcp_show = true;
    },

    cst_tribute_icms_st() {
      (this.aliquot_fcp = true),
        (this.csosn_show = false),
        (this.code_cest = true),
        (this.mode_st = true),
        (this.mva = true),
        (this.nfci = true),
        (this.additional_aliquot_tax_st = true),
        (this.percentagem_st_reduction = true),
        (this.percentage_fcp_tax = true);
    },
  },
};
</script>
