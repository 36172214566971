<template>
  <dir>
    <v-responsive>
      <v-data-table
        :hide-default-footer="true"
        :items-per-page="5"
        :headers="headers"
      >
      </v-data-table>
      <v-divider></v-divider>
      <v-pagination
        color="success"
        v-model="page"
        :length="lengthPaginator"
      ></v-pagination>
    </v-responsive>
  </dir>
</template>

<script>
export default {
  props: {
    headers: Array(),
    lengthPaginator: Number(),
  },
  name: "Table",
  data: function () {
    return {
      page: 1,
    };
  },
  methods: {},
};
</script>

<style>
</style>