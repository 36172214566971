
import apifiscal from "../apifiscal";
import { ADD_ORDER_CORRECTION_LETTER } from "./actions/correction_letter"
// import axios from "axios"
apifiscal


const correction_letter = {
    state: {
        correctionLetter: Object()
    },
    getters: {
        getCorrectionLetter: function (state) {
            return state.correctionLetter;
        }
    },
    mutations: {
        ADD_ORDER_CORRECTION_LETTER: function (state, payload) {
            state.correctionLetter = payload
        }
    },
    actions: {
        ADD_ORDER_CORRECTION_LETTER: function (context, payload) {

            try {
                
                // axios.post("/noteissue/issuecorretionNfe", payload).then((response) => {
                    apifiscal.post("/recipientsStament", payload).then((response) => {

                    context.commit(ADD_ORDER_CORRECTION_LETTER, response);
                })
            } catch (error) {
                console.log("==>", error)
            }

        }
    },
}
export default correction_letter