import {
  GET_ALL_FORM_OF_PAYMENT,
  DESTROY_FORM_OF_PAYMENT,
  GET_FILTER_FORM_OF_PAYMENT,
  AUX_TO_UPDATE_FORM,
} from "../../../store/actions/formofpayment";

async function destroy_formOfPayment(item) {
    const formofpayment = {
      id: item.id,
    };
    try {
      await this.$store.dispatch(DESTROY_FORM_OF_PAYMENT, formofpayment);
    } catch (error) {
      alert(error);
    }
  }
  function  open_Dialog(item) {
    this.addDialog = !this.addDialog;
    //this.objectToUpdate = item;
    this.$store.dispatch(AUX_TO_UPDATE_FORM, item)
  }
  async function GetAll(page = 1) {
    let value = {
      page: page,
      limit: 10,
      paginate: true,
    };
    await this.$store.dispatch(GET_ALL_FORM_OF_PAYMENT, value);
  }

  async function formOfPayment_filter(page = 1){
    let value = {
      search: this.filter,
      page: page,
      limit: 10,
      paginate: true
    }
    await this.$store.dispatch(GET_FILTER_FORM_OF_PAYMENT, value)
  }


  export default {
    destroy_formOfPayment,
    open_Dialog,
    GetAll,
    formOfPayment_filter,
  }